import React, { useEffect, useState } from 'react'
import { minimumPriceValidator, minimumPriceValidatorInviteOnly, quanityValidator } from 'modules/utils/validators'
import { Form, Input, InputNumber, Radio, Switch } from 'antd'

import {
  uploadBuyImage,
} from 'modules/memberships/memberships-actions'

import './index.styl'
import TooltipInfo from '../../../../../../../../../CreateEventV2/TooltipInfo'
import SingleDatePicker from '../../../../../../../../../MakeTicketComponents/components/Header/DetailsFields/SingleDatePicker'
import ImageUploader from '../../../../../../../ImageUploader'

const { Item } = Form
const { TextArea } = Input

export const privacyOptionsLabel = () => (
  <TooltipInfo
    className='poIcon'
    info='The privacy options determine who can see your event. If it is a Public event it is available on the main page for anyone to purchase. If it is Invite Only, the only way to get tickets is by Gifting, Direct Sale or sharing the event link.'
  />
)
const BuyForm = ({ name, onRemove, categoryData, form }) => {
  const [privacyOption, setPrivacyOption] = useState(categoryData?.privacyOption || 'visible')
  const [isEarlyBird, setIsEarlyBird] = useState(categoryData?.hasEarlyBird || false)
  const [earlyBirdDate, setEarlyBirdDate] = useState(categoryData?.earlyBirdDate || '')
  const [availableItem, setAvailableItem] = useState(form.getFieldValue('showAvailableTickets') || false)
  const [imageUrl, setImageUrl] = useState({})

  useEffect(() => {
    const buyItemsData = form.getFieldValue('items')
    const updatedItemsData = buyItemsData?.map((item, index) => {
      if (index === name && Object.keys(imageUrl).length > 0) {
        return {
          ...item,
          imageUrl: imageUrl,
        }
      }
      return item
    })

    form.setFieldsValue({
      items: updatedItemsData,
    })
  }, [imageUrl])

  const handlePrivacyOption = type => {
    // if (type === 'visible') {
    //  categoryData.privacyOption = 'visible'
    // }
    // if (type === 'invite') {
    //  categoryData.privacyOption = 'invite'
    // }
    setPrivacyOption(type)
  }
  const availableItemHandler = () => {
    setAvailableItem(!availableItem)
    // form.setFieldsValue({
    //  showAvailableTickets: !availableTicket,
    // })
  }
  const onCheckEarlyBird = () => setIsEarlyBird(!isEarlyBird)
  const uniqueId = `imageItemUploadInput-${name}`
  const isTablet = window.innerWidth <= 1350
  const isMobile = window.innerWidth <= 730
  return (
    <div className='itemWrapper'>
      <div className='fieldsImageWrapper'>
        <div className='topField'>
          <div className='inputFields'>
            <Item
              rules={[
                { required: true, message: 'Item Name is required!' },
                { max: 100, message: 'Item Name cannot exceed 100 characters!' },
              ]}
              name={[name, 'name']}
              label='Item Category'
              normalize={v => v.trimStart()}
            >
              <Input className='inputName' />
            </Item>
            <div className='priceQuantityWrapper'>
              <div className='mobileFlexML'>
                <Item
                  name={[name, 'price']}
                  label='Item Price'
                  rules={[
                    { required: true, message: 'Item Price is required!' },
                    privacyOption === 'visible' ? minimumPriceValidator : minimumPriceValidatorInviteOnly,
                  ]}
                >
                  <InputNumber placeholder='0' className='price' min={0} />
                </Item>
                <Item
                  rules={[{ required: true, message: 'Item Quantity is required!' },
                    { type: 'number', max: 100000, message: 'Quantity cannot exceed 100 000' },
                    quanityValidator,
                  ]}
                  name={[name, 'quantity']}
                  label='Item Quantity'
                >
                  <InputNumber
                    className='quantity'
                    placeholder='0'
                    min={1}
                  />
                </Item>
              </div>
            </div>
          </div>
          {isMobile ? (
            <div className='privacyButtonWrapper'>
              <div className='poIconWrapper'>
                <div className='privacyOptionWrapper'>
                  <Item
                    rules={[{ required: true, message: 'Choose Privacy Option' }]}
                    name={[name, 'privacyOption']}
                    label='Privacy Option'
                    initialValue={privacyOption}
                    className='no_margin item_radio-group poRadio'
                  >
                    <Radio.Group className='radioGroupWrapper' onChange={e => handlePrivacyOption(e.target.value)}>
                      <Radio checked value='visible'>Visible to Public</Radio>
                      <Radio value='invite'>Invite Only</Radio>
                    </Radio.Group>
                  </Item>
                </div>
                {privacyOptionsLabel()}
              </div>
            </div>
          ) : (
            <Item
              rules={[
                { required: false },
                { max: 500, message: 'Additional Info cannot exceed 500 characters!' },
              ]}
              name={[name, 'description']}
              label='Additional Info'
              normalize={v => v.trimStart()}
            >
              <TextArea autoSize={{ maxRows: 50 }} className='additionalInfo' />
            </Item>
          )}
          {isMobile && (
            <Item
              rules={[
                { required: false },
                { max: 500, message: 'Additional Info cannot exceed 500 characters!' },
              ]}
              name={[name, 'description']}
              label='Additional Info'
              normalize={v => v.trimStart()}
            >
              <TextArea autoSize={{ maxRows: 50 }} className='additionalInfo' />
            </Item>
          )}
        </div>
        {isTablet && !isMobile
          ? (
            <div className='privacyButtonWrapper'>
              <div className='poIconWrapper'>
                <div className='privacyOptionWrapper' style={isEarlyBird ? { top: '20.9%' } : {}}>
                  <Item
                    rules={[{ required: true, message: 'Choose Privacy Option' }]}
                    name={[name, 'privacyOption']}
                    label='Privacy Option'
                    initialValue={privacyOption}
                    className='no_margin item_radio-group poRadio'
                  >
                    <Radio.Group className='radioGroupWrapper' onChange={e => handlePrivacyOption(e.target.value)}>
                      <Radio checked value='visible'>Visible to Public</Radio>
                      <Radio value='invite'>Invite Only</Radio>
                    </Radio.Group>
                  </Item>
                </div>
                {privacyOptionsLabel()}
              </div>
            </div>
          ) : !isMobile ? (
            <ImageUploader uploaderTitle='Item' id={uniqueId} categoryData={categoryData} setImageUrl={setImageUrl} form={form} name={name} action={uploadBuyImage} />) : null}
      </div>
      <div className='privacyButtonWrapper'>
        {isTablet
          ? (
            <ImageUploader uploaderTitle='Item' id={uniqueId} categoryData={categoryData} setImageUrl={setImageUrl} form={form} name={name} action={uploadBuyImage} />
          ) : (
            <div className='poIconWrapper'>
              <div className='privacyOptionWrapper'>
                <Item
                  rules={[{ required: true, message: 'Choose Privacy Option' }]}
                  name={[name, 'privacyOption']}
                  label='Privacy Option'
                  initialValue={privacyOption}
                  className='no_margin item_radio-group poRadio'
                >
                  <Radio.Group className='radioGroupWrapper' onChange={e => handlePrivacyOption(e.target.value)}>
                    <Radio checked value='visible'>Visible to Public</Radio>
                    <Radio value='invite'>Invite Only</Radio>
                  </Radio.Group>
                </Item>
              </div>
              {privacyOptionsLabel()}
            </div>
          )}
        <div className='radioBuyWrapper'>
          <div className='radioItemField showAvailableBuy'>
            <Item
              name={[name, 'showAvailableTickets']}
              valuePropName='checked'
              className='item'
              initialValue={availableItem}
            >
              <Switch onChange={availableItemHandler} defaultChecked={availableItem} />
            </Item>
            <div className='text'>Show Available Items</div>
          </div>
          <div className='radioItemField earlyBirdDateItem'>
            <Item
              name={[name, 'hasEarlyBird']}
              className='item'
              valuePropName='checked'
            >
              <Switch onChange={onCheckEarlyBird} defaultChecked={isEarlyBird} />
            </Item>
            <div className='text'>Early Bird Special</div>
          </div>
          {isEarlyBird && isTablet && (
            <div className='earlyBirdFieldsItemWrapper'>
              <SingleDatePicker msDate label='Early Bird Date' namePrefix={name} nameText='earlyBirdDate' fromDate={earlyBirdDate} form={form} setFromDate={setEarlyBirdDate} title='Early Bird Date' />
              <Item
                name={[name, 'earlyBirdPrice']}
                dependencies={[[name, 'price']]}
                label='Early Bird Price'
                rules={[
                  { required: true, message: 'Enter price' },
                  minimumPriceValidator,
                ]}
                className='earlyBirdPrice'
              >
                <InputNumber placeholder='0' min={0} />
              </Item>
            </div>
          )}
        </div>
      </div>
      {isEarlyBird && !isTablet && (
        <div className='earlyBirdFieldsItemWrapper'>
          <SingleDatePicker msDate label='Early Bird Date' namePrefix={name} nameText='earlyBirdDate' fromDate={earlyBirdDate} form={form} setFromDate={setEarlyBirdDate} title='Early Bird Date' />
          <Item
            name={[name, 'earlyBirdPrice']}
            dependencies={[[name, 'price']]}
            label='Early Bird Price'
            rules={[
              { required: true, message: 'Enter price' },
              minimumPriceValidator,
            ]}
            className='earlyBirdPrice'
          >
            <InputNumber placeholder='0' min={0} />
          </Item>
        </div>
      )}
      <div className='btnWrapper'>
        <div className='removeBtn' onClick={() => onRemove(name)}>
          Delete Buy Item
        </div>
      </div>
    </div>
  )
}

export default BuyForm
