import React, { useState } from 'react'
import { Input } from 'antd'
import EmojiPicker from 'emoji-picker-react'
import { SendOutlined, SmileOutlined } from '@ant-design/icons'
import './index.styl'

const MessageInput = ({ sendMessage, disabled, isMobile, newMessage, setNewMessage }) => {
  const [emojiPickerVisible, setEmojiPickerVisible] = useState(false)

  const handleNewMessageChange = event => {
    setNewMessage(event.target.value)
  }

  const handleSendMessage = () => {
    const _newMessage = newMessage.trim()
    if (!disabled && _newMessage) {
      sendMessage() // вызываем sendMessage без аргументов
      setNewMessage('')
    }
  }

  const handleEmojiClick = emojiObject => {
    setNewMessage(prevMessage => `${prevMessage}${emojiObject.emoji}`)
    setEmojiPickerVisible(false)
  }

  const handleEmojiButtonClick = () => {
    setEmojiPickerVisible(!emojiPickerVisible)
  }

  return (
    <div className='message-input-chat'>
      <div className='messageWrapper'>
        {emojiPickerVisible && (
          <div className='emoji-picker-wrapper'>
            <EmojiPicker onEmojiClick={handleEmojiClick} />
          </div>
        )}
        <Input
          onChange={handleNewMessageChange}
          onPressEnter={handleSendMessage}
          placeholder='Write a comment...'
          className='input-field'
          disabled={disabled}
          value={newMessage}
          prefix={<SmileOutlined onClick={handleEmojiButtonClick} />}
          suffix={<SendOutlined onClick={handleSendMessage} />}
          size='large'
        />
      </div>
    </div>
  )
}

export default MessageInput
