import React, { useState, useContext, useEffect } from 'react'
import { Form, Switch, Divider } from 'antd'
import { CreateMemberLyfeContext } from '../../../../../../..'
// import TooltipInfo from '../../../../../../../../CreateEventV2/TooltipInfo'
import BuyForm from './BuyForm'
import './index.styl'

const { Item, List } = Form

const Buy = ({ currentPageDetails }) => {
  const { form } = useContext(CreateMemberLyfeContext)

  const [itemsSwitch, setItemsSwitch] = useState(false)
  const [items, setItems] = useState(form.getFieldValue('items') || [])
  // const mobile = window.innerWidth <= 730
  // const tooltipTitle = `Charity Raffles are limited to $30,000 in total ticket sales and $100 max price per ticket

  // Ticket Lyfe charges for Charity Raffle Tickets are $1 for Raffles priced up to $50 and $2 for higher priced Raffle Tickets

  // Do not pick Charity Raffles if you are doing a regular event with Random Draw Prizes--Because you can do a Random Draw on ANY Ticket Lyfe Event, the Charity Raffle limits above will not apply, and Ticket Lyfe treat your event as an ordinary Ticketed Event`

  // const additionalTooltip = (
  //  <TooltipInfo
  //    className='tooltipTitle'
  //    text={mobile ? '' : 'Additional info'}
  //    info={tooltipTitle}
  //  />
  // )

  useEffect(() => {
    if (!!currentPageDetails?.items?.length) {
      setItemsSwitch(true)
      setItems(currentPageDetails.items)
      form.setFieldsValue({
        items: currentPageDetails.items,
      })
    }
  }, [currentPageDetails, form])

  useEffect(() => {
    if (!items.length) {
      setItemsSwitch(false)
      form.setFieldsValue({
        itemsSwitch: false,
      })
    }
  }, [items, form])

  const handleSwitchChange = checked => {
    setItemsSwitch(checked)
    if (checked && !items.length) {
      const newItem = [{}]
      setItems(newItem)
      form.setFieldsValue({
        items: newItem,
      })
    }
  }

  const getItemsData = idx => {
    return items[idx]
  }

  return (
    <div className='sessionAndPassesWrapper'>
      <div className={itemsSwitch ? 'betweenWraper active' : 'betweenWraper'}>
        <div className='radioWrapper'>
          <Item style={{ marginBottom: 0 }} name='itemsSwitch' valuePropName='checked' className='item'>
            <Switch onChange={handleSwitchChange} />
          </Item>
          <div className='text'>Buy</div>
        </div>
        {/* <div className='tooltipWrapper'>{additionalTooltip}</div> */}
      </div>
      {itemsSwitch && (
        <List name='items'>
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, fieldKey, ...restField }) => (
                <BuyForm
                  form={form}
                  currentPageDetails={currentPageDetails}
                  categoryData={getItemsData(fieldKey)}
                  key={key}
                  name={name}
                  fieldKey={fieldKey}
                  onRemove={index => {
                    remove(index)
                    const updatedItems = form.getFieldValue('items')
                    setItems(updatedItems)
                    if (updatedItems.length === 0) {
                      form.setFieldsValue({
                        itemsSwitch: false,
                      })
                    }
                  }}
                  {...restField}
                />
              ))}
              <div
                id='addCategory'
                className='addCategoryBtn'
                onClick={() => {
                  add()
                  const updatedItems = form.getFieldValue('items')
                  setItems(updatedItems)
                }}
              >
                Add Another Buy Item
              </div>
            </>
          )}
        </List>
      )}
      <Divider />
    </div>
  )
}

export default Buy
