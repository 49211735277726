import React, { useEffect, useState } from 'react'
import { Pagination, Table } from 'antd'
import { events as eventsApi } from 'api'

import { ResponsiveCards } from 'components'
import moment from 'moment'
import NoEventsInformation from 'images/NoEventsInformation.svg'
import './index.styl'

const paginationDefault = {
  current: 1,
  total: 0,
  limit: 5,
}

const ScansTable = ({ data = [], eventID, setData }) => {
  const [isResponsive, setIsResponsive] = useState(false)
  const [pagination, setPagination] = useState(paginationDefault)
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    updateEvents(paginationDefault.current)
  }, [eventID])
  const updateEvents = (current = 1) => {
    const params = {
      limit: paginationDefault.limit,
      skip: (current * pagination.limit) - pagination.limit,
      search: '',
    }
    setLoading(true)
    if (eventID) {
      eventsApi
        .getScannedTickets(eventID, params).then(response => {
          setPagination({ ...pagination, total: response.data.data.count, current })
        })
        .catch(err => err)
        .finally(() => setLoading(false))
    }
  }

  const onChange = (current, pageSize) => {
    const skip = (current * pageSize) - paginationDefault.limit
    const params = {
      limit: paginationDefault.limit,
      search: '',
      skip,
    }
    setLoading(true)
    eventsApi
      .getScannedTickets(eventID, params)
      .then(response => {
        setData(response.data.data.scannedTickets)
        setPagination({ ...pagination, total: response.data.data.count, current })
      })
      .catch(err => err)
      .finally(() => setLoading(false))
  }

  const createSeatInformation = data => {
    return data.map(item => ({
      ...item,
      seatInformation: `${item.section === undefined ? ' ' : item.section} ${item.table === undefined ? ' ' : `Table: ${item.table}`} ${item.seat === undefined ? ' ' : `Seat: ${item.seat}`}`,
    }))
  }
  const checkNeedSeatingOrNo = (columns, data) => {
    for (let i = 0; i < data.length; i++) {
      if (data[i].section !== undefined || data[i].table !== undefined || data[i].seat !== undefined) {
        columns.push({
          title: 'Seating',
          dataIndex: 'seatInformation',
          key: 'seatInformation',
        })
        break
      }
    }
    return columns
  }
  useEffect(() => {
    setIsResponsive(window.innerWidth < 1025)
  }, [window.innerWidth])

  const columns = [
    {
      title: 'User name',
      dataIndex: 'fullName',
      key: 'fullName',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Ticket ID',
      dataIndex: '_id',
      key: '_id',
    },
    {
      title: 'Scanned on',
      dataIndex: 'scannedOn',
      key: 'scannedOn',
      render: (_, { scannedOn }) => scannedOn ? moment(scannedOn).format('MMMM DD, YYYY hh:mm a') : '',
    },
  ]

  const prepareCardData = data => [
    {
      title: 'User name',
      text: data?.fullName === undefined ? data.name || '' : data?.fullName || data.name,
    },
    {
      title: 'Email',
      text: data?.email,
    },
    {
      title: 'Ticket ID',
      text: data?._id,
    },
    {
      title: 'Scanned on',
      text: data?.scannedOn ? moment(data?.scannedOn).format('MMMM DD, YYYY hh:mm a') : '',
    },
    ...(data.section !== undefined || data.table !== undefined || data.seat !== undefined
      ? [
        {
          title: 'Seating',
          text: data.seatInformation,
        },
      ]
      : []),
  ]

  if (!data.length) {
    return (
      <div className='wrapperBodyEmpty'>
        <img src={NoEventsInformation} alt='Information Logo' />
        <div className='informationDescription'>No scanned tickets available yet</div>
      </div>
    )
  }

  return (
    <div className='scansTable'>
      {isResponsive
        ? (
          <>
            {createSeatInformation(data).map(ticket => (
              <ResponsiveCards key={ticket?._id} data={prepareCardData(ticket)} />
            ))}

          </>
        ) : (
          <Table
            pagination={false}
            dataSource={createSeatInformation(data)}
            columns={checkNeedSeatingOrNo(columns, data)}
            rowKey='_id'
          />
        )}
      {!!data?.length && !!pagination.total && (
        <Pagination
          current={pagination.current}
          pageSize={pagination.limit}
          total={pagination.total}
          showSizeChanger={false}
          className='pagination'
          disabled={loading}
          onChange={onChange}
        />
      )}
    </div>
  )
}

export default ScansTable
