import React from 'react'
// import { useSelector, useDispatch } from 'react-redux'
import './index.styl'
import { Table } from 'antd'

const Index = () => {
  // const [visible, setVisible] = useState(false)
  // const [loading, setLoading] = useState(false)
  // const { user } = useSelector(state => state.session)
  // const dispatch = useDispatch()
  // const { email, mobile, firstName, lastName, userName } = user

  const currentCol = [
    {
      title: 'Name',
      dataIndex: 'Name',
      key: 'name',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Renewal Date',
      dataIndex: 'renewalDate',
      key: 'renewalDate',
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
    },
  ]

  const historyCol = [
    {
      title: 'Name',
      dataIndex: 'Name',
      key: 'name',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Renewal Date',
      dataIndex: 'renewalDate',
      key: 'renewalDate',
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
    },
    {
      title: 'Download',
      dataIndex: 'download',
      key: 'Download',
    },
  ]

  return (
    <div className='sectionProfile'>
      <div className='head'>
        <div className='titleSection'>Subscriptions and Purchases</div>
      </div>
      <div className='body'>
        <div>
          <div className='tableTitle'>Current Subscriptions</div>
          <Table
            columns={currentCol}
            pagination={false}
            // loading={loading}
            dataSource={null}
            // here need date
            // dataSource={!loading ? dataSource : null}
            // title={() => catTabs()}
            className='detailsTable'
          />
        </div>

        <div className='tableTitle'>Purchase History</div>
        <Table
          columns={historyCol}
          pagination={false}
          // loading={loading}
          dataSource={null}
          // here need date
          // dataSource={!loading ? dataSource : null}
          // title={() => catTabs()}
          className='detailsTable'
        />
      </div>

    </div>
  )
}

export default Index
