import React, { useEffect, useState } from 'react'
import { Layout } from 'components'
import {
  ProfileImage,
  PersonalInformation,
  ChangePassword,
  BankDetails,
  SubscriptionsAndPurchases, ShippingAddress,
} from './Sections'
import { useSelector, useDispatch } from 'react-redux'
import { userBankAccount } from 'modules/session/session-actions'
import './index.styl'
import { Menu } from 'antd'

const MyProfile = () => {
  const { user } = useSelector(state => state.session)
  const dispatch = useDispatch()
  // const [closeBanner, setCloseBanner] = useState(true)
  useEffect(() => {
    window.dataLayer.push({ event: 'Profile' })
  }, [])

  useEffect(() => {
    if (user) {
      dispatch(userBankAccount())
    }
  }, [])
  // const handleCloseBanner = () => {
  //  setCloseBanner(false)
  // }

  const [current, setCurrent] = useState('personal')

  const onClick = e => {
    setCurrent(e.key)
  }

  const renderContent = () => {
    switch (current) {
      case 'personal':
        return (
          <div>
            <PersonalInformation />
            <ChangePassword />
          </div>)
      case 'subscriptionsAndPurchases':
        return <SubscriptionsAndPurchases />
      case 'bank':
        return <BankDetails />
      case 'address':
        return <ShippingAddress />
      default:
        return <div>Select a menu item to view content</div>
    }
  }
  return (
    <Layout className='wrapperMyProfile'>
      <div className='container'>
        <div className='centered'>
          <div className='mainTitle'>Profile Details</div>
          <div className='grid'>
            <div>
              <ProfileImage />
              {/* {closeBanner ? (
                <div className='wrapperProfileImage'>
                  <img onClick={handleCloseBanner} className='closeBanner' src={CloseBanner} alt='CloseBanner' />
                  <img className='mobileWidth' src={ProfileHelperImage} alt='ProfileHelperImage' />
                  <div className='textHelper'>Add Bank Account information in order to get paid via ACH... OR connect your Stripe Account instead to receive payments </div>
                </div>
              ) : null} */}
            </div>
            <div className='right'>
              <div className='menuProfile'>
                <Menu
                  onClick={onClick}
                  selectedKeys={[current]}
                  mode='horizontal'
                >
                  <Menu.Item key='personal'>Personal Information</Menu.Item>
                  <Menu.Item key='bank'>Bank Details</Menu.Item>
                  <Menu.Item key='subscriptionsAndPurchases'>Subscriptions and Purchases</Menu.Item>
                  {/* <Menu.Item key='address'>Shipping Address</Menu.Item> */}
                </Menu>
              </div>
              {renderContent()}

            </div>

            {/* main info */}
            {/* <VenueInformation /> */}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default MyProfile
