import React, { useState, useRef, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { Upload, Button, Progress, Modal } from 'antd'
import {
  uploadImage,
  eventsSetImages,
} from 'modules/events/events-actions'
import ModalCropImage from '../../../CreateEvent/MultiMediaContainer/ModalCropImage'
import fileIcon from 'images/fileIcon.svg'
import deleteIcon from 'images/close.svg'
import { blacklistFileTypes, getCroppedImg, notify } from 'utils'
import { CancelToken } from 'api/axios'
import { images } from 'apiv2'
import c from 'classnames'
import './index.styl'

const { Dragger } = Upload
const isDenied = (type = '') => !!type.match(blacklistFileTypes)
const onNotifyDenied = () => notify('Invalid file extension')

const cropDefault = { unit: '%', width: 240, aspect: 16 / 9 }

const SeatingScheme = ({ setTemplateCover, templateCover, templateCoverName, setTemplateCoverName }) => {
  const dispatch = useDispatch()

  // const { currentEventDetails } = useContext(CreateEventContext)
  const [loadingCover, setLoadingCover] = useState(false)
  const [progressCover, setProgressCover] = useState(false)
  const [ticketCover, setTicketCover] = useState(templateCover)
  const [ticketCoverName, setTicketCoverName] = useState(templateCoverName)
  const [fileNameTemp, setFileNameTemp] = useState('')
  const [fileDataBlob, setFileDataBlob] = useState(null)
  const [crop, setCrop] = useState(cropDefault)
  const [originalImage, setOriginalImage] = useState('')
  const cancelRequest = useRef(null)
  const imageRef = useRef(null)
  const onLoad = useCallback(img => (imageRef.current = img), [])
  const uploadProgress = setFunc => progressEvent => {
    setFunc(Math.round((progressEvent.loaded * 100) / progressEvent.total))
  }
  const clearTempState = () => {
    setFileDataBlob(null)
    setFileNameTemp('')
    setCrop(cropDefault)
  }

  // useEffect(() => {
  //   if (currentEventDetails?.imageUrl?.thumbnail1) {
  //     setTicketCover(currentEventDetails?.imageUrl?.thumbnail1)
  //     setTicketCoverName(getCoverName(currentEventDetails?.imageUrl?.thumbnail1))
  //   }
  //   if (currentEventDetails?.attachments?.length) {
  //     dispatch(eventsSetFiles(currentEventDetails?.attachments))
  //     setFilesList(prepareFilesList(currentEventDetails?.attachments))
  //   }
  //   return () => dispatch(eventsSetFiles([]))
  // }, [currentEventDetails])

  const handleConfirmModify = async () => {
    setLoadingCover(true)
    await getCroppedImg(imageRef.current, crop, ticketCoverName)
      .then(file => {
        const onUploadProgress = uploadProgress(setProgressCover)
        const cancelToken = new CancelToken(cancel => (cancelRequest.current = { cancel }))
        dispatch(uploadImage(originalImage, file, { onUploadProgress, cancelToken, isTemplate: true })).then(response => {
          if (response) {
            setTicketCover(response.original)
            setTemplateCover(response)
            setTicketCoverName(fileNameTemp)
            setTemplateCoverName && setTemplateCoverName(fileNameTemp)
            clearTempState()
          }
          setLoadingCover(false)
          setProgressCover(null)
          cancelRequest.current = null
        })
      })
      .catch(err => {
        notify(err?.message)
        setLoadingCover(false)
      })
  }

  const onCancel = () => {
    clearTempState()
    if (cancelRequest.current?.cancel) {
      cancelRequest.current.cancel('Upload canceled')
    }
  }

  const onFileChanged = async image => {
    if (isDenied(image?.type)) {
      return onNotifyDenied()
    }
    setLoadingCover(true)
    setFileNameTemp(image?.file?.name)
    setOriginalImage(image?.file?.originFileObj)

    const onUploadProgress = uploadProgress(setProgressCover)
    images.preparePoster(image, { onUploadProgress, responseType: 'blob' })
      .then(response => {
        const blob = URL.createObjectURL(response.data)
        setFileDataBlob(blob)
        setLoadingCover(false)
      })
      .catch(err => {
        notify(err?.message || err?.response?.data?.message)
        setFileNameTemp(null)
        setLoadingCover(false)
      })
  }

  const onSuccess = ({ onSuccess }) => onSuccess('ok')

  const onRemoveCover = () => {
    Modal.confirm({
      title: 'Are you sure you want to remove photo?',
      cancelText: 'No',
      okText: 'Yes',
      onOk: () => {
        setTicketCover('')
        setTicketCoverName('')
        dispatch(eventsSetImages({}))
      },
    })
  }

  const showProgress = (progress, size = 52, isDark = false) => typeof progress === 'number' && (
    <div className={c('progressContainer', { isDark })}>
      <Progress
        trailColor='transparent'
        strokeLinecap='square'
        strokeColor='#19B47D'
        percent={progress}
        strokeWidth={10}
        showInfo={false}
        type='circle'
        width={size}
      />
    </div>
  )

  return (
    <div className='wrapperBackgroundSeating'>
      <div className='uploadContainer'>
        <div className='uploadLabel'>
          {/* <span className='required'>*</span> */}
          Seating Scheme Image (Optional)
        </div>
        <div className={c('uploadSpinner', { isFull: !!ticketCover })}>
          <Dragger
            accept='image/png, image/jpeg'
            onChange={onFileChanged}
            customRequest={onSuccess}
            showUploadList={false}
            disabled={loadingCover}
            name='image'
          >
            <div className={c('upload', { ticketCover: !!ticketCover })}>
              {loadingCover && showProgress(progressCover)}
              {ticketCover && <img src={ticketCover} alt='' />}
              <div className='coverBackground' />
              <div className='uploadTitle'>Upload any type of files using Drag & Drop <br /> or
              </div>
              <div className='uploadBtn'>
                {ticketCover ? <>Change <span className='xsHidden'>Photo</span></> : <div className='chooseSchemeStyle'>Choose File</div>}
              </div>
              {/* <div className='uploadInfo'>*Only jpg/.JPG/.png/.bmp/.jpeg file formats are allowed</div> */}
            </div>
          </Dragger>
          {fileDataBlob && (
            <ModalCropImage
              onConfirm={handleConfirmModify}
              className='cropImageModal'
              loading={loadingCover}
              onCancel={onCancel}
              file={fileDataBlob}
              setCrop={setCrop}
              onLoad={onLoad}
              crop={crop}
            />
          )}
        </div>
      </div>
      <div className='fileListContainer xsVisible'>
        {(ticketCover || loadingCover) && (
          <div className='fileContainer'>
            {loadingCover && showProgress(progressCover, 24, !ticketCover)}
            {ticketCover ? (
              <img src={ticketCover} alt=' ' />
            ) : (
              <div className='iconContainer'><img src={fileIcon} alt='' className='fileIcon' /></div>
            )}
            <div className='fileName'>{ticketCoverName || 'Loading...'}</div>
            <Button className='deleteBtn' type='link' onClick={onRemoveCover} disabled={loadingCover}>
              <img src={deleteIcon} />
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}

export default SeatingScheme
