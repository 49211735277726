import React, { useEffect, useState } from 'react'
import './index.styl'
import AnnouncementCard from './AnnouncementCard'
import HoursCalendar from './HoursCalendar'
import SessionAndPassesCard from './SessionAndPassesCard'
import UpcomingEventsCard from './UpcomingEventsCard'
import { useSelector } from 'react-redux'
import NewChatFrame from './NewChatFrame'

const Body = ({ announcements, sessions, passes, upcomingEvents, items, workingHours, id, userId, additionalChats, currentUser }) => {
  const [chats, setChats] = useState([])
  const [selectedChatId, setSelectedChatId] = useState(null)
  const { token } = useSelector(state => state.session)
  const {
    isMembershipMember,
  } = useSelector(state => state.memberships)

  useEffect(() => {
    setChats(additionalChats)
  }, [additionalChats])
  useEffect(() => {
    if (chats?.length > 0 && selectedChatId === null) {
      setSelectedChatId(chats[0]?.id)
    }
  }, [chats, selectedChatId])

  const updateChats = (chatID, lastMessage) => {
    setChats(prevChats =>
      prevChats.map(chat =>
        chat.id === chatID ? { ...chat, lastMessage } : chat,
      ),
    )
  }
  const isOwnerPage = currentUser === userId

  return (
    <div className='memberBodyWrapper'>
      {announcements?.length > 0 && <AnnouncementCard announcements={announcements} />}
      {workingHours && <HoursCalendar schedule={workingHours} />}
      <SessionAndPassesCard id={id} userId={userId} sessions={sessions} passes={passes} items={items} />
      {upcomingEvents?.length > 0 && <UpcomingEventsCard upcomingEvents={upcomingEvents} />}
      {selectedChatId && (isMembershipMember || isOwnerPage) && <NewChatFrame currentUser={currentUser} userId={userId} chats={chats} setSelectedChatId={setSelectedChatId} token={token} chatID={selectedChatId} updateChats={updateChats} />}
    </div>
  )
}

export default Body
