import { SESSION_IS_MOBILE, SESSION_GUEST_RESERVATION_TOKEN, ADMIN_BANK_DETAILS, ADMIN_BANK_DETAILS_CLEAR, BANK_DETAILS_UPDATE, LOGO_FOR_VENUE_UPDATE, SESSION_LOGIN, SESSION_LOGOUT, SESSION_SKIP_TUTORIAL, SUBSCRIBE_UPDATE } from './session-constants'

export const sessionInitialState = {
  user: null,
  bankDetails: null,
  adminBankDetails: null,
  token: null,
  isMobile: false,
  skipTutorial: false,
  logo: null,
  guestReservationToken: null,
}

export const sessionReducer = (state = sessionInitialState, action) => {
  const { type, payload } = action
  switch (type) {
    case SESSION_IS_MOBILE:
      return { ...state, isMobile: payload }
    case SESSION_LOGIN:
      return { ...state, user: payload, token: payload.token }
    case SUBSCRIBE_UPDATE:
      return { ...state, user: { ...state?.user, isSubscriptionActive: payload } }
    case SESSION_LOGOUT:
      return { ...state, user: null, token: null, logo: null, bankDetails: null, adminBankDetails: null }
    case LOGO_FOR_VENUE_UPDATE:
      return { ...state, logo: payload }
    case BANK_DETAILS_UPDATE:
      return { ...state, bankDetails: payload }
    case ADMIN_BANK_DETAILS:
      return { ...state, adminBankDetails: payload }
    case ADMIN_BANK_DETAILS_CLEAR:
      return { ...state, adminBankDetails: null }
    case SESSION_SKIP_TUTORIAL:
      return { ...state, skipTutorial: payload }
    case SESSION_GUEST_RESERVATION_TOKEN:
      return { ...state, guestReservationToken: payload }
    default:
      return state
  }
}
