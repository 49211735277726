/* eslint-disable react/jsx-closing-tag-location */
import React, { useEffect, useState } from 'react'
// import { useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import queryString from 'query-string'
import { Select, notification } from 'antd'
import EventDetails from './EventDetails'
import ScansTable from './ScansTable'
import QRReader from './QRReader'
import NotifySound from './AudioService'
import { eventsV2 } from 'apiv2'
import { parseQuerySearch } from './helper'
import { DebounceInput } from 'react-debounce-input'
import arrow from 'images/simpleArrow.svg'
import DropDownIcon from 'images/DropDownIcon.svg'

import './index.styl'

const { Option } = Select
const environmentCamera = 'environment'
// const userCamera = 'user'
const options = [
  { key: 'environment', title: 'Main Camera' },
  { key: 'user', title: 'Front Camera' },
  { key: 'device', title: 'Scanning Device' },
]

const ScannerDetails = ({ event, loading, tickets, pagination, onChange }) => {
  // const { isMobile } = useSelector(state => state.session)
  const [camera, setCamera] = useState(environmentCamera)
  const [scannedTickets, setScannedTickets] = useState(tickets)
  const [barcodeInputValue, updateBarcodeInputValue] = useState('')
  const [scanning, setScanning] = useState(false)
  const [scanningStatus, setScanningStatus] = useState(null)
  const [isSelectActive, setIsSelectActive] = useState(false)
  const { search } = useLocation()
  const queryParams = queryString.parse(search)
  const categoryId = queryParams.categoryID
  const categoryID = categoryId === '0' ? '' : categoryId
  function onChangeBarcode (data) {
    updateBarcodeInputValue(data.target.value)
    const pattern = data.target.value.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g)
    if (pattern) {
      const barcode = parseQuerySearch(data.target.value, 'barcode')
      if (barcode) {
        eventsV2
          .scanTicketV2({ barcode, eventID: event?._id, categoryID })
          .then(({ data: { data: newScan, message } }) => {
            scannedTickets.splice(4, 1)
            setScannedTickets([newScan, ...scannedTickets])
            notification.success({ message: message })
            setStatus('success', message)
            updateBarcodeInputValue('')
          })
          .catch(error => {
            notification.warning({ message: error?.response?.data?.message })
            setStatus('fail', error?.response?.data?.message)
            updateBarcodeInputValue('')
          })
      } else {
        setStatus('fail', 'You are scanning the wrong code')
        updateBarcodeInputValue('')
      }
    } else {
      setStatus('fail', 'You are scanning the wrong code')
      updateBarcodeInputValue('')
    }
  }
  function barcodeAutoFocus (e) {
    if (isSelectActive) {
      document.getElementById('SearchbyScanning').focus()
      const target = e.currentTarget
      setTimeout(
        function () {
          target.focus()
        },
        5,
      )
    }
  }
  useEffect(() => {
    setScanning(loading)
  }, [loading])

  useEffect(() => {
    setScannedTickets(tickets)
  }, [tickets])

  const handleFacingMode = mode => setCamera(mode)

  const setStatus = (status, message) => {
    NotifySound(status)
    setScanningStatus({ status, message })
    setTimeout(() => {
      setScanningStatus(null)
      setScanning(false)
    }, 3000)
  }

  const onScan = data => {
    if (data && event?._id) {
      setScanning(true)
      const barcode = parseQuerySearch(data, 'barcode')
      if (barcode) {
        eventsV2
          .scanTicketV2({ barcode, eventID: event?._id, categoryID })
          .then(({ data: { data: newScan, message } }) => {
            scannedTickets.splice(4, 1)
            setScannedTickets([newScan, ...scannedTickets])
            setStatus('success', message)
          })
          .catch(error => setStatus('fail', error?.response?.data?.message))
      } else {
        setStatus('fail', 'You are scanning the wrong code')
      }
    }
  }
  const handleSelectOnBlur = () => {
    if (camera === 'device') {
      document.getElementById('SearchbyScanning').focus()
      setIsSelectActive(true)
    }
  }
  const renderSelect = (
    <Select
      suffixIcon={<img src={DropDownIcon} alt=' ' />}
      getPopupContainer={node => node?.parentNode}
      dropdownClassName='readerSelectMain'
      onChange={handleFacingMode}
      className='readerSelect'
      onDropdownVisibleChange={() => setIsSelectActive(false)}
      onBlur={handleSelectOnBlur}
      defaultValue={camera}
    >
      {options.map(i => <Option key={i.key} value={i.key}>{i.title}</Option>)}
    </Select>
  )

  return (
    <div className='scannerDetails container'>
      <div className='scannerLinkWrapper'>
        <Link to='/scanner'>
          <img src={arrow} alt='arrow' className='headerBackArrow' />
          <span>Back</span>
        </Link>
      </div>
      <div className='flexWrapper'>
        <div className='eventInfoContainer'>
          <EventDetails event={event} />
        </div>
        {camera !== 'device'
          ? <>
            <div className='eventReader'>
              <QRReader
                scanning={scanning || loading}
                setStatus={setScanningStatus}
                response={scanningStatus}
                onScan={onScan}
                camera={camera}
              />
              <div className='xsVisible'>
                {renderSelect}
              </div>
            </div>
            <div className='eventInfoContainer smHidden' />
          </>
          : <>
            <div className='eventReaderDevice'>
              <DebounceInput
                debounceTimeout={300}
                onChange={e => onChangeBarcode(e)}
                value={barcodeInputValue}
                id='SearchbyScanning'
                autoFocus
                style={{ opacity: '0', position: 'absolute' }}
                onBlur={e => barcodeAutoFocus(e)}
              />
              <div className='xsVisible'>
                {renderSelect}
              </div>
            </div>
          </>}
        <div className='eventTable'>
          <div className='bottom'>
            <div className='readerTitle'>Scanned Tickets</div>
            <div className='xsHidden'>
              {renderSelect}
            </div>
          </div>
          <ScansTable data={scannedTickets} setData={setScannedTickets} loading={loading} onChange={onChange} eventID={event?._id} pagination={pagination} />
        </div>
      </div>
    </div>
  )
}

export default ScannerDetails
