import React, { useEffect } from 'react'
// import { useSelector } from 'react-redux'
import { MemberLyfeLayout, CustomButton } from 'components'

import { useParams } from 'react-router-dom'
// import { auth as v2auth } from 'apiv2'

import './index.styl'

const ParentApproval = () => {
  // const [message, setMessage] = useState('')
  const { token } = useParams()
  // const { user } = useSelector(state => state.session)
  useEffect(() => {
    // if (user) {
    //  setMessage('Please logout and try again.')
    // }
    // v2auth.verificationEmail(token, lang)
    //  .then(() => {
    //    setMessage('Your account has been verified successfully')
    //  })
    //  .catch(err => {
    //    setMessage(err?.response?.data?.message || err?.response?.data?.error)
    //  })
  }, [token])
  return (
    <MemberLyfeLayout>
      <div className='wrapperApprovalScreen'>
        <div className='parentApprovalTitle'>Parrent Approve</div>
        <div className='parentAproveBtnWrapper'>
          <CustomButton
            // onClick={handleFetchMore}
            text='Approve'
            type='dark'
            className='approveBtn'
          />
          <CustomButton
            // onClick={handleFetchMore}
            text='Disapprove'
            className='disaproveBtn'
            type='dark'
          />
        </div>
      </div>
    </MemberLyfeLayout>
  )
}

export default ParentApproval
