import React, { useState } from 'react'
import { useHistory, Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { CustomButton } from 'components'
import { Form, Input } from 'antd'
import { login } from 'modules/session/session-actions'
import './index.styl'

const { Item } = Form

const FormSignIn = ({ title = '', redirectTo, goGuest }) => {
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const history = useHistory()
  const onFinish = form => {
    setLoading(true)
    const data = {
      email: form.email.trim(),
      password: form.password,
      role: 'customer',
      deviceType: 'WEB',
    }
    const callback = () => {
      setLoading(false)
      window.dataLayer.push({ event: 'login-success' })
    }
    dispatch(login(data, callback, true)).then(data => data?.isVenueUser ? history.push(`/venue/${data?.venueUrl}`) : history.push(redirectTo)).catch(() => setLoading(false))
  }

  const resetPassword = () => history.push('/auth/reset-password')
  const onPressSpace = e => {
    if (e.key === ' ') {
      e.preventDefault()
    }
  }
  return (
    <div className='wrapperFormSignIn'>
      <div className='titleLogIn'>{title}</div>
      <Form layout='vertical' className='formLogIn' onFinish={onFinish}>
        <Item
          name='email' label='Email or Username'
          rules={[{ required: true, whitespace: true, message: 'Please enter email or username' }]}
          validateTrigger='onBlur'
        >
          <Input className='input' />
        </Item>
        <Item
          name='password'
          label='Password'
          className='passwordSignIn'
          rules={[
            { required: true, message: 'Please enter password' },
            { min: 6, message: 'Password must be at least 6 characters.', transform: value => value?.trim() },
          ]}
          validateTrigger='onBlur'
        >
          <Input.Password onKeyDown={onPressSpace} type='Password' className='input' />
        </Item>

        <div className='link' onClick={resetPassword}>Forgot password?</div>
        <div className='wrapperActions'>
          {goGuest && <CustomButton text='Continue as a guest' type='white' className='additionalBtn' htmlType='button' onClick={goGuest} />}
          <CustomButton text='Log In' type='dark' className='logInButton' htmlType='submit' loading={loading} />
        </div>
        <div className='linkToSignUpWrapper'>
          <div className='noAccountTitle'>Don’t have an account?</div>
          <Link className='signUpButtonLink' to='/auth/choose-account'>Sign Up</Link>
        </div>
      </Form>
    </div>
  )
}

export default FormSignIn
