import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Slider, CustomButton } from 'components'
import { Skeleton, Tooltip } from 'antd'

import cardImg from 'images/card.png'

import NoEventsInformation from 'images/NoEventsInformation.svg'
import './index.styl'

const SliderHome = ({ loaded }) => {
  const { memberPublicEvents = [] } = useSelector(state => state.memberships)
  const history = useHistory()
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 2200 },
      items: 5,
    },
    largeDesktop: {
      breakpoint: { max: 2200, min: 1800 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 1800, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  }
  const onClick = item => history.push(`/memberPage/${item.id}/${item.userID}`)
  // show first 15 events
  const sliderEvents = memberPublicEvents.slice(0, 15)
  const slider = (
    <Slider mlDots showDots responsive={responsive}>
      {sliderEvents.map((item, index) => (
        <div key={index} className='wrapperSlideMember'>
          <img
            src={item?.imageUrl?.thumbnail1}
            onError={e => (e.target.src = cardImg)}
            alt='card'
            className='imgCardMember'
          />
          <div className='sliderDetailMember'>
            <div className='eventTitle'>{item.eventName}</div>
            <div className='memberSliderEventDescription'>{item.eventDescription}</div>
            <CustomButton
              onClick={() => onClick(item)}
              text='Read More'
              className='buttonReadMore'
              type='dark'
            />
          </div>
        </div>
      ))}
    </Slider>
  )
  const placeholder = (
    <div className='wrapperBodyEmpty'>
      <Tooltip title='No Events available'>
        <img src={NoEventsInformation} alt='Information Logo' />
      </Tooltip>
      <div className='informationDescription'>No Events available yet</div>
    </div>
  )
  return (
    <div>
      {!loaded
        ? (
          <div className='sliderSkeleton'>
            <Skeleton.Image className='sliderSkeletonImage' loading={!loaded} active={!loaded} />
            <Skeleton.Image className='sliderSkeletonImage' loading={!loaded} active={!loaded} />
            <Skeleton.Image className='sliderSkeletonImage' loading={!loaded} active={!loaded} />
            <Skeleton.Image className='sliderSkeletonImage' loading={!loaded} active={!loaded} />
          </div>
        )
        : sliderEvents.length ? slider : placeholder}
    </div>)
}

export default SliderHome
