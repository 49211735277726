import React, { useEffect } from 'react'
import { InviteActionButtons } from 'components'
import onDemandBadge from 'images/OnDemand.svg'
import { useHistory, useLocation } from 'react-router'
import { getUtcOffsetDate } from 'utils'
import './index.styl'

const Card = ({ item, tag = '', guest = false, onClick, setShowModal, onSelectInvite = () => { } }) => {
  const isInvite = item?.inviteType !== undefined
  const history = useHistory()
  const { pathname } = useLocation()
  const { id, eventID, onDemand = false, bgColor, ticketType, ticketPrice, ticketsCount, locations, location, eventName, imageUrl, eventType, timeZone, ticketPasses, eventVenue, presentedBy, eventStart, eventEnd, eventRainDate, ticketName, parentApproval } = item
  // console.log('allitem', item)
  const ticketData = {
    guest,
    isInvite,
    ticketName,
    id: id,
    eventName: eventName,
    address: location,
    locations: locations,
    eventType: eventType,
    timeZone: timeZone,
    venue: eventVenue,
    presentedBy: presentedBy,
    startDate: eventStart,
    rainDate: getUtcOffsetDate(eventRainDate, 'MMMM DD, YYYY', timeZone),
    endDate: eventEnd,
    image: imageUrl?.thumbnail1,
    parentApproval,
    imageUrl: imageUrl,
    backgroundColor: bgColor,
    quantity: isInvite ? item.quantity : ticketsCount,
    originalPrice: isInvite ? item.price : ticketPrice,
    resellPrice: isInvite && item.price,
    ticketPasses: ticketPasses || [],
    inviteID: isInvite && item._id,
    inviteStatus: item?.inviteStatus,
    ticketType: ticketType,
    eventID: eventID,
  }

  const { image, quantity, venue = 'Virtual event' } = ticketData
  const backgroundColor = bgColor
  useEffect(() => {
    setShowModal(false)
    onClick(ticketData)
  }, [ticketData.quantity])

  const handleClick = () => {
    if (ticketType.includes('MEMBERSHIP')) {
      history.push({
        pathname: `/detail-membership/${eventID}`,
        state: { ticketData },
      })
    } else {
      setShowModal(true)
      onClick(ticketData)
    }
  }

  const isColorBlack = backgroundColor === '#FFFF00' || backgroundColor === '#FFCC00' || backgroundColor === '#FF9900' || backgroundColor === '#FF6600' || backgroundColor === '#99CC00' || backgroundColor === '#99FF00' || backgroundColor === '#33FF00' || backgroundColor === '#33CC00'
  return (
    <div className='wrapperCardWallet'>
      <div
        className='body' onClick={handleClick}
      >
        <div className='imgWrap'>
          <img src={image} alt={eventName} className='myWallet-image' />
          {/* <div className='background' /> */}
          {onDemand && <img src={onDemandBadge} alt=' ' className='onDemandBadge' draggable={false} />}
        </div>
      </div>
      <div className='coloredLine' style={{ backgroundColor: `${backgroundColor}`, height: '32px' }}>
        <div className='quantityText' style={{ color: isColorBlack ? '#1A1D24' : '#FFFFFF' }}>{ticketName}</div>
        <div className='quantityText' style={{ color: isColorBlack ? '#1A1D24' : '#FFFFFF' }}>{quantity} Tickets</div>
      </div>
      <div className='foot'>
        <div className='top'>
          <div className='title'>{eventName}</div>
          <div className='subtitle'>
            <div className='description'>
              <div className='venueName'>{ticketType.includes('MEMBERSHIP') ? ticketName : venue}</div>
              <div>{getUtcOffsetDate(eventStart, 'MMMM DD, YYYY')}</div>
            </div>
            <div
              className={`viewBtn ${pathname.includes('member') ? 'memberColor' : '' }`} onClick={handleClick}
            >View More
            </div>
          </div>
        </div>
        {isInvite && item.inviteStatus !== 'ACCEPTED' && item.inviteStatus !== 'REJECTED' &&
          <div className='bottom'>
            <InviteActionButtons inviteData={ticketData} onSelectInvite={onSelectInvite} />
          </div>}
      </div>
    </div>
  )
}

export default Card
