import React, { useState, useRef, useCallback, useEffect, useContext } from 'react'
import { useDispatch } from 'react-redux'
import { Upload, Button, Progress, Modal } from 'antd'
import {
  uploadImage,
  uploadAttachment,
  eventsDeleteFile,
  eventsSetImages,
  eventsSetFiles,
} from 'modules/events/events-actions'
import ModalCropImage from './ModalCropImage'
import fileIcon from 'images/fileIcon.svg'
import deleteIcon from 'images/close.svg'
import { blacklistFileTypes, getCroppedImg, notify } from 'utils'
import { CancelToken } from 'api/axios'
import { images } from 'apiv2'
import c from 'classnames'
import { CreateEventContext } from '../'
import './index.styl'

const { Dragger } = Upload
const isDenied = (type = '') => !!type.match(blacklistFileTypes)
const isSizeDenied = (size = 0) => size > 500 * 1024 * 1024
const onNotifyDenied = () => notify('Invalid file extension')
const onNotifyDeniedSize = () => notify('You cannot upload a file larger than 500 MB')

const getCoverName = imgUrl => imgUrl.replace(/^.*[\\\/]/, '')
const cropDefault = { unit: '%', width: 240, aspect: 16 / 9 }

const MultiMediaContainer = () => {
  const dispatch = useDispatch()

  const { currentEventDetails } = useContext(CreateEventContext)
  const [loadingCover, setLoadingCover] = useState(false)
  const [progressCover, setProgressCover] = useState(false)
  const [loading, setLoading] = useState(null)
  const [progress, setProgress] = useState(null)
  const [ticketCover, setTicketCover] = useState('')
  const [ticketCoverName, setTicketCoverName] = useState(getCoverName(ticketCover))
  const [fileNameTemp, setFileNameTemp] = useState('')
  const [filesList, setFilesList] = useState([])
  const [fileDataBlob, setFileDataBlob] = useState(null)
  const [crop, setCrop] = useState(cropDefault)
  const [originalImage, setOriginalImage] = useState('')

  const cancelRequest = useRef(null)
  const imageRef = useRef(null)
  const onLoad = useCallback(img => (imageRef.current = img), [])

  const uploadProgress = setFunc => progressEvent => {
    setFunc(Math.round((progressEvent.loaded * 100) / progressEvent.total))
  }

  const clearTempState = () => {
    setFileDataBlob(null)
    setFileNameTemp('')
    setCrop(cropDefault)
  }

  const prepareFilesList = (files = []) => files.map(obj => {
    return { uid: obj?.original, name: obj?.fileName, uploadedUrl: obj?.original, imgData: obj?.thumbnail }
  })

  useEffect(() => {
    if (currentEventDetails?.imageUrl?.thumbnail1) {
      setTicketCover(currentEventDetails?.imageUrl?.thumbnail1)
      setTicketCoverName(getCoverName(currentEventDetails?.imageUrl?.thumbnail1))
    }
    if (currentEventDetails?.attachments?.length) {
      dispatch(eventsSetFiles(currentEventDetails?.attachments))
      setFilesList(prepareFilesList(currentEventDetails?.attachments))
    }
    return () => dispatch(eventsSetFiles([]))
  }, [currentEventDetails])

  const handleConfirmModify = async () => {
    setLoadingCover(true)
    await getCroppedImg(imageRef.current, crop, ticketCoverName)
      .then(file => {
        const onUploadProgress = uploadProgress(setProgressCover)
        const cancelToken = new CancelToken(cancel => (cancelRequest.current = { cancel }))
        dispatch(uploadImage(originalImage, file, { onUploadProgress, cancelToken })).then(response => {
          if (response) {
            setTicketCover(response.original)
            setTicketCoverName(fileNameTemp)
            clearTempState()
          }
          setLoadingCover(false)
          setProgressCover(null)
          cancelRequest.current = null
        })
      })
      .catch(err => {
        notify(err?.message)
        setLoadingCover(false)
      })
  }

  const onCancel = () => {
    clearTempState()
    if (cancelRequest.current?.cancel) {
      cancelRequest.current.cancel('Upload canceled')
    }
  }

  const onFileChanged = async image => {
    if (isDenied(image?.type)) {
      return onNotifyDenied()
    }
    setLoadingCover(true)
    setFileNameTemp(image?.file?.name)
    setOriginalImage(image?.file?.originFileObj)

    const onUploadProgress = uploadProgress(setProgressCover)
    images.preparePoster(image, { onUploadProgress, responseType: 'blob' })
      .then(response => {
        const blob = URL.createObjectURL(response.data)
        setFileDataBlob(blob)
        setLoadingCover(false)
      })
      .catch(err => {
        notify(err?.message || err?.response?.data?.message)
        setFileNameTemp(null)
        setLoadingCover(false)
      })
  }

  const onChangeFiles = async data => {
    if (data?.file?.status === 'uploading') {
      setLoading(true)
      setProgress(Math.round((data?.file?.percent || 0) * 100))
    } else if (data?.file?.status === 'done') {
      if (filesList.length > 4) {
        return
      }
      if (isDenied(data?.file?.type)) {
        return onNotifyDenied()
      }
      if (isSizeDenied(data?.file?.size)) {
        return onNotifyDeniedSize()
      }
      setLoading(true)
      let imgData = {}
      if (data.file.type.includes('image')) {
        imgData = await new Promise(resolve => {
          const reader = new FileReader()
          reader.readAsDataURL(data.file.originFileObj)
          reader.onload = () => resolve(reader.result)
        })
        data.file.imgData = imgData
      }
      const _fileList = [...filesList, data.file]
      setFilesList(_fileList)
      const cancelToken = new CancelToken(cancel => {
        cancelRequest.current = { cancel }
      })
      const options = { onUploadProgress: uploadProgress(setProgress), cancelToken }
      dispatch(uploadAttachment(data, options)).then(response => {
        if (response) {
          setFilesList(_fileList.map(file => {
            if (file.uid === data.file.uid) {
              file.uploadedUrl = response?.original
              if (response?.thumbnail) {
                file.imgData = response?.thumbnail
              }
            }
            return file
          }))
        } else {
          setFilesList(filesList.filter(f => f.uid !== data.file.uid))
        }
        setLoading(false)
        setProgress(null)
        cancelRequest.current = null
      })
    } else if (data?.file?.status === 'error') {
      setLoading(false)
      setProgress(null)
      setFilesList(prevList => prevList.filter(item => item.uid !== data?.file?.uid))
    }
  }

  const onSuccess = ({ onSuccess }) => onSuccess('ok')
  const onClick = file => {
    Modal.confirm({
      title: `Are you sure you want to remove ${file.name}？`,
      cancelText: 'No',
      okText: 'Yes',
      onOk: () => {
        if (file.uploadedUrl) {
          dispatch(eventsDeleteFile(file.uploadedUrl))
        } else if (cancelRequest.current?.cancel) {
          cancelRequest.current.cancel('Upload canceled')
        }
        setFilesList(filesList.filter(f => f.uid !== file.uid))
      },
    })
  }
  const onRemoveCover = () => {
    Modal.confirm({
      title: 'Are you sure you want to remove photo?',
      cancelText: 'No',
      okText: 'Yes',
      onOk: () => {
        setTicketCover('')
        setTicketCoverName('')
        dispatch(eventsSetImages({}))
      },
    })
  }

  const showProgress = (progress, size = 52, isDark = false) => typeof progress === 'number' && (
    <div className={c('progressContainer', { isDark })}>
      <Progress
        trailColor='transparent'
        strokeLinecap='square'
        strokeColor='#19B47D'
        percent={progress}
        strokeWidth={10}
        showInfo={false}
        type='circle'
        width={size}
      />
    </div>
  )

  return (
    <div className='wrapperBackgroundTicket'>
      <div className='uploadContainer'>
        <div className='uploadLabel'>
          <span className='required'>*</span>
          Ticket cover
        </div>
        <div className={c('uploadSpinner', { isFull: !!ticketCover })}>
          <Dragger
            accept='image/png, image/jpeg'
            onChange={onFileChanged}
            customRequest={onSuccess}
            showUploadList={false}
            disabled={loadingCover}
            name='image'
          >
            <div className={c('upload', { ticketCover: !!ticketCover })}>
              {loadingCover && showProgress(progressCover)}
              {ticketCover && <img src={ticketCover} alt='' />}
              <div className='coverBackground' />
              <div className='uploadTitle'>Upload an image using Drag & Drop <br /> or</div>
              <div className='uploadBtn'>
                {ticketCover ? <>Change <span className='xsHidden'>Photo</span></> : 'Select file'}
              </div>
              <div className='uploadInfo'>*Only jpg/.JPG/.png/.bmp/.jpeg file formats are allowed</div>
            </div>
          </Dragger>
          {fileDataBlob && (
            <ModalCropImage
              onConfirm={handleConfirmModify}
              className='cropImageModal'
              loading={loadingCover}
              onCancel={onCancel}
              file={fileDataBlob}
              setCrop={setCrop}
              onLoad={onLoad}
              crop={crop}
            />
          )}
        </div>
      </div>
      <div className='fileListContainer xsVisible'>
        {(ticketCover || loadingCover) && (
          <div className='fileContainer'>
            {loadingCover && showProgress(progressCover, 24, !ticketCover)}
            {ticketCover ? (
              <img src={ticketCover} alt=' ' />
            ) : (
              <div className='iconContainer'><img src={fileIcon} alt='' className='fileIcon' /></div>
            )}
            <div className='fileName'>{ticketCoverName || 'Loading...'}</div>
            <Button className='deleteBtn' type='link' onClick={onRemoveCover} disabled={loadingCover}>
              <img src={deleteIcon} />
            </Button>
          </div>
        )}
      </div>
      <div className='uploadContainer'>
        <div className='uploadLabel'>Additional files</div>
        <Dragger
          disabled={filesList.length > 4 || loading}
          customRequest={onSuccess}
          onChange={onChangeFiles}
          showUploadList={false}
          name='files'
        >
          <div className='upload specialDesignGutter'>
            <div className='uploadTitle'>Upload any type of files using Drag & Drop <br /> or</div>
            <div className='uploadBtn'>Select file</div>
            <div className='uploadInfo sxVisible'>Any type of files</div>
          </div>
        </Dragger>
      </div>
      <div className='fileListContainer'>
        {filesList.map((file, idx) => (
          <div key={`${file.uid}${idx}`} className='fileContainer'>
            <>
              {!file?.uploadedUrl && showProgress(progress, 24, !file?.imgData)}
              {file?.imgData ? (
                <img src={file?.imgData} alt=' ' />
              ) : (
                <div className='iconContainer'><img src={fileIcon} alt='' className='fileIcon' /></div>
              )}
            </>
            <div className='fileName'>{file?.name}</div>
            <Button className='deleteBtn' type='link' onClick={() => onClick(file)}>
              <img src={deleteIcon} alt=' ' />
            </Button>
          </div>
        ))}
      </div>
    </div>
  )
}

export default MultiMediaContainer
