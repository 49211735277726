import React from 'react'
import { notification } from 'antd'
import { CopyToClipboard } from 'react-copy-to-clipboard/lib/Component'
import { CustomButton, HiddenField } from 'components'
import c from 'classnames'
import './index.styl'

export const detailsByType = {
  TICKETED_ADMITION: {
    eventType: 'Venue',
    second: { field: 'venue', title: 'Venue:' },
    third: { field: 'rainDate', title: 'Rain Date:' },
    fourth: { field: 'ticketType', title: 'Category:' },
    fifth: { field: 'quantity', title: 'Ticket qty:' },
    sixth: { field: 'venuePhone', title: 'Phone:' },
    seventh: { field: 'seatSectionName', title: 'Section:' },
    eighth: { field: 'eventRow', title: 'Row:' },
    nineth: { field: 'eventSeat', title: 'Seat:' },
    ten: { field: 'eventTable', title: 'Table:' },
    eleven: { field: 'description', title: 'Notes' },
  },
  TICKETED_ADMITION_STREAM: {
    eventType: 'Venue and Stream',
    second: { field: 'venue', title: 'Venue:' },
    third: { field: 'quantity', title: 'Ticket qty:', xsOrder: true },
    fourth: { field: 'ticketType', title: 'Category:' },
    seventh: { field: 'seatSectionName', title: 'Section:' },
    eighth: { field: 'eventRow', title: 'Row:' },
    nineth: { field: 'eventSeat', title: 'Seat:' },
    ten: { field: 'eventTable', title: 'Table:' },
    eleven: { field: 'description', title: 'Notes' },
  },
  VIRTUAL: {
    eventType: 'Virtual Meeting',
    second: { field: 'ticketType', title: 'Category:' },
    third: { field: 'quantity', title: 'Ticket qty:' },
    sixth: { field: 'zoom_password', title: 'Access code:' },
    eleven: { field: 'description', title: 'Notes' },
  },
  STREAM: {
    eventType: 'Stream',
    second: { field: 'ticketType', title: 'Category:' },
    third: { field: 'quantity', title: 'Ticket qty:' },
    sixth: { field: 'zoom_password', title: 'Access code:' },
    eleven: { field: 'description', title: 'Notes' },
  },
  MEMBERSHIP: {
    eventType: 'Venue',
    second: { field: 'venue', title: 'Venue:' },
    third: { field: 'rainDate', title: 'Rain Date:' },
    fourth: { field: 'ticketType', title: 'Category:' },
    fifth: { field: 'quantity', title: 'Ticket qty:' },
    sixth: { field: 'venuePhone', title: 'Phone:' },
    seventh: { field: 'seatSectionName', title: 'Section:' },
    eighth: { field: 'eventRow', title: 'Row:' },
    nineth: { field: 'eventSeat', title: 'Seat:' },
    ten: { field: 'eventTable', title: 'Table:' },
    eleven: { field: 'description', title: 'Notes' },
  },
}

const EventDetails = ({ eventType, ...props }) => {
  const renderCol = item => {
    const field = detailsByType[eventType][item]?.field ? props[detailsByType[eventType][item]?.field] : ''
    return field ? (
      <>
        <div className={c('eventDetailsCol', { xsOrder: detailsByType[eventType]?.[item]?.xsOrder })}>
          <span className='descTitle'>{detailsByType[eventType]?.[item]?.title}</span>
          <span className='descBody'>{field}</span>
        </div>
      </>
    ) : null
  }
  const renderAccessCode = item => {
    const field = detailsByType[eventType][item]?.field ? props[detailsByType[eventType][item]?.field] : ''
    const onSuccess = () => notification.success({ message: 'Password copied to clipboard' })
    return field ? (
      <>
        <div className='eventDetailsCol long'>
          <span className='descTitle'>{detailsByType[eventType]?.[item].title}</span>
          <span className='descBody'>
            <HiddenField label='****' value={field} />
          </span>
        </div>
        <div className='eventDetailsCol centerXS'>
          <CopyToClipboard text={field} onCopy={onSuccess}>
            <CustomButton text='Copy Access Code' type='dark' />
          </CopyToClipboard>
        </div>
      </>
    ) : null
  }

  return (
    <div className='EventDetails'>
      <div className='eventDetailsRow'>
        <div className='eventDetailsCol'>
          <span className='descTitle'>Event Type:</span>
          <span className='descBody'>{detailsByType[eventType]?.eventType}</span>
        </div>
        {renderCol('second')}
      </div>
      <div className='eventDetailsRow xsOrder'>
        {renderCol('fourth')}
        {renderCol('fifth')}
        {props?.ticket?.venuePhone &&
          <div className={c('eventDetailsCol')}>
            <span className='descTitle'>Phone:</span>
            <span className='descBody'>{props?.ticket?.venuePhone}</span>
          </div>}
      </div>
      <div className='eventDetailsRow'>
        {renderCol('third')}
        {renderCol('seventh')}
        {renderCol('eighth')}
        {renderCol('ten')}
        {renderCol('nineth')}
      </div>
      <div className='eventDetailsRow'>
        {renderAccessCode('sixth')}

      </div>
    </div>
  )
}

export default EventDetails
