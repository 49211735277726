import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Modal, Form, Input } from 'antd'

import PlacesAutocomplete from 'react-places-autocomplete'
import c from 'classnames'
import Location from 'images/LocationWhite.svg'
import { CustomButton } from 'components'
import './index.styl'

const { Item } = Form

const ModalProfile = ({ visible = false, onChange, onFinish, name, loading }) => {
  const { user, bankDetails } = useSelector(state => state.session)

  const attr = { visible, onCancel: onChange, width: 400, maxWidth: 400, footer: null, className: 'wrapperModalProfile', destroyOnClose: true }
  const [form] = Form.useForm()
  const [address, setAddress] = useState('')
  const getRules = (field, _min = 3) => {
    const min = _min || 3
    return [
      { required: true, message: `Please enter your ${field}` },
      { min, message: `${field} must be at least ${min} characters.`, transform: value => value?.trim() },
    ]
  }
  const getRulesBank = (field, _min = 12) => {
    const min = field === 'account #' ? 0 : 9
    const max = field === 'account #' ? 22 : 9
    return [
      { required: true, message: `Please enter your ${field}` },
      { min, message: `${field} must be at least ${min} symbols.`, transform: value => value?.trim() },
      { max, message: `${field} must be no more than ${max} symbols.`, transform: value => value?.trim() },
    ]
  }

  const onPressSpace = e => {
    if (e.key === ' ') {
      e.preventDefault()
    }
  }
  const handleSelect = async value => {
    await setAddress(value)
    await form.setFieldsValue({
      address: value,
    })
  }
  const locationIcon = <img src={Location} alt='Location Logo' />
  const className = 'input location-search-input'
  const obj = {
    password: () => (
      <>
        <Item name='currentPassword' label='Current password' className='item' rules={[{ required: true, message: 'Please enter your current password' }]}>
          <Input autoComplete='false' type='password' className='darkBg' />
        </Item>
        <Item
          name='newPassword' label='New password' className='item' rules={[
            { required: true, message: 'Please enter your new password' },
            { min: 6, message: 'Password must be at least 6 characters.', transform: value => value?.trim() },
          ]}
        >
          <Input onKeyDown={onPressSpace} autoComplete='false' type='password' className='darkBg' />
        </Item>
        <Item
          name='confirmPassword' dependencies={['newPassword']} label='Confirm new password' className='item' rules={[{ required: true, message: 'Please confirm your new password' }, ({ getFieldValue }) => ({
            validator (rule, value) {
              if (!value || getFieldValue('newPassword') === value) {
                return Promise.resolve()
              }
              return Promise.reject(new Error('The two passwords that you entered do not match!'))
            },
          })]}
        >
          <Input onKeyDown={onPressSpace} autoComplete='false' type='password' className='darkBg' />
        </Item>
      </>
    ),
    information: () => (
      <>
        <Item normalize={v => v.trimStart()} name='firstName' label='First name' className='item' validateTrigger='onBlur'>
          <Input className='darkBg' maxLength={32} />
        </Item>
        <Item normalize={v => v.trimStart()} name='lastName' label='Last name' className='item' validateTrigger='onBlur'>
          <Input className='darkBg' maxLength={32} />
        </Item>
        <Item normalize={v => v.trimStart()} name='userName' label='User name' className='item' rules={getRules('user name')} validateTrigger='onBlur'>
          <Input className='darkBg' />
        </Item>
        <Item normalize={v => v.trimStart()} name='email' label='Email' className='item' validateTrigger='onBlur'>
          <Input className='darkBg' maxLength={32} />
        </Item>
        <Item normalize={v => v.trimStart()} name='dateOfBirth' label='Date of birth' className='item' validateTrigger='onBlur'>
          <Input className='darkBg' maxLength={32} />
        </Item>
        <Item normalize={v => v.trimStart()} name='mobile' label='Mobile number' className='item' rules={getRules('mobile number')} validateTrigger='onBlur'>
          <Input prefix='+1' className='darkBg' type='number' />
        </Item>
        <Item normalize={v => v.trimStart()} name='address' label='Address' className='item' validateTrigger='onBlur'>
          <Input className='darkBg' maxLength={32} />
        </Item>
        <Item normalize={v => v.trimStart()} name='school' label='School' className='item' validateTrigger='onBlur'>
          <Input className='darkBg' maxLength={32} />
        </Item>
        <Item normalize={v => v.trimStart()} name='parentName' label='parentName' className='item' validateTrigger='onBlur'>
          <Input className='darkBg' maxLength={32} />
        </Item>
        <Item normalize={v => v.trimStart()} name='parentPhone' label='Parent phone' className='item' validateTrigger='onBlur'>
          <Input className='darkBg' maxLength={32} />
        </Item>
        <Item normalize={v => v.trimStart()} name='Parent email' label='parentEmail' className='item' validateTrigger='onBlur'>
          <Input className='darkBg' maxLength={32} />
        </Item>
      </>
    ),
    bankDetails: () => (
      <>
        <Item normalize={v => v.trimStart()} name='routing' label='Routing Transit #' className='item' rules={getRulesBank('routing transit #')} validateTrigger='onBlur'>
          <Input className='darkBg' type='number' />
        </Item>
        <Item normalize={v => v.trimStart()} name='account' label='Account #' className='item' rules={getRulesBank('account #')} validateTrigger='onBlur'>
          <Input
            onKeyDown={onPressSpace}
            className='darkBg'
          />
        </Item>
      </>
    ),
    informationVenue: () => (
      <>
        <Item normalize={v => v.trimStart()} name='venueName' label='Venue name' className='item' rules={getRules('venue name')} validateTrigger='onBlur'>
          <Input disabled className='darkBg' maxLength={32} />
        </Item>
        <Item normalize={v => v.trimStart()} name='businessMobile' label='Business number' className='item' rules={getRules('business number')} validateTrigger='onBlur'>
          <Input prefix='+1' className='darkBg' type='number' />
        </Item>
        <Item normalize={v => v.trimStart()} name='mobile' label='Mobile number' className='item' rules={getRules('mobile number')} validateTrigger='onBlur'>
          <Input prefix='+1' className='darkBg' type='number' />
        </Item>
        <PlacesAutocomplete value={address} onChange={setAddress} onSelect={handleSelect}>
          {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
            <div className='locationContainer'>
              <Item
                rules={getRules('address')}
                className='item'
                name='address'
                label='Address'
                normalize={v => v.trimStart()}
              >
                <Input {...getInputProps({ className, suffix: locationIcon, disabled: true })} />
              </Item>
              <div className={c('autocomplete-dropdown-container', { isActive: !!suggestions.length })}>
                {loading && <div>Loading...</div>}
                {suggestions.map((suggestion, index) => {
                  const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item'
                  const style = { backgroundColor: suggestion.active ? '#fafafa' : '#ffffff', cursor: 'pointer' }
                  return (
                    <div {...getSuggestionItemProps(suggestion, { className, style })} key={index}>
                      {suggestion.description}
                    </div>
                  )
                })}
              </div>
            </div>
          )}
        </PlacesAutocomplete>
        <Item normalize={v => v.trimStart()} name='userName' label='User name' className='item' rules={getRules('user name')} validateTrigger='onBlur'>
          <Input className='darkBg' />
        </Item>
      </>
    ),
  }

  const initialValues = name === 'password' ? {} : {
    email: user.email,
    mobile: user.mobile,
    address: user.address,
    businessMobile: user.businessMobile,
    venueName: user.venueName,
    firstName: user.firstName,
    lastName: user.lastName,
    userName: user.userName,
    routing: bankDetails?.routingNumber || '',
    account: bankDetails?.accountNumber || '',
  }
  const textSubmit = name === 'password' ? 'Change' : 'Save'
  return (
    <Modal title={name === 'password' ? 'Change password' : name === 'bankDetails' ? 'Bank Details' : 'Personal information'} {...attr}>
      <Form form={form} layout='vertical' className='form' onFinish={onFinish} initialValues={initialValues}>
        {obj[name]()}
        <div className='wrapperActions'>
          <CustomButton text='Cancel' type='white' onClick={onChange} htmlType='button' />
          <CustomButton text={textSubmit} type='dark' htmlType='submit' className='btn' loading={loading} />
        </div>
      </Form>
    </Modal>
  )
}

export default ModalProfile
