import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { CustomButton } from 'components'
import { Modal } from 'antd'
import { inviteTicket, setInviteTicketGuest } from 'modules/events/events-actions'
import './index.styl'

const { confirm } = Modal

const InviteActionButtons = ({ inviteData = {}, onSelectInvite, callback = () => {} }) => {
  const { inviteID, inviteType, quantity, guest } = inviteData
  const [loading, setLoading] = useState('')
  const dispatch = useDispatch()
  const reject = () => {
    setLoading('reject')

    confirm({
      title: 'Do you want to reject this invite?',
      onOk: () => {
        const obj = { inviteID, status: 'REJECTED' }
        if (guest) {
          dispatch(setInviteTicketGuest(obj)).then(() => setLoading(''))
        } else {
          dispatch(inviteTicket(obj)).then(() => setLoading(''))
        }
        callback()
      },
      onCancel: () => {
        setLoading('')
      },
    })
  }

  const accept = () => {
    if ((inviteType === 'RESELL' || inviteType === 'SELL')) {
      callback()
      onSelectInvite(inviteData)
    } else {
      setLoading('accept')
      const callbackFn = () => {
        callback()
        setLoading('')
      }
      const obj = { inviteID, status: 'ACCEPTED', quantity }
      if (guest) {
        dispatch(setInviteTicketGuest(obj)).then(callbackFn)
      } else {
        dispatch(inviteTicket(obj)).then(callbackFn)
      }
    }
  }

  return (
    <div className='wrapperActionsModalTicket'>
      <CustomButton className='rejectModalTicket' text='Reject' type='white' onClick={reject} loading={loading === 'reject'} />
      <CustomButton className='acceptModalTicket' text='Accept' type='dark' onClick={accept} loading={loading === 'accept'} />
    </div>
  )
}

export default InviteActionButtons
