import React, { useState } from 'react'
import { Modal, Form, Button, InputNumber, Divider } from 'antd'
import { CustomButton } from 'components'
import { PlusOutlined, MinusOutlined } from '@ant-design/icons'
import closeIconSvg from 'images/Crossmark.svg'
import './index.styl'

const { Item } = Form

const PrivateLiveStreamModal = ({
  onCloseModal,
  ticketData,
  setByTiket,
  visible,
}) => {
  const [quantity, setQuantity] = useState(0)
  const onCancel = () => {
    onCloseModal(false)
    setTimeout(onCloseModal, 100)
  }
  const attr = { onCancel, width: 800, maxWidth: 800, footer: null, maskStyle: { backgroundColor: '#E8EEF7E6' } }
  const closeIcon = <img src={closeIconSvg} alt='close icon' className='closeIcon' />

  const validQuality = v => ({
    validator () {
      if (!!v) {
        return Promise.resolve()
      }
      return Promise.reject(new Error('Enter quantity!'))
    },
  })

  const submit = async () => {
    if (quantity === 0) {
      return
    }
    setByTiket(quantity)
    onCancel()
  }

  return (
    <Modal {...attr} className='privatLiveStream' title='Buy Live Stream Tickets ' closeIcon={closeIcon} visible={visible}>
      <Form onFinish={submit}>
        <h3 className='secondaryText'>
          Would you like to buy Live Stream Tickets to gift out?
        </h3>
        <div className='castomQuantityContainer'>
          <div className='price'>${quantity * 2}
            <div className='bundle'>Bundle of {quantity} tickets</div>
          </div>
          <Item
            className='castomQuantity'
            rules={[validQuality]}
            name='quantity'
          >
            <Button onClick={() => setQuantity(quantity === 0 ? 0 : quantity - 25)} className='minus' type='button'><MinusOutlined /></Button>
            <InputNumber defaultValue={quantity} value={quantity} placeholder='0' className='input fullWidth' min={0} />
            <Button onClick={() => setQuantity(quantity + 25)} className='plus' type='button'><PlusOutlined /></Button>
          </Item>
        </div>
        <Divider />
        <CustomButton
          htmlType='submit'
          text={`Buy tickets for ${quantity * 2}$`}
          className='btnMakeTicket'
          type='dark'
        />
      </Form>
    </Modal>
  )
}

export default PrivateLiveStreamModal
