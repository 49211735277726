/* eslint-disable indent */
import React, { useEffect } from 'react'
import './index.styl'
import { useSelector, useDispatch } from 'react-redux'
import { clearGetEventDetails } from 'modules/events/events-actions'
import { Link, useLocation } from 'react-router-dom'
import { isVenue } from 'utils'
import { getLogoVenue } from '../../modules/session/session-actions'
import defaultLogo from 'images/brand-header.svg'
import logoVenue from 'images/logoVenue.svg'

const Logo = () => {
  const { pathname } = useLocation()
  const dispatch = useDispatch()
  const { currentEvent, currentEventDetails } = useSelector(state => state.events)
  const { user, logo } = useSelector(state => state.session)
  useEffect(() => {
    if (!pathname.includes('/venue') && user?.venueUrl) {
      dispatch(getLogoVenue(user?.venueUrl))
    }
    if (pathname.includes('/venue') && !user?.venueUrl || pathname.includes('/venue') && isVenue(user?.role)) {
      if (user?.venueUrl || pathname.includes('venue/')) {
        const venueUrlFromUser = pathname.slice(7)
        dispatch(getLogoVenue(venueUrlFromUser))
      }
    }
  }, [pathname, user?.venueUrl])
  const venueDraftLogo = currentEventDetails?.isVenueEvent && currentEventDetails?.venueDetails
  const logoCheck = pathname.includes('venue') && !pathname.includes('for-venue') || user?.isVenueUser || currentEvent?.venueDetails?.venueLogo?.original || venueDraftLogo
  return (
    <Link
      to={(pathname.includes('venue')) && !(pathname.includes('for-venues')) ? pathname : currentEvent?.venueDetails ? `/venue/${currentEvent?.venueDetails.venueUrl}` : user?.isVenueUser ? `/venue/${user?.venueUrl}` : '/home'} onClick={() => {
        dispatch(clearGetEventDetails({}))
        window.scroll(0, 0)
      }}
    >

      {
        logoCheck
          ? (
            <img
              src={currentEvent?.venueDetails?.venueLogo?.original
              ? currentEvent?.venueDetails?.venueLogo?.original : venueDraftLogo
              ? currentEventDetails?.venueDetails?.venueLogo?.original || logoVenue : (logo?.original || logoVenue)} alt='logo' className='logo'
            />
          ) : (
            <img src={defaultLogo} alt='logo' className='logo' />
          )
      }
    </Link>
  )
}

export default Logo
