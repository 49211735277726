import React, { useState } from 'react'
import { Modal, Form, Input, DatePicker, notification } from 'antd'
import moment from 'moment'
import { memberships } from 'apiv2'
import { useHistory } from 'react-router-dom'
import calendarIcon from 'images/calendarIcon.svg'
import { mobileValidator } from 'modules/utils/validators'
import { CustomButton } from 'components'
import { useDispatch } from 'react-redux'
import './index.styl'

const { Item } = Form

const ModalAdditionalInformation = ({ visible, setVisible, signupFields = [], rosterDoB, parentApproval, eventID }) => {
  const [isAdult, setIsAdult] = useState(false)
  const [loading, setLoading] = useState(false)
  const [birthDate, setBirthDate] = useState(null) // State for birthDate
  const [form] = Form.useForm()
  const history = useHistory()
  const dispatch = useDispatch()
  const dateIcon = <img src={calendarIcon} alt=' ' />

  const disabledDate = current => {
    return current && (current > moment().endOf('day') || current < moment().subtract(100, 'years').startOf('day'))
  }

  const handleDateChange = date => {
    setBirthDate(date) // Set birthDate state
    if (date) {
      const age = moment().diff(date, 'years')
      setIsAdult(age >= 18)
    } else {
      setIsAdult(false)
    }
  }

  const submitForm = value => {
    setLoading(true)
    const fieldData = signupFields?.map(field => ({
      id: field.id,
      value: value[field.name] !== undefined ? value[field.name] : undefined,
    })).filter(field => field.value !== undefined)
    const dateTransform = date => {
      return `${moment(date).format('YYYY-MM')}`
    }
    const obj = {
      eventID,
      signupData: fieldData || [],
      dob: dateTransform(value.birthDate),
    }
    if (value.name || value.email || value.phone) {
      obj.parentData = {
        name: value.name,
        email: value.email,
        phone: value.phone,
      }
    }

    dispatch(memberships.membershipRequest(obj).then(() => {
      setVisible(false)
      history.push('/my-member-wallet')
    },
    ).catch(err => notification.warning({ message: err?.response?.data?.error || err?.response?.data?.message })).finally(() => setLoading(false)))
  }

  // const submitForm = values => {
  //  console.log('submitForm', values)
  // }

  // const validateBirthDate = (_, value) => {
  //   if (!value) {
  //     return Promise.reject(new Error('Please enter date of birth!'))
  //   }
  //   const [day, month, year] = value.split('.')
  //   const birthDate = new Date(`${year}-${month}-${day}`)
  //   const today = new Date()
  //   const hundredYearsAgo = new Date()
  //   hundredYearsAgo.setFullYear(today.getFullYear() - 100)
  //   if (birthDate > today) {
  //     return Promise.reject(new Error('Date of birth cannot be later than today.'))
  //   }
  //   if (birthDate < hundredYearsAgo) {
  //     return Promise.reject(new Error('Date of birth cannot be earlier than 100 years ago.'))
  //   }
  //   if (day < 1 || day > 31 || month < 1 || month > 12 || year.length !== 4) {
  //     return Promise.reject(new Error('Please enter a valid date.'))
  //   }
  //   return Promise.resolve()
  // }

  return (
    <Modal
      className='modal-additional-information'
      title='Please Provide Additional Details'
      visible={visible}
      width={472}
      onCancel={() => setVisible(false)}
      footer={null}
      centered
      closable={!loading}
      destroyOnClose
    >
      <Form onFinish={submitForm} layout='vertical' form={form} id='additional-info'>
        {rosterDoB &&
          <Item
            rules={[{ required: true, message: 'Enter Start Date' }]}
            label='Date of Birth'
            name='birthDate'
            className='defaultInputLabel item'
          >
            <DatePicker
              picker='month'
              initialValue={birthDate}
              className='darkBg dateOfBirthPicker'
              getPopupContainer={node => node?.parentNode}
              format='YYYY-MM'
              suffixIcon={dateIcon}
              disabledDate={disabledDate}
              onChange={handleDateChange}
            />
          </Item>}
        {birthDate && parentApproval && !isAdult && (
          <>
            <Item
              rules={[
                { required: true, message: 'Please enter email!' },
                { type: 'email', message: 'Please enter a correct email!', transform: value => value?.trim() },
              ]}
              label='Parent Email'
              className='defaultInputLabel email'
              name='email'
              normalize={v => v.trimStart()}
            >
              <Input className='darkBg' />
            </Item>
            <Item
              rules={[{ required: true, message: 'Please enter parent name!' }]}
              normalize={v => v.trimStart()}
              name='name'
              label='Parent Name'
              className='item'
            >
              <Input className='darkBg' maxLength={32} />
            </Item>
            <Item
              name='phone'
              label='Parent Phone'
              className='defaultInputLabel haveMargin mobile'
              rules={[{ required: true, message: 'Please enter phone number' }, mobileValidator]}
            >
              <Input className='darkBg' type='number' />
            </Item>
          </>
        )}
        {signupFields?.map(field => (
          <div key={field.id}>
            {field?.minorsOnly && !isAdult && birthDate && (
              <Item
                rules={[{ required: field.hasRequired, message: `Please enter ${field?.name}!` }]}
                normalize={v => v.trimStart()}
                name={field?.name}
                label={field?.name}
                className='item'
              >
                <Input className='darkBg' maxLength={32} />
              </Item>
            )}
            {!field?.minorsOnly &&
              <Item
                rules={[{ required: field.hasRequired, message: `Please enter ${field?.name}!` }]}
                normalize={v => v.trimStart()}
                name={field?.name}
                label={field?.name}
                className='item'
              >
                <Input className='darkBg' maxLength={32} />
              </Item>}
          </div>
        ))}
        <div className='additionalInfoBtnWrapper'>
          <CustomButton onClick={() => setVisible(false)} className='cancelInfoBtn' text='Cancel' type='white' />
          <CustomButton className='submitInfoBtn' text='Save' htmlType='submit' type='dark' loading={loading} />
        </div>
      </Form>
    </Modal>
  )
}

export default ModalAdditionalInformation
