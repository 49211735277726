import React, { useEffect, useState } from 'react'
import { Form, Input, InputNumber, Radio, Switch } from 'antd'
import { quanityValidator } from 'modules/utils/validators'

import './index.styl'

const { Item } = Form
const { TextArea } = Input

const validatePrice = (_, value) => {
  if (value === 0) {
    return Promise.reject(new Error('Price cannot be 0!'))
  }
  const regex = /^\d{1,8}(\.\d{0,2})?$/
  if (!regex.test(value)) {
    return Promise.reject(new Error('Price must not exceed 8 digits and 2 decimal places!'))
  }
  return Promise.resolve()
}

const SessionForm = ({ name, onRemove, categoryData, form }) => {
  const [selectedSessionSize, setSelectedSessionSize] = useState(categoryData?.sessionSize || '')

  const [availableSession, setAvailableSession] = useState(form.getFieldValue('showAvailableTickets') || false)

  const availableSessionHandler = () => {
    setAvailableSession(!availableSession)
  }

  const handleSessionSizeChange = e => {
    setSelectedSessionSize(e.target.value)
  }

  useEffect(() => {
    setSelectedSessionSize(categoryData?.sessionSize)
  }, [categoryData])
  return (
    <div className='sessionWrapper'>
      <div className='sessionTopField'>
        <Item
          name={[name, 'name']} label='Session Category' rules={[
            { required: true, message: 'Session Category is required!' },
            { max: 100, message: 'Session Category cannot exceed 100 characters!' },
          ]}
          normalize={v => v.trimStart()}
        >
          <Input className='categorySession' placeholder='Session' />
        </Item>
        <Item
          name={[name, 'quantity']} label='Session Quantity' rules={[
            { required: true, message: 'Sessions Quantity is required!' },
            { type: 'number', max: 100000, message: 'Quantity cannot exceed 100 000' },
            quanityValidator,
          ]}
        >
          <InputNumber className='quantity' placeholder='0' min={1} />
        </Item>
      </div>
      <div className='radioItemField showAvailableSession'>
        <Item
          name={[name, 'showAvailableTickets']}
          valuePropName='checked'
          className='item'
          initialValue={availableSession}
        >
          <Switch onChange={availableSessionHandler} defaultChecked={availableSession} />
        </Item>
        <div className='text'>Show available sessions</div>
      </div>
      <Item name={[name, 'sessionSize']} label='Session size' rules={[{ required: true, message: 'Session Price is required!' }]}>
        <Radio.Group className='radioSessionSizeWrapper' onChange={handleSessionSizeChange} value={selectedSessionSize}>
          <div className={selectedSessionSize === 1 ? 'radioContentWrapper active' : 'radioContentWrapper'}>
            <div className='radioContent'>
              <Radio className='radioBtn' value={1}>
                <div className='contentTitle'>1 Session</div>
              </Radio>
              <div className='contentSubtitle'>
                <Item
                  rules={[selectedSessionSize === 1 && { required: true, message: 'Session Price is required!' },
                    selectedSessionSize === 1 && { validator: validatePrice },
                  ]} name={selectedSessionSize !== 1 ? 'price5' : [name, 'price']} label='Session Price'
                >
                  <InputNumber disabled={selectedSessionSize !== 1} placeholder='0' className='price' min={0} />
                </Item>
              </div>
            </div>
          </div>
          <div className={selectedSessionSize === 5 ? 'radioContentWrapper active' : 'radioContentWrapper'}>
            <div className='radioContent'>
              <Radio className='radioBtn' value={5}>
                <div className='contentTitle'>5 Sessions</div>
              </Radio>
              <div className='contentSubtitle'>
                <Item
                  rules={[selectedSessionSize === 5 && { required: true, message: 'Session Price is required!' },
                    selectedSessionSize === 5 && { validator: validatePrice },
                  ]} name={selectedSessionSize !== 5 ? 'price10' : [name, 'price']} label='Package Price'
                >
                  <InputNumber disabled={selectedSessionSize !== 5} placeholder='0' className='price' min={0} />
                </Item>
              </div>
            </div>
          </div>
          <div className={selectedSessionSize === 10 ? 'radioContentWrapper active' : 'radioContentWrapper'}>
            <div className='radioContent'>
              <Radio className='radioBtn' value={10}>
                <div className='contentTitle'>10 Sessions</div>
              </Radio>
              <div className='contentSubtitle'>
                <Item
                  rules={[selectedSessionSize === 10 && { required: true, message: 'Session Price is required!' },
                    selectedSessionSize === 10 && { validator: validatePrice },
                  ]} name={selectedSessionSize !== 10 ? 'price15' : [name, 'price']} label='Package Price'
                >
                  <InputNumber disabled={selectedSessionSize !== 10} placeholder='0' className='price' min={0} />
                </Item>
              </div>
            </div>
          </div>
        </Radio.Group>
      </Item>
      <Item
        rules={[
          { required: false },
          { max: 500, message: 'Additional Info cannot exceed 500 characters!' },
        ]}
        name={[name, 'description']} label='Additional Info'
        normalize={v => v.trimStart()}
      >
        <TextArea autoSize={{ maxRows: 50 }} className='additionalInfo' />
      </Item>
      <div className='btnWrapper'>
        <div className='removeBtn' onClick={() => onRemove(name)}>
          Delete Session
        </div>
      </div>
    </div>
  )
}

export default SessionForm
