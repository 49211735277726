import React from 'react'
import { useHistory } from 'react-router'

import { Image } from 'antd'
import locationIcon from 'images/memberLocationIcon.svg'
import mobileIcon from 'images/memberMobileIcon.svg'
import emailIcon from 'images/memberEmail.svg'
import cardImg from 'images/card.png'
import { CustomButton } from '../../components'

import './index.styl'

const DetailPageHeader = ({
  currentPageDetails: {
    images,
    locations,
    name,
    businessPhone,
    promoterEmail,
    mobile,
  },
  id,
}) => {
  const Item = ({ icon, title = '', text, textClass = '' }) => (
    <li className='item'>
      <div className='leftSide'>
        <img src={icon} alt=' ' className={title.toLowerCase()} />
        <span>{title}</span>
      </div>
      <div className={['text', textClass].join(' ')}>{text}</div>
    </li>
  )
  const history = useHistory()

  const handleEmailTo = () => {
    history.push(`/send-mail?eventID=${id}`)
  }
  return (
    <div className='wrapperDetailPageHeader'>
      <div className='imgWrapper'>
        <Image src={images?.original} onError={e => (e.target.src = cardImg)} preview={{ src: images?.sourceImage }} className='imagePage' />
      </div>
      <div className='wrapperDetails'>
        <div className='detailsContainer'>
          <div className='containerTitle'>
            <div className='detailTicketTitle'>{name || 'Loading...'}</div>
          </div>
          <ul className='wrapperItems'>
            <div>
              {locations?.map((location, index) => (
                <Item
                  key={index}
                  text={<a href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(location)}`} target='_blank' rel='noopener noreferrer'>{location}</a>}
                  title='Location'
                  icon={locationIcon}
                />
              ))}
              <Item
                text={promoterEmail}
                title='Manager Email'
                icon={emailIcon}
              />
            </div>
            <div>
              <Item
                text={mobile}
                title='Mobile Number'
                icon={mobileIcon}
              />
              {businessPhone && businessPhone !== 'undefined' &&
                <Item
                  text={businessPhone}
                  title='Business Number'
                  icon={mobileIcon}
                />}

            </div>
          </ul>
          <CustomButton
            text='Email To Manager'
            className='emailTo'
            type='dark'
            onClick={handleEmailTo}
          />
        </div>
      </div>
    </div>
  )
}

export default DetailPageHeader
