import React, { useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { isMobileWidth } from 'modules/session/session-actions'
import Authorized from './Authorized'
import Unauthorized from './Unauthorized'
import './index.styl'
import c from 'classnames'

const HeaderMemberLyfe = () => {
  const { user, isMobile: headerMobile, isMobile } = useSelector(state => state.session)
  const dispatch = useDispatch()
  const headerRef = useRef(null)

  useEffect(() => {
    const handleResize = () => {
      if (!isMobile) {
        dispatch(isMobileWidth(window.innerWidth < 768))
      }
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    if (!headerMobile && window.innerWidth < 768) {
      dispatch(isMobileWidth(window.innerWidth < 768))
    }
    if (headerMobile && window.innerWidth > 767) {
      dispatch(isMobileWidth(window.innerWidth < 768))
    }
  }, [headerRef.current])

  return (
    <header ref={headerRef} className={c('header', { headerMobile })}>
      <div className='container'>
        {user ? <Authorized /> : <Unauthorized />}
      </div>
    </header>
  )
}

export default HeaderMemberLyfe
