import adminapi from './axios.v2.withoutTransform'

const makeMemberPage = obj => {
  return adminapi('memberships/create', 'POST', obj)
}

const membershipRequest = obj => {
  return adminapi('memberships/membershipRequest', 'POST', obj)
}
const resendParentRequest = obj => {
  return adminapi('memberships/resendParentRequest', 'POST', obj)
}
const selfScanWallet = obj => {
  return adminapi('user/selfScan', 'POST', obj)
}

const getMemberPageDetails = id => {
  return adminapi(`memberships/${id}`, 'GET')
}

const getMembershipQRCode = id => {
  return adminapi(`memberships/${id}/qrcode`, 'GET')
}

const checkMembershipMember = eventID => {
  return adminapi(`memberships/checkMembershipMember?eventID=${eventID}`, 'GET')
}
const getParentApproval = eventID => {
  return adminapi(`memberships/getParentApproval?eventID=${eventID}`, 'GET')
}

const editMemberPage = (obj, id) => {
  return adminapi(`memberships/${id}`, 'PUT', obj)
}

const getMembershipDistributedTickets = (eventID, ticketType, limit = 10, skip = 0, search) =>
  adminapi(`boxOffice/getMembershipDistributedTickets?eventID=${eventID}&ticketType=${ticketType}&limit=${limit}&skip=${skip}` + `${search ? `&search=${search}` : ''}`, 'GET')

const getDonationList = (eventID, limit = 10, skip = 0, search) =>
  adminapi(`boxOffice/getDonationList?eventID=${eventID}&limit=${limit}&skip=${skip}` + `${search ? `&search=${search}` : ''}`, 'GET')

const getMembershipRosterFields = eventID => adminapi(`boxOffice/membershipRosterFields?eventID=${eventID}`, 'GET')

const getMembershipRoster = (eventID, limit = 10, skip = 0, search, type) => adminapi(`boxOffice/getMembershipRoster?eventID=${eventID}&limit=${limit}&skip=${skip}&type=${type}` + `${search ? `&search=${search}` : ''}`, 'GET')

const inviteMembership = (eventID, email) => adminapi(`boxOffice/${eventID}/manageMembershipMember`, 'POST', { email })

const approveMembership = (eventID, membershipID) => adminapi(`boxOffice/${eventID}/manageMembershipMember`, 'PUT', { membershipID, operation: 'approve' })

const disableMembership = (eventID, membershipID) => adminapi(`boxOffice/${eventID}/manageMembershipMember`, 'PUT', { membershipID, operation: 'disable' })

const unblockMembership = (eventID, membershipID) => adminapi(`boxOffice/${eventID}/manageMembershipMember`, 'PUT', { membershipID, operation: 'unblock' })

const deleteMembership = (eventID, membershipID) => adminapi(`boxOffice/${eventID}/manageMembershipMember`, 'DELETE', { membershipID })

const getMembershipSummaries = eventID => adminapi(`boxOffice/getMembershipSummaries?eventID=${eventID}`, 'GET')

const getScanCsv = eventID => {
  return adminapi(`boxOffice/memberships/${eventID}/exportScannedTickets`, 'GET')
}

const getMemberPublicEvent = obj => {
  let apiUrl = 'memberships/list'
  if (Object.keys(obj).length) {
    apiUrl += '?v=1'
  }
  if (obj.limit) {
    apiUrl += `&limit=${obj.limit}`
  }
  if (obj.skip) {
    apiUrl += `&skip=${obj.skip}`
  }
  if (obj.eventName) {
    const search = encodeURIComponent(obj.eventName)
    apiUrl += `&search=${search}`
  }
  return adminapi(apiUrl, 'GET', {})
}

const getActiveParticipations = (limit = 20, skip = 0) =>
  adminapi(`memberships/activeParticipations?limit=${limit}&skip=${skip}`, 'GET')

export default {
  makeMemberPage,
  getMemberPageDetails,
  editMemberPage,
  getMembershipDistributedTickets,
  getDonationList,
  getMembershipRosterFields,
  getMembershipRoster,
  getMembershipSummaries,
  membershipRequest,
  getParentApproval,
  inviteMembership,
  approveMembership,
  disableMembership,
  unblockMembership,
  deleteMembership,
  resendParentRequest,
  getScanCsv,
  checkMembershipMember,
  getMembershipQRCode,
  selfScanWallet,
  getMemberPublicEvent,
  getActiveParticipations,
}
