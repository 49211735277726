import React, { useEffect, useState, useContext } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Skeleton, Tooltip, Pagination } from 'antd'
import { getEvents, clearGetEventDetails } from 'modules/events/events-actions'
import Row from './Row'
import { MyBoxOfficeContext } from '../index'
import NoEventsInformation from 'images/NoEventsInformation.svg'
import { useHistory } from 'react-router'
import { useQuery, getMyBoxOfficeURL, handleConfirmModal, notify } from 'utils'
import { eventsV2 } from 'apiv2'
import './index.styl'

const paginationDefault = {
  current: 1,
  total: 0,
  limit: 5,
}

const TableEvents = () => {
  const { filterTag, sortTag, searchTag, loading, setLoading } = useContext(MyBoxOfficeContext)
  const query = useQuery()
  const [pagination, setPagination] = useState(paginationDefault)
  const { events, promotions } = useSelector(state => state.events)
  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    updateEvents(filterTag.key, +query.get('page') || 1)
  }, [filterTag, sortTag, searchTag])

  const catchError = error => {
    notify(error?.response?.data?.error || error?.response?.data?.message || error?.message)
    setLoading(false)
  }

  const updateEvents = (key = filterTag.key, current = 1) => {
    const params = {
      limit: paginationDefault.limit,
      skip: (current * pagination.limit) - pagination.limit,
      search: searchTag,
      eventType: key,
      order: sortTag,
    }
    setLoading(true)
    dispatch(clearGetEventDetails())
    dispatch(getEvents(params))
      .then(total => setPagination({ ...pagination, total, current }))
      .catch(err => err)
      .finally(() => setLoading(false))
  }

  const onDeleteEvent = id => {
    const onOk = () => {
      setLoading(true)
      eventsV2.deleteEvent(id)
        .then(() => onChange(pagination.current, pagination.limit))
        .catch(catchError)
    }
    handleConfirmModal('Are you sure you want to delete this event?', onOk)
  }

  const onCancelEvent = eventID => {
    const onOk = () => {
      setLoading(true)
      eventsV2.cancelEvent(eventID)
        .then(() => onChange(pagination.current, pagination.limit))
        .catch(catchError)
    }
    handleConfirmModal('Are you sure you want to cancel this event?', onOk)
  }
  const onActivateEvent = eventID => {
    const onOk = () => {
      setLoading(true)
      eventsV2.activateEvent(eventID)
        .then(() => onChange(pagination.current, pagination.limit))
        .catch(catchError)
    }
    handleConfirmModal('Are you sure you want to activate this event?', onOk)
  }

  const items = filterTag.key === 'SUBPROMOTIONS' ? [...promotions, ...events.filter(i => i?.isPromoted)] : events
  const onChange = (current, pageSize) => {
    const skip = (current * pageSize) - pagination.limit
    const params = {
      eventType: filterTag.key,
      limit: pagination.limit,
      search: searchTag,
      order: sortTag,
      skip,
    }
    history.push(getMyBoxOfficeURL(query.get('activeTab'), sortTag, current))
    setLoading(true)
    dispatch(clearGetEventDetails())
    dispatch(getEvents(params))
      .then(total => setPagination({ ...pagination, total, current }))
      .catch(err => err)
      .finally(() => setLoading(false))
  }

  return (
    <div className='wrapperTableEvents'>
      <Skeleton loading={loading} active paragraph={{ rows: 15 }}>
        {items.length
          ? items.map((item, index) => (
            <Row
              onActivateEvent={onActivateEvent}
              onDeleteEvent={onDeleteEvent}
              onCancelEvent={onCancelEvent}
              updateEvents={updateEvents}
              key={index}
              {...item}
            />
          ))
          : (
            <div className='wrapperBodyEmpty'>
              <Tooltip title='No Events available'>
                <img src={NoEventsInformation} alt='Information Logo' />
              </Tooltip>
              <div className='informationDescription'>No Events available yet</div>
            </div>)}
      </Skeleton>
      {!!items.length && !!pagination.total && (
        <Pagination
          current={pagination.current}
          pageSize={pagination.limit}
          total={pagination.total}
          showSizeChanger={false}
          className='pagination'
          onChange={onChange}
          disabled={loading}
        />
      )}
    </div>

  )
}

export default TableEvents
