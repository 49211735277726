import React, { useState, useRef, useCallback, useEffect, useContext } from 'react'
import { useDispatch } from 'react-redux'
import { Upload, Progress } from 'antd'
import {
  uploadImage,
} from 'modules/events/events-actions'
import {
  uploadAdditionalImages,
  deleteAdditionalImage,
  setAdditionalImages,
} from 'modules/memberships/memberships-actions'
import plus from 'images/orange-plus.svg'
import { getCroppedImg, notify } from 'utils'
import { CancelToken } from 'api/axios'
import { images } from 'apiv2'
import c from 'classnames'
import './index.styl'
import ModalCropImage from '../../../../CreateEvent/MultiMediaContainer/ModalCropImage'
import UploadComponent from '../UploadComponent'
import { CreateMemberLyfeContext } from '../../../index'

const { Dragger } = Upload
const onNotifyDenied = () => notify('Invalid file extension')

const getCoverName = imgUrl => imgUrl.replace(/^.*[\\\/]/, '')
const cropDefault = { unit: '%', width: 240, aspect: 16 / 9 }

const UploadImage = () => {
  const dispatch = useDispatch()
  const { currentPageDetails } = useContext(CreateMemberLyfeContext)

  const [loadingAdditionalCover, setLoadingAdditionalCover] = useState(false)
  const [loadingCover, setLoadingCover] = useState(false)
  const [progressCover, setProgressCover] = useState(false)
  const [loading, setLoading] = useState(null)
  const [ticketCover, setTicketCover] = useState('')
  const [ticketCoverName, setTicketCoverName] = useState(getCoverName(ticketCover))
  const [fileNameTemp, setFileNameTemp] = useState('')
  const [filesList, setFilesList] = useState([])
  const [fileDataBlob, setFileDataBlob] = useState(null)
  const [additionalFileDataBlob, setAdditionalFileDataBlob] = useState(null)
  const [crop, setCrop] = useState(cropDefault)
  const [originalImage, setOriginalImage] = useState('')
  const [additionalOriginalImage, setAdditionalOriginalImage] = useState('')
  const [showModal, setShowModal] = useState(false)
  const [showAdditionalModal, setShowAdditionalModal] = useState(false)
  const cancelRequest = useRef(null)
  const imageRef = useRef(null)
  const additionalImageRef = useRef(null)
  const onLoad = useCallback(img => (imageRef.current = img), [])
  const additionalOnLoad = useCallback(img => (additionalImageRef.current = img), [])

  const uploadProgress = setFunc => progressEvent => {
    setFunc(Math.round((progressEvent.loaded * 100) / progressEvent.total))
  }

  const clearTempState = () => {
    setFileDataBlob(null)
    setFileNameTemp('')
    setCrop(cropDefault)
  }

  const prepareFilesList = (files = []) => files.map(obj => {
    return { uid: obj?.original, name: obj?.fileName, uploadedUrl: obj?.original, thumbUrl: obj?.thumbnail1 }
  })

  useEffect(() => {
    if (currentPageDetails?.images?.thumbnail1) {
      setTicketCover(currentPageDetails?.images?.thumbnail1)
      setTicketCoverName(getCoverName(currentPageDetails?.images?.thumbnail1))
    }
    if (currentPageDetails?.additionalImages?.length) {
      dispatch(setAdditionalImages(currentPageDetails?.additionalImages))
      setFilesList(prepareFilesList(currentPageDetails?.additionalImages))
    }
    return () => dispatch(setAdditionalImages([]))
  }, [currentPageDetails])

  const handleConfirmModify = async () => {
    setLoadingCover(true)
    await getCroppedImg(imageRef.current, crop, ticketCoverName)
      .then(file => {
        const onUploadProgress = uploadProgress(setProgressCover)
        const cancelToken = new CancelToken(cancel => (cancelRequest.current = { cancel }))
        dispatch(uploadImage(originalImage, file, { onUploadProgress, cancelToken })).then(response => {
          if (response) {
            setTicketCover(response.original)
            setTicketCoverName(fileNameTemp)
            clearTempState()
          }
          setLoadingCover(false)
          setProgressCover(null)
          cancelRequest.current = null
        })
      })
      .catch(err => {
        notify(err?.message)
        setLoadingCover(false)
      })
  }

  const handleAdditionalConfirmModify = async () => {
    setLoadingAdditionalCover(true)
    setLoading(true)
    const _fileList = [...filesList]
    await getCroppedImg(additionalImageRef.current, crop, ticketCoverName)
      .then(file => {
        const onUploadProgress = uploadProgress(setProgressCover)
        const cancelToken = new CancelToken(cancel => (cancelRequest.current = { cancel }))
        dispatch(uploadAdditionalImages(additionalOriginalImage, file, { onUploadProgress, cancelToken })).then(response => {
          if (response) {
            setFilesList(_fileList.map(file => {
              file.uploadedUrl = response?.original
              if (response?.thumbnail) {
                file.imgData = response?.thumbnail
              }
              return file
            }))
            setShowAdditionalModal(false)
          }
          setLoadingAdditionalCover(false)
          setLoading(false)
          setProgressCover(null)
          cancelRequest.current = null
        })
      })
      .catch(err => {
        notify(err?.message)
        setLoadingAdditionalCover(false)
      })
  }

  const onCancel = () => {
    clearTempState()
    if (cancelRequest.current?.cancel) {
      cancelRequest.current.cancel('Upload canceled')
    }
    setShowModal(false)
    setShowAdditionalModal(false)
  }

  const onAdditionalImagesCancel = () => {
    clearTempState()
    if (cancelRequest.current?.cancel) {
      cancelRequest.current.cancel('Upload canceled')
    }
    setFilesList(prevList => prevList.slice(0, -1))
    setShowModal(false)
    setShowAdditionalModal(false)
  }

  const onFileChanged = async image => {
    if (image.file.status !== 'done') return
    if (image?.file?.type !== 'image/jpeg' && image?.file?.type !== 'image/png') {
      return onNotifyDenied()
    }
    setLoadingCover(true)
    setFileNameTemp(image?.file?.name)
    setOriginalImage(image?.file?.originFileObj)

    const onUploadProgress = uploadProgress(setProgressCover)
    images.preparePoster(image, { onUploadProgress, responseType: 'blob' })
      .then(response => {
        const blob = URL.createObjectURL(response.data)
        setFileDataBlob(blob)
        setLoadingCover(false)
        setShowModal(true)
      })
      .catch(err => {
        notify(err?.message || err?.response?.data?.message)
        setFileNameTemp(null)
        setLoadingCover(false)
      })
  }

  const onAdditionalImagesChanged = async data => {
    if (data.file.status === 'removed') {
      return
    }
    const { file } = data

    if (file?.type !== 'image/jpeg' && file?.type !== 'image/png') {
      return onNotifyDenied()
    }
    setLoading(true)
    setLoadingAdditionalCover(true)
    setAdditionalOriginalImage(file?.originFileObj)
    setFilesList(data.fileList)
    const onUploadProgress = uploadProgress(setProgressCover)
    if (data.file.status === 'done') {
      images.preparePoster(data, { onUploadProgress, responseType: 'blob' })
        .then(response => {
          const blob = URL.createObjectURL(response.data)
          setAdditionalFileDataBlob(blob)
          setLoadingAdditionalCover(false)
          setLoading(false)
          setShowAdditionalModal(true)
        })
        .catch(err => {
          notify(err?.message || err?.response?.data?.message)
          setLoadingAdditionalCover(false)
        })
    }
  }

  const onSuccess = ({ onSuccess }) => onSuccess('ok')

  const onRemoveFile = file => {
    setFilesList(prevFilesList => prevFilesList.filter(item => item.uid !== file.uid))
    dispatch(deleteAdditionalImage(file.uploadedUrl))
  }

  const showProgress = (progress, size = 52, isDark = false) => typeof progress === 'number' && (
    <div className={c('progressContainer', { isDark })}>
      <Progress
        trailColor='transparent'
        strokeLinecap='square'
        strokeColor='#19B47D'
        percent={progress}
        strokeWidth={10}
        showInfo={false}
        type='circle'
        width={size}
      />
    </div>
  )

  const uploadButton = (
    <div className='upload specialDesignGutter'>
      <div className='uploadTitle'>
        Additional Files
        <br />
        <img src={plus} alt='plus' />
      </div>
    </div>
  )

  return (
    <div className='wrapperUploaderMembership'>
      <div className='uploadContainer'>
        <div className={c('uploadSpinner', { isFull: !!ticketCover })}>
          <Dragger
            accept='image/png, image/jpeg'
            onChange={onFileChanged}
            customRequest={onSuccess}
            showUploadList={false}
            disabled={loadingCover}
            name='image'
          >
            <div className={c('upload', { ticketCover: !!ticketCover })}>
              {loadingCover && showProgress(progressCover)}
              {ticketCover && <img src={ticketCover} alt='' />}
              <div className='coverBackground' />
              {window.innerWidth <= 1350
                ? (
                  <div className='uploadTitle'> Upload ticket cover image or video</div>
                ) : (
                  <div className='uploadTitle'>Upload a cover image or video using Drag & Drop or</div>
                )}
              <div className='uploadBtn'>
                {ticketCover ? <>Change <span className='xsHidden'>Photo</span></> : 'Select file'}
              </div>
              <div className='uploadInfo'>Only jpg/ .JPG/ .png/ .jpeg/ file formats are allowed</div>
            </div>
          </Dragger>
          {fileDataBlob && showModal && (
            <ModalCropImage
              onConfirm={handleConfirmModify}
              className='cropImageModal'
              loading={loadingCover}
              onCancel={onCancel}
              file={fileDataBlob}
              setCrop={setCrop}
              onLoad={onLoad}
              crop={crop}
            />
          )}
        </div>
      </div>
      <div className='uploadAdditionalFilesWrapper'>
        <div className='uploadAdditionalFiles'>
          <UploadComponent
            accept='.png,.jpg,.jpeg'
            disabled={loading}
            customRequest={onSuccess}
            handleOnChange={onAdditionalImagesChanged}
            listType='picture-card'
            showUploadList={{ showPreviewIcon: false }}
            filesList={filesList}
            onRemove={onRemoveFile}
          >
            {uploadButton}
          </UploadComponent>
          {additionalFileDataBlob && showAdditionalModal && (
            <ModalCropImage
              onConfirm={handleAdditionalConfirmModify}
              className='cropImageModal'
              loading={loadingAdditionalCover}
              onCancel={onAdditionalImagesCancel}
              file={additionalFileDataBlob}
              setCrop={setCrop}
              onLoad={additionalOnLoad}
              crop={crop}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default UploadImage
