import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Tooltip, Skeleton } from 'antd'
import { ModalAcceptInvite, ModalDetailTicket } from 'components'
import Card from './Card'
import NoEventsInformation from 'images/NoEventsInformation.svg'
import { isOnDemand } from 'utils'
import { useLocation } from 'react-router'
import './index.styl'
import SpecialCard from './SpecialCard'

const Body = ({ valueSearch, loading }) => {
  const [inviteToAccept, setInviteToAccept] = useState()
  const [selectedTicket, setSelectedTicket] = useState()
  const [showModal, setShowModal] = useState(false)
  const [currentAcceptedTickets, setCurrentAcceptedTickets] = useState([])
  const { location } = useLocation()
  const { accepted, pending } = useSelector(state => state.events.myWallets)
  const { user } = useSelector(state => state.session)
  const { walletPendingDataGuest } = useSelector(state => state.events)

  useEffect(() => {
    if (accepted?.length) {
      setCurrentAcceptedTickets(accepted
        .map(event => isOnDemand(event?.eventEnd) ? ({ ...event, onDemand: true }) : event),
      )
    }
  }, [JSON.stringify(accepted)])

  const include = (item, name) => item[name]?.toLowerCase().includes(valueSearch.toLowerCase())

  const getItem = item => {
    const ticketID = item.id
    const eventID = item.eventID

    return (
      include(ticketID, 'ticketType') ||
      include(eventID, 'address') ||
      include(eventID, 'eventName') ||
      include(eventID, 'eventType') ||
      include(eventID, 'venue') ||
      include(eventID, 'presentedBy')
    )
  }

  const pendingTicket = (
    <div className='wrapperInvites'>
      <div className='title'>Invites Sent To Me:</div>
      <div className='wrapperCards'>
        {pending?.map((i, ind) =>
          getItem(i, 'Invite') && <Card key={ind} item={i} tag='Invite' setShowModal={setShowModal} onClick={setSelectedTicket} onSelectInvite={setInviteToAccept} />,
        )}
      </div>
    </div>
  )

  const pendingTicketGuest = (
    <div className='wrapperInvites'>
      <div className='title'>Invitations and Tickets Sent to you:</div>
      <div className='wrapperCards'>
        {walletPendingDataGuest?.map((i, ind) =>
          getItem(i, 'Invite') && <Card key={ind} pending={pending} item={i} tag='Invite' guest setShowModal={setShowModal} onClick={setSelectedTicket} onSelectInvite={setInviteToAccept} />,
        )}
      </div>
    </div>
  )

  return (
    <div className='wrapperBodyMyWallet'>
      <Skeleton loading={loading} active paragraph={{ rows: 15 }}>
        {currentAcceptedTickets?.length || pending?.length || walletPendingDataGuest?.length ? (
          <>
            {currentAcceptedTickets?.length > 0 && (
              <div className='wrapperCards'>
                {currentAcceptedTickets.map((i, ind) => (
                  <>
                    {i?.ticketType === 'RAFFLE' || i?.ticketType === 'ITEM'
                      ? <SpecialCard pathname={location} key={ind} item={i} tag='Ticket' setShowModal={setShowModal} onClick={setSelectedTicket} />
                      : <Card key={ind} item={i} tag='Ticket' setShowModal={setShowModal} onClick={setSelectedTicket} />}
                  </>
                ))}
              </div>
            )}
            {pending?.length > 0 && pendingTicket}
            {!user && walletPendingDataGuest?.length > 0 && pendingTicketGuest}
          </>
        ) : (
          <div className='wrapperBodyEmpty'>
            <Tooltip title='tooltip example'>
              <img src={NoEventsInformation} alt='Information Logo' />
            </Tooltip>
            <div className='informationDescription'>No tickets available yet</div>
          </div>
        )}
      </Skeleton>
      {inviteToAccept && <ModalAcceptInvite inviteData={inviteToAccept} onCloseModal={() => setInviteToAccept()} />}
      {showModal && <ModalDetailTicket ticketData={selectedTicket} onCloseModal={() => setShowModal(false)} onSelectInvite={setInviteToAccept} />}
    </div>
  )
}

export default Body
