import React from 'react'
import { useHistory } from 'react-router-dom'
import './index.styl'
import { CustomButton } from '../../../../components'
import MSImage from 'images/MSDefaultImage.png'

const SessionAndPassesCard = ({ sessions = [], passes = [], items = [], id, userId }) => {
  const history = useHistory()

  const handleGetMembership = () => {
    history.push(`/buyPage/${id}/${userId}`)
    window.scrollTo(0, 0)
  }
  return (
    <>
      {sessions?.length > 0 &&
        <div className='buySessionWrapper'>
          <div className='title'>Buy Sessions</div>
          <div className='sessionCardWrapper'>
            {sessions?.map((item, key) => (
              <div className='cardWrapper' key={key}>
                <div className='category'>{item.name}</div>
                <div className='price'>{'$' + item.price}</div>
                <div className='session'>{item.sessionSize} Sessions</div>
                <div className='description'>{item.description}</div>
                <CustomButton
                  text='Buy Now'
                  className='buyNow'
                  type='dark'
                  onClick={handleGetMembership}
                />
              </div>
            ))}
          </div>
        </div>}
      {passes?.length > 0 &&
        <div className='buyPassesWrapper'>
          <div className='title'>Buy Passes</div>
          <div className='passesCardWrapper'>
            {passes?.map((item, key) => (
              <div className='cardWrapper' key={key}>
                <div className='category'>{item.name}</div>
                <div className='price'>{'$' + item.price}</div>
                <div className='duration'>{item.duration}</div>
                <div className='description'>{item.description}</div>
                <CustomButton
                  text='Buy Now'
                  className='buyNow'
                  type='dark'
                  onClick={handleGetMembership}
                />
              </div>
            ))}
          </div>
        </div>}
      {items?.length > 0 &&
        <div className='itemsWrapper'>
          <div className='title'>Shop</div>
          <div className='itemsCardWrapper'>
            {items.map((item, key) => (
              <div key={key} className='cardWrapper'>
                <img src={item?.imageUrl?.thumbnail1 || MSImage} alt=' ' />
                <div className='name'>{item.name || item.categoryName}</div>
                <div className='price'>{'$' + item.price}</div>
                <CustomButton
                  text='Buy Now'
                  className='buyNow'
                  type='dark'
                  onClick={handleGetMembership}
                />
              </div>
            ))}
          </div>
        </div>}
    </>
  )
}

export default SessionAndPassesCard
