import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation, useParams, useHistory } from 'react-router-dom'
import { MainButton, ModalTicketAction, CustomButton } from 'components'
import { handleConfirmModal, isAdmin, notify, formatInteger } from 'utils'
import ThreeDotIcon from 'images/threedots.svg'
import EditIcon from 'images/editGray.svg'
import {
  getInvites,
  getEventDetailsSummaryV2,
  cashSaleTicketFromMyBoxOffice,
  deleteInvite,
} from 'modules/events/events-actions'
import {
  getMembershipDistributedTickets,
  getDonationList,
} from 'modules/memberships/memberships-actions'
import { Table, Input, Pagination, Tabs, Popover } from 'antd'
import moment from 'moment'
import SearchIcon from 'images/input-search.svg'
import { isEmpty } from 'lodash'
import { events } from 'api'
import { distribution } from 'apiv2'
import MobileTable from '../../MobileTable'
import c from 'classnames'
import './index.styl'
import ModalEditNote from '../../../../components/ModalEditNote'

const { TabPane } = Tabs

const Distribution = ({ currencySymbol }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [ticketID, setTicketID] = useState('')
  const [ticketType, setTicketType] = useState('')
  const [urls, setUrls] = useState([])
  const [loadUrls, setLoadUrls] = useState(false)
  const [visibleModal, setVisibleModal] = useState(false)
  const { eventID } = useParams()
  const { pathname, state = {} } = useLocation()
  const activeTable = pathname.split('/')[5].replace(/[\-\/]/gi, ' ')
  const [publicSaleData, setPublicSaleData] = useState([])
  const [directSaleData, setDirectSaleData] = useState([])
  const [cashCheckSaleData, setCashCheckSaleData] = useState([])
  const [publicBuyData, setPublicBuyData] = useState([])
  const [subPromotionsData, setSubPromotionsData] = useState([])
  const [giftData, setGiftTableData] = useState([])
  const [searchValue, setSearchValue] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [perPage] = useState(window.innerWidth < 1200 ? 3 : 10)
  const [dataLength, setDataLength] = useState(0)
  const [loading, setLoading] = useState(true)
  const [actionProcessing, setActionProcessing] = useState(false)
  const [activeMainTab, setActiveMainTab] = useState(pathname)
  const [activeTab] = useState(activeTable)
  const [openNotesModal, setOpenNotesModal] = useState(false)
  const [currentInviteID, setCurrentInviteID] = useState('')
  const scrollRef = useRef(null)
  const {
    currentEventDetails,
    inviteGiftUsers,
    inviteCashUsers,
    inviteSellUsers,
    inviteBuyUsers,
    invitePromoteUsers,
  } = useSelector(state => state.events)
  const {
    soldMemberDetails,
    currentPageDetails,
  } = useSelector(state => state.memberships)
  const tickets = [
    ...(currentPageDetails?.passes || []),
    ...(currentPageDetails?.sessions || []),
    ...(currentPageDetails?.items || []),
  ]
  const { user: { _id: currentUserId, role } } = useSelector(state => state.session)
  const skip = (currentPage - 1) * perPage
  const fetchUrlsData = () => {
    setLoadUrls(true)
    events.getSubPromoteUrls(eventID)
      .then(({ data: { data } }) => setUrls(data))
      .catch(error => notify(error?.response?.data?.message))
      .finally(() => setLoadUrls(false))
  }
  const handleModal = () => {
    setVisibleModal(!visibleModal)
  }
  const handleOpenNotesModal = () => {
    setOpenNotesModal(!openNotesModal)
  }
  // change for pages
  const handleTabChange = ticketType => {
    if (activeTab !== 'all memberships') {
      setTicketID(ticketType)
    } else {
      setTicketType(ticketType)
    }
  }

  const onFinishNotes = form => {
    const { notes } = form
    setLoading(!loading)
    const obj = { eventID, inviteID: currentInviteID, notes }
    distribution.updateInviteNotes(obj).then(() => {
      setLoading(false)
      updateTicketsOnPage(activeTab)
      setOpenNotesModal(false)
    }).catch(() => setLoading(false))
  }

  const formTicketAction = values => {
    if (actionProcessing) return
    setActionProcessing(true)
    const ticketOwnerId = currentPageDetails.userID
    let obj = {
      eventID: eventID,
      ticketID: values.category,
      email: values.email.trim(),
      quantity: values.quantity,
      inviteType: activeTable === 'gift tickets' ? 'GIFT' : activeTable === 'sub promote tickets' ? 'PROMOTE' : activeTable === 'cash sale tickets' ? 'CASH_SELL' : 'SELL',
      isSubPromoter: currentUserId !== ticketOwnerId && !isAdmin(role),
    }
    if (values.price) {
      obj = { ...obj, price: values.price }
    }
    if (values.mobile) {
      obj.mobile = values.mobile
    }
    if (values.notes) {
      obj.notes = values.notes
    }
    if (values.uniqueName && values.uniqueName.trim()) {
      obj = { ...obj, uniqueName: values.uniqueName.trim() }
    }
    dispatch(cashSaleTicketFromMyBoxOffice(obj)).then(response => {
      /* dispatch(getEventGetInviteUsers(currentEventDetails?.tickets[0]?._id, 'GIFT'))
      dispatch(getEventGetInviteUsers(currentEventDetails?.tickets[0]?._id, 'SELL')) */
      if (response) {
        dispatch(getEventDetailsSummaryV2(eventID, currentEventDetails?.isPromoted))
        updateTicketsOnPage(activeTab)
      }
      setActionProcessing(false)
    })
  }
  const processTableData = array => {
    return array?.map((i, index) => ({
      key: index,
      user: i?.invitee?.fullName || i?.fullName,
      mobile: i?.invitee?.fullMobile || i?.fullMobile,
      email: i?.invitee?.email || i?.email,
      inviteID: i?.id || i?._id,
      barcode: i?.barcode,
      quantity: formatInteger(i?.quantity),
      note: i?.notes,
      sentOn: `${moment(i?.sentOn).format('MM.DD.YYYY')} at ${moment(i?.sentOn).format('hh:mm a')}`,
      status: i?.inviteStatus,
      fullName: i?.fullName,
      isScanned: i?.isScanned,
      vanityUrl: i?.vanityUrl,
      price: i?.price,
      notes: i?.notes,
      ticketName: i?.ticketName,
      sessionsBought: i?.sessionsBought,
      sessionsRemaining: i?.sessionsRemaining,
      sessionsScanned: i?.sessionsScanned,
      purchaseDate: `${moment(i?.purchaseDate).format('MMM D, YYYY')}`,
      isActive: i?.isActive,
      amount: i?.amount,
    }))
  }
  const switchByActiveTable = (all, pub, direct, gift, cash, sub) => {
    const runIfFunc = obj => typeof obj === 'function' ? obj() : obj
    switch (activeTable) {
      case 'all memberships':
        return runIfFunc(all)
      case 'public sale membership':
        return runIfFunc(pub)
      case 'direct sale membership':
        return runIfFunc(direct)
      case 'gift membership':
        return runIfFunc(gift)
      case 'cash sale membership':
        return runIfFunc(cash)
    }
  }
  const calculateDataLength = () => switchByActiveTable(soldMemberDetails?.total, inviteBuyUsers?.total, inviteSellUsers?.total, inviteGiftUsers?.total, inviteCashUsers?.total, invitePromoteUsers?.total)

  const updateTicketsOnPage = type => {
    setLoading(true)
    switch (type) {
      case 'all memberships':
        dispatch(getMembershipDistributedTickets(eventID, ticketType, perPage, skip, searchValue))
        break
      case 'public sale membership':
        dispatch(getInvites(ticketID, 'BUY', perPage, skip, searchValue))
        break
      case 'direct sale membership':
        dispatch(getInvites(ticketID, 'SELL', perPage, skip, searchValue))
        break
      case 'cash sale membership':
        dispatch(getInvites(ticketID, 'CASH_SELL', perPage, skip, searchValue))
        break
      case 'gift membership':
        dispatch(getInvites(ticketID, 'GIFT', perPage, skip, searchValue))
        break
    }
  }

  useEffect(() => {
    setSearchValue('')
  }, [])
  useEffect(() => {
    if (tickets && isEmpty(ticketID)) {
      setTicketID(tickets[0]?.id)
      setTicketType(tickets[0]?.ticketType)
    }
  }, [tickets])
  useEffect(() => {
    if (visibleModal && activeTable.includes('promot')) {
      fetchUrlsData()
    }
  }, [visibleModal])

  useEffect(() => {
    setGiftTableData(processTableData(inviteGiftUsers?.data))
    setLoading(false)
  }, [inviteGiftUsers])

  useEffect(() => {
    setSubPromotionsData(processTableData(invitePromoteUsers?.data))
    setLoading(false)
  }, [invitePromoteUsers])

  useEffect(() => {
    setDirectSaleData(processTableData(inviteSellUsers?.data))
    setLoading(false)
  }, [inviteSellUsers])
  useEffect(() => {
    setCashCheckSaleData(processTableData(inviteCashUsers?.data))
    setLoading(false)
  }, [inviteCashUsers])

  useEffect(() => {
    setPublicBuyData(processTableData(inviteBuyUsers?.data))
    setLoading(false)
  }, [inviteBuyUsers])

  useEffect(() => {
    setPublicSaleData(processTableData(soldMemberDetails?.data))
    setLoading(false)
  }, [soldMemberDetails])

  useEffect(() => {
    setDataLength(calculateDataLength())
  }, [inviteBuyUsers, inviteSellUsers, inviteGiftUsers, inviteCashUsers, invitePromoteUsers, soldMemberDetails])
  useEffect(() => {
    ticketID && ticketType !== 'MEMBERSHIP_DONATION' && updateTicketsOnPage(activeTab)
    window.innerHeight <= 815 && scrollRef.current.scrollIntoView()
  }, [currentPage])

  useEffect(() => {
    ticketID && ticketType !== 'MEMBERSHIP_DONATION' && (currentPage !== 1 ? setCurrentPage(1) : updateTicketsOnPage(activeTab))
    if (ticketType === 'MEMBERSHIP_DONATION') {
      dispatch(getDonationList(eventID, perPage, skip, searchValue))
    }
  }, [activeTab, ticketID, ticketType, searchValue])

  let requestDelay = 0
  const onChangeSearch = e => {
    const search = e.target.value
    if (requestDelay) clearTimeout(requestDelay)
    requestDelay = setTimeout(() => {
      setSearchValue(search)
    }, 700)
  }

  const isOwner = currentUserId === currentPageDetails.userID || isAdmin(role)
  const distributionNavigation = [
    {
      text: 'All Memberships',
      to: `/member-office/member-details/member-distribution/${eventID}/all-memberships`,
    },
    {
      text: 'Online sales',
      to: `/member-office/member-details/member-distribution/${eventID}/public-sale-membership`,
    },
  ]
  if (isOwner) {
    const directSale = {
      text: 'Direct sales',
      to: `/member-office/member-details/member-distribution/${eventID}/direct-sale-membership`,
    }
    distributionNavigation.splice(distributionNavigation.length, 0, directSale)
  }

  const giftTicket = {
    text: 'Gifts',
    to: `/member-office/member-details/member-distribution/${eventID}/gift-membership`,
  }
  distributionNavigation.splice(distributionNavigation.length, 0, giftTicket)

  const cashCheck = {
    text: 'Cash/check sale',
    to: `/member-office/member-details/member-distribution/${eventID}/cash-sale-membership`,
  }
  distributionNavigation.splice(distributionNavigation.length, 0, cashCheck)
  // if (isOwner) {
  //  const subPromote = {
  //    text: 'Sub promote tickets',
  //    to: `/my-box-office/event-details/tickets-distribution/${eventID}/sub-promote-tickets`,
  //  }
  //  distributionNavigation.splice(distributionNavigation.length, 0, subPromote)
  // }

  useEffect(() => {
    if (currentPageDetails.userID && !distributionNavigation.find(({ to }) => pathname.includes(to))) {
      history.push(distributionNavigation[0]?.to)
    }
  }, [pathname, currentPageDetails.userID, JSON.stringify(distributionNavigation)])

  const popoverContent = inviteID => {
    const handleCancel = inviteID => {
      handleConfirmModal(
        'Are you sure you want to cancel?',
        () =>
          dispatch(deleteInvite({ eventID, inviteID }))
            .then(() => updateTicketsOnPage(activeTab))
            .catch(() => {}),
      )
    }
    return (
      <div className='actionPopover'>
        <div className='popoverBtn popove`rBtnRemove' onClick={() => handleCancel(inviteID)}>Cancel</div>
      </div>
    )
  }

  const defaultCol = [
    {
      title: 'User',
      dataIndex: 'user',
      key: 'user',
      render: isUser => isUser || '',
    },
    {
      title: 'Mobile',
      dataIndex: 'mobile',
      key: 'mobile',
      render: isMobile => isMobile || '',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
  ]
  const readyCol = {
    all: ticketType === 'MEMBERSHIP_PASS' ? [
      {
        title: 'Purchase Date',
        dataIndex: 'purchaseDate',
        key: 'purchaseDate',
      },
      {
        title: 'Full Name',
        dataIndex: 'fullName',
        key: 'fullName',
        render: isUser => isUser || '',
      },
      {
        title: 'Mobile',
        dataIndex: 'mobile',
        key: 'mobile',
        render: isMobile => isMobile || '',
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
      },
      {
        title: 'Status',
        key: 'isActive',
        dataIndex: 'isActive',
        render: (_, { isActive }) => (

          <div
            className={c('ticketDistributionStatus', { isActive }, { inactive: !isActive })}
          >{isActive ? 'Active' : 'Inactive'}
          </div>
        ),
      },
      {
        title: 'Notes',
        dataIndex: 'notes',
        key: 'notes',
        render: (note, inviteID) => {
          return (
            <div className='editNoteWrapper'>
              <div className='promoterNote'>
                {note}
              </div>
              <img
                onClick={() => {
                  handleOpenNotesModal()
                  setCurrentInviteID(inviteID.inviteID)
                }} src={EditIcon} alt='editIcon'
              />
            </div>
          )
        },
      },
    ]
      : ticketType === 'MEMBERSHIP_SESSIONS' ? [
        {
          title: 'Purchase Date',
          dataIndex: 'purchaseDate',
          key: 'purchaseDate',
          width: '14%',
        },
        {
          title: 'Ticket Name',
          dataIndex: 'ticketName',
          key: 'ticketName',
          width: '14%',
        },
        {
          title: 'Full Name',
          dataIndex: 'fullName',
          key: 'fullName',
          render: isUser => isUser || '',
          width: '18%',
        },
        {
          title: 'Mobile',
          dataIndex: 'mobile',
          key: 'mobile',
          render: isMobile => isMobile || '',
          width: '15%',
        },
        {
          title: 'Email',
          dataIndex: 'email',
          key: 'email',
          width: '12%',
        },
        {
          title: 'Sessions Bought',
          dataIndex: 'sessionsBought',
          key: 'sessionsBought',
        },
        {
          title: 'Active Sessions',
          dataIndex: 'sessionsRemaining',
          key: 'sessionsRemaining',
        },
        {
          title: 'Scanned Sessions',
          dataIndex: 'sessionsScanned',
          key: 'sessionsScanned',
        },
        {
          title: 'Notes',
          dataIndex: 'notes',
          key: 'notes',
          render: (note, inviteID) => {
            return (
              <div className='editNoteWrapper'>
                <div className='promoterNote'>
                  {note}
                </div>
                <img
                  onClick={() => {
                    handleOpenNotesModal()
                    setCurrentInviteID(inviteID.inviteID)
                  }} src={EditIcon} alt='editIcon'
                />
              </div>
            )
          },
        },
      ]
        : ticketType === 'ITEM' ? [
          {
            title: 'Purchase Date',
            dataIndex: 'purchaseDate',
            key: 'purchaseDate',
          },
          {
            title: 'Full Name',
            dataIndex: 'fullName',
            key: 'fullName',
            render: isUser => isUser || '',
          },
          {
            title: 'Mobile',
            dataIndex: 'mobile',
            key: 'mobile',
            render: isMobile => isMobile || '',
          },
          {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
          },
          {
            title: 'Ticket Name',
            dataIndex: 'ticketName',
            key: 'ticketName',
          },
          {
            title: 'Scanned',
            dataIndex: 'isScanned',
            key: 'isScanned',
            render: (_, { isScanned }) => (

              <div>{isScanned ? 'Yes' : 'No'}
              </div>
            ),
          },
          {
            title: 'Notes',
            dataIndex: 'notes',
            key: 'notes',
            render: (note, inviteID) => {
              return (
                <div className='editNoteWrapper'>
                  <div className='promoterNote'>
                    {note}
                  </div>
                  <img
                    onClick={() => {
                      handleOpenNotesModal()
                      setCurrentInviteID(inviteID.inviteID)
                    }} src={EditIcon} alt='editIcon'
                  />
                </div>
              )
            },
          },
        ]
          : [
            {
              title: 'Purchase Date',
              dataIndex: 'purchaseDate',
              key: 'purchaseDate',
            },

            {
              title: 'Full Name',
              dataIndex: 'fullName',
              key: 'fullName',
              render: isUser => isUser || '',
            },
            {
              title: 'Mobile',
              dataIndex: 'mobile',
              key: 'mobile',
              render: isMobile => isMobile || '',
            },
            {
              title: 'Email',
              dataIndex: 'email',
              key: 'email',
            },
            {
              title: 'Total Donate',
              dataIndex: 'amount',
              key: 'amount',
              render: (item, record) => <span>{currencySymbol}{item}</span>,
            },
          ],
    public: [
      ...defaultCol,
      {
        title: 'Quantity',
        key: 'quantity',
        dataIndex: 'quantity',
      },
      {
        title: 'Purchased on',
        key: 'sentOn',
        dataIndex: 'sentOn',
      },
      {
        title: 'Note to manager',
        key: 'notes',
        dataIndex: 'notes',
        render: (note, inviteID) => {
          return (
            <div className='editNoteWrapper'>
              <div className='promoterNote'>
                {note}
              </div>
              <img
                onClick={() => {
                  handleOpenNotesModal()
                  setCurrentInviteID(inviteID.inviteID)
                }} src={EditIcon} alt='editIcon'
              />
            </div>
          )
        },
      },
    ],
    direct: [
      ...defaultCol,
      {
        title: 'Qty',
        key: 'quantity',
        dataIndex: 'quantity',
      },
      {
        title: 'Price',
        key: 'price',
        dataIndex: 'price',
        render: price => (
          `$${price}`
        ),
      },
      {
        title: 'Status',
        key: 'status',
        dataIndex: 'status',
        render: isStatus => (
          <div className={c('ticketDistributionStatus',
            { isAccepted: isStatus === 'ACCEPTED' },
            { isPending: isStatus === 'PENDING' },
            { isRejected: isStatus === 'REJECTED' })}
          >{isStatus}
          </div>
        ),
      },
      {
        title: 'Note',
        key: 'note',
        dataIndex: 'note',
        render: note => (
          <div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', maxWidth: '180px', overflow: 'hidden' }}>
            {note}
          </div>
        ),
      },
      {
        key: 'status',
        dataIndex: 'status',
        render: (isStatus, record) => {
          return (
            <>
              {isStatus === 'PENDING' &&
                <Popover
                  overlayClassName='actionPopoverContainer'
                  getPopupContainer={node => node?.parentNode}
                  content={() => popoverContent(record?.inviteID)}
                  placement='top'
                  trigger='click'
                >
                  <img src={ThreeDotIcon} className='threeDots mdVisible' alt=' ' />
                  <CustomButton className='actionPopoverButton xsVisible' text='More Options' />
                </Popover>}
            </>
          )
        },
      },
    ],
    cash: [
      ...defaultCol,
      {
        title: 'Qty',
        key: 'quantity',
        dataIndex: 'quantity',
      },
      {
        title: 'Price',
        key: 'price',
        dataIndex: 'price',
        render: price => (
          `$${price}`
        ),
      },
      {
        title: 'Note',
        key: 'note',
        dataIndex: 'note',
      },
      {
        dataIndex: 'inviteID',
        render: (inviteID, record) => {
          return (
            <>
              <Popover
                overlayClassName='actionPopoverContainer'
                getPopupContainer={node => node?.parentNode}
                content={() => popoverContent(inviteID === undefined ? record.inviteID : inviteID)}
                placement='top'
                trigger='click'
              >
                <img src={ThreeDotIcon} className='threeDots mdVisible' alt=' ' />
                <CustomButton className='actionPopoverButton xsVisible' text='More Options' />
              </Popover>
            </>
          )
        },
      },
    ],
    sub: [
      ...defaultCol,
      {
        title: 'Vanity URL',
        key: 'vanityUrl',
        dataIndex: 'vanityUrl',
      },
      {
        title: 'Quantity',
        key: 'quantity',
        dataIndex: 'quantity',
      },
      {
        title: 'Status',
        key: 'status',
        dataIndex: 'status',
        render: isStatus => (
          <div className={c('ticketDistributionStatus',
            { isAccepted: isStatus === 'ACCEPTED' },
            { isPending: isStatus === 'PENDING' },
            { isRejected: isStatus === 'REJECTED' })}
          >{isStatus}
          </div>
        ),
      },
    ],
    rest: [
      ...defaultCol,
      {
        title: 'Quantity',
        key: 'quantity',
        dataIndex: 'quantity',
      },
      {
        title: 'Note',
        key: 'note',
        dataIndex: 'note',
      },
      {
        dataIndex: 'inviteID',
        render: (inviteID, record) => {
          return (
            <>
              <Popover
                overlayClassName='actionPopoverContainer'
                getPopupContainer={node => node?.parentNode}
                content={() => popoverContent(inviteID === undefined ? record.inviteID : inviteID)}
                placement='top'
                trigger='click'
              >
                <img src={ThreeDotIcon} className='threeDots mdVisible' alt=' ' />
                <CustomButton className='actionPopoverButton xsVisible' text='More Options' />
              </Popover>
            </>
          )
        },
      },
    ],
  }
  const actionButton = (!activeTable.includes('public') && !activeTable.includes('all') || !activeTable.includes('public') && !activeTable.includes('all') && (isAdmin(role))
    ? (
      <MainButton
        text={activeTable.includes('direct') || activeTable.includes('cash')
          ? 'Sell Tickets'
          : activeTable.includes('promot')
            ? 'Sub-promote Tickets'
            : 'Gift Tickets'}
        className='btnAction'
        onClick={handleModal}
      />)
    : <div />)

  const placeholder = switchByActiveTable('Search', 'Search by publicly sold', 'Search by directly sold', 'Search by gifted', 'Search by cash/check', 'Search by sub-promoters')

  const searchPanel = (
    <div className='searchPanel'>
      <Input
        className='inputSearch darkBg noFocusBorder'
        placeholder={placeholder}
        onChange={e => onChangeSearch(e)}
        prefix={<img alt='search icon' src={SearchIcon} />}
        allowClear
      />
    </div>)

  const includes = (item, key) => item[key] ? item[key].toLowerCase().includes(searchValue.toLowerCase()) : false
  const filterData = data =>
    !searchValue ? data : data?.filter(item => includes(item, 'user') || includes(item, 'email') || includes(item, 'mobile') || includes(item, 'note'))

  const tabs = (
    <>
      {activeTab === 'all memberships' ? (
        <Tabs
          onChange={key => handleTabChange(key)} type='card' className='customTabs'
        >
          {currentPageDetails?.passes?.length > 0 && (
            <TabPane tab='Passes' key='MEMBERSHIP_PASS' />
          )}
          {currentPageDetails?.sessions?.length > 0 && (
            <TabPane tab='Sessions' key='MEMBERSHIP_SESSIONS' />
          )}
          {currentPageDetails?.items?.length > 0 && (
            <TabPane tab='Items' key='ITEM' />
          )}
          {currentPageDetails?.donationEnabled && (
            <TabPane tab='Donation' key='MEMBERSHIP_DONATION' />
          )}

        </Tabs>
      ) : (
        <Tabs
          onChange={id => handleTabChange(id)} type='card' className='customTabs'
        >
          {tickets?.map(i => (
            <TabPane tab={i.name} key={i.id} />
          ))}
        </Tabs>
      )}
    </>
  )
  const catTabs = () => (
    <div className='categoryTabs'>
      {tabs}
      <Input
        className='inputSearch darkBg noFocusBorder'
        placeholder={placeholder}
        onChange={e => onChangeSearch(e)}
        prefix={<img alt='search icon' src={SearchIcon} />}
        allowClear
      />
    </div>
  )

  const columns = switchByActiveTable(readyCol.all, readyCol.public, readyCol.direct, readyCol.rest, readyCol.cash, readyCol.sub)
  const dataSource = switchByActiveTable(filterData(publicSaleData), filterData(publicBuyData), filterData(directSaleData), filterData(giftData), filterData(cashCheckSaleData), filterData(subPromotionsData))
  const onMainTabChanged = pathname => {
    setActiveMainTab(pathname)
    history.push({ pathname, state })
  }
  return (
    <>
      <div className='wrapperTablesAssignSeating memberDistribution'>
        <div ref={scrollRef} className='titleAssignedSeating'>Membership Distribution</div>
        <div className='member_navigation_wrap'>
          <Tabs
            onChange={onMainTabChanged}
            animated={false}
            className='distributionNavTabs'
            activeKey={activeMainTab}
            tabBarExtraContent={{ right: actionButton }}
          >
            {distributionNavigation.map(i => (
              <TabPane tab={i.text} key={i.to} />
            ))}
          </Tabs>
          {actionButton}
        </div>
        <div className='table_content'>
          {window.innerWidth > 1295
            ? (
              <Table
                columns={columns}
                pagination={false}
                loading={loading}
                dataSource={!loading ? dataSource : null}
                title={() => catTabs()}
                className='eventDetailsTable'
              />
            )
            : (
              <div className='mobileTable'>
                {tabs}
                {searchPanel}
                <MobileTable data={dataSource} columns={columns} loading={loading} />
              </div>)}
          <Pagination
            hideOnSinglePage total={dataLength} pageSize={perPage} onChange={page => setCurrentPage(page)}
            current={currentPage} className='pagination'
          />
        </div>
      </div>
      <ModalTicketAction
        actionType={activeTable.includes('direct') || activeTable.includes('cash') ? 'sell' : activeTable.includes('promot') ? 'promote' : 'gift'}
        giftActiveTable={activeTable}
        visible={visibleModal}
        eventID={eventID}
        giftActiveTab={activeTab}
        updateTicketsOnPage={updateTicketsOnPage}
        eventDetails={currentPageDetails}
        categories={tickets}
        updateVisible={handleModal}
        submitForm={formTicketAction}
        currencySymbol={currencySymbol}
        openModalForm={setVisibleModal}
        loading={actionProcessing || loadUrls}
        urls={urls}
        setTicketID={setTicketID}
      />
      <ModalEditNote noteTo='manager' currentNote={dataSource?.filter(item => item.inviteID === currentInviteID)[0]?.note} onFinish={onFinishNotes} onChange={handleOpenNotesModal} visible={openNotesModal} loading={loading} />
    </>
  )
}

export default Distribution
