import React, { useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { AutoComplete, notification } from 'antd'
import { throttle, uniqBy } from 'lodash'
import { memberships, eventsV2 } from 'apiv2'
import searchIcon from 'images/input-search.svg'
import './index.styl'

const Search = () => {
  const [options, setOptions] = useState([])
  const [value, setValue] = useState('')
  const history = useHistory()
  const { pathname } = useLocation()
  const { user } = useSelector(state => state.session)
  const onChange = e => setValue(e)
  const onSelect = item => {
    setOptions([])
    history.push(`/buy/${item.id}/${item.userid}`)
    setValue('')
  }
  // const searchPlaceholder = <span className='searchPlaceholder'>Events, artists or places</span>
  const transformData = i => ({ value: i.id, label: i.eventName, id: i.id, userid: i.userID })

  const onSearch = eventName => {
    if (eventName && eventName.length > 1) {
      if (pathname.includes('venue') && user?.venueUrl) {
        const objVenue = { venue: user.venueUrl, eventName }
        eventsV2
          .getPublicVenueEvent(objVenue)
          .then(({ data: { events = [] } }) => {
            setOptions(uniqBy(events?.map(transformData), 'id'))
          })
          .catch(error => notification.warning({ message: error?.response?.data?.message }))
      } else {
        memberships
          .getMemberPublicEvent({ eventName })
          .then(({ data: { events = [] } }) => {
            setOptions(uniqBy(events?.map(transformData), 'id'))
          })
          .catch(error => notification.warning({ message: error?.response?.data?.message }))
      }
    } else {
      setOptions([])
    }
  }
  return (
    <div className='wrapperMainSearch'>
      <span className='searchIcon'><img src={searchIcon} alt='search icon' /></span>
      <AutoComplete
        onChange={throttle(onChange, 500)}
        onSearch={throttle(onSearch, 500)}
        onSelect={(_, item) => onSelect(item)}
        suffixIcon={<img src={searchIcon} alt='search icon' />}
        notFoundContent='No Events Available'
        dropdownClassName='search_dropdown'
        placeholder='Events, artists or places'
        dropdownAlign='topLeft'
        className='mainSearch'
        options={options}
        value={value}
        allowClear
      />
    </div>
  )
}

export default Search
