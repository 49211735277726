/* eslint-disable react/jsx-closing-tag-location */
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Modal } from 'antd'
import { FormPayment } from 'components'
import { Tickets } from '../ModalBuyTicket/Screens'
import Category from '../ModalBuyTicket/Category'
import StepsModal from '../ModalBuyTicket/StepsModal'
import './index.styl'
import cardImg from 'images/card.png'
import moment from 'moment'
import calendar from 'images/Calendar.svg'
import closeIconSvg from 'images/Crossmark.svg'

const ModalBuyPrivateTickets = ({ orderedTickets = [], openModalForm, resetField, eventDetails, updateVisible, updateTicketsOnPage, giftActiveTable, giftActiveTab, giftValues, inviteType, updateFunc, visible = false, setVisible, img, donation, eventID, callback = () => { }, currencySymbol, action, quantity, price }) => {
  const { user } = useSelector(state => state.session)
  const [screen, setScreen] = useState('payment')
  const privateLiveStreamData = [
    inviteType === 'Gift' ? {
      total: price,
      type: `Gift ${donation} tickets`,
    }
      : {
        total: price,
        type: `Bundle of ${donation} tickets`,
      },
  ]

  const { eventName, startDate } = useSelector(state => state.events.currentEvent)
  const onChangeScreen = scr => setScreen(scr)
  const onTicketsBought = scr => {
    onChangeScreen(scr)
  }
  const onCancel = () => {
    setVisible(false)
    if (screen === 'tickets' && inviteType === 'Gift') {
      resetField()
      openModalForm(true)
    }
  }

  const closeIcon = <img src={closeIconSvg} alt='close icon' className='closeIcon' />
  const attr = { visible, onCancel, width: 840, maxWidth: 840, footer: null, className: 'modalBuyTicket' }
  const screens = {
    payment: () => <div className='screen'>
      <FormPayment
        orderedTickets={orderedTickets}
        callback={onTicketsBought}
        currencySymbol={currencySymbol}
        donation={donation}
        updateFunc={updateFunc}
        giftValues={giftValues}
        giftActiveTable={giftActiveTable}
        giftActiveTab={giftActiveTab}
        eventDetails={eventDetails}
        updateVisible={updateVisible}
        updateTicketsOnPage={updateTicketsOnPage}
        inviteType={inviteType}
        resetField={resetField}
        quantity={quantity}
        selectedOption='Stripe'
        totalPrice={price}
        eventID={eventID}
        action={action}
      />
    </div>,
    tickets: () => <div className='screen'><Tickets inviteType={inviteType} price={price} currencySymbol={currencySymbol} /></div>,
  }
  return (
    <Modal title='Purchase tickets' {...attr} closeIcon={closeIcon} centered>
      <div className='wrapperCategories'>
        <div className='imgLeft'>
          <img
            src={img}
            onError={e => (e.target.src = cardImg)}
            alt='cardDetail'
            className='img'
          />
          <div className='front'>
            <div className='title'>{eventName}</div>
            <ul className='descriptions'>
              <li className='dateWrapper'>
                <img src={calendar} alt='calendar' className='dateIcon' />
                <div className='date'>{moment(startDate).format('MMMM DD, YYYY')}</div>
              </li>
            </ul>
          </div>
        </div>
        <div className='wrapperDescription'>
          <div className='title'>Purchase:</div>

          <ul>
            {
              privateLiveStreamData.map((feeItem, index) => (
                <Category key={`fee${index}`} item={feeItem} currencySymbol={currencySymbol} />
              ))
            }
            <Category key='total' item={{ type: 'Total', total: price }} total currencySymbol={currencySymbol} />
          </ul>

        </div>
      </div>

      <div className='wrapperScreen'>
        <StepsModal activeScreen={screen} userLogged={user} />
        {screens[screen]()}
      </div>
    </Modal>
  )
}

export default ModalBuyPrivateTickets
