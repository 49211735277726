import React, { useState, useRef, useCallback, useEffect, useContext } from 'react'
import { useDispatch } from 'react-redux'
import { Upload, Progress, Form } from 'antd'

import plus from 'images/orange-plus.svg'
// import MSImage from 'images/MSDefaultImage.png'
import { getCroppedImg, notify } from 'utils'
import { CancelToken } from 'api/axios'
import { images } from 'apiv2'
import c from 'classnames'
import './index.styl'

import ModalCropImage from '../../../CreateEventV2/MultiMediaContainer/ModalCropImage'
import { CreateMemberLyfeContext } from '../..'

const { Dragger } = Upload
const onNotifyDenied = () => notify('Invalid file extension')

const getCoverName = imgUrl => imgUrl.replace(/^.*[\\\/]/, '')
const cropDefault = { unit: '%', width: 240, aspect: 16 / 9 }

const ImageUploader = ({ name, action, setImageUrl, categoryData, id, uploaderTitle }) => {
  const dispatch = useDispatch()
  const { currentPageDetails } = useContext(CreateMemberLyfeContext)

  const [loadingCover, setLoadingCover] = useState(false)
  const [progressCover, setProgressCover] = useState(false)
  const [ticketCover, setTicketCover] = useState('')
  const [ticketCoverName, setTicketCoverName] = useState(getCoverName(ticketCover))
  const [fileNameTemp, setFileNameTemp] = useState('')
  const [fileDataBlob, setFileDataBlob] = useState(null)
  const [crop, setCrop] = useState(cropDefault)
  const [originalImage, setOriginalImage] = useState('')

  const cancelRequest = useRef(null)
  const imageRef = useRef(null)
  const onLoad = useCallback(img => (imageRef.current = img), [])

  const uploadProgress = setFunc => progressEvent => {
    setFunc(Math.round((progressEvent.loaded * 100) / progressEvent.total))
  }

  const clearTempState = () => {
    setFileDataBlob(null)
    setFileNameTemp('')
    setCrop(cropDefault)
  }

  useEffect(() => {
    if (categoryData?.imageUrl?.thumbnail1) {
      setTicketCover(categoryData.imageUrl?.thumbnail1)
      setTicketCoverName(getCoverName(categoryData.imageUrl?.thumbnail1))
    }
  }, [currentPageDetails])

  const handleConfirmModify = async () => {
    setLoadingCover(true)
    await getCroppedImg(imageRef.current, crop, ticketCoverName)
      .then(file => {
        const onUploadProgress = uploadProgress(setProgressCover)
        const cancelToken = new CancelToken(cancel => (cancelRequest.current = { cancel }))
        dispatch(action(originalImage, file, { onUploadProgress, cancelToken })).then(response => {
          if (response) {
            setTicketCover(response.original)
            setTicketCoverName(fileNameTemp)
            setImageUrl(response)
            clearTempState()
          }
          setLoadingCover(false)
          setProgressCover(null)
          cancelRequest.current = null
        })
      })
      .catch(err => {
        notify(err?.message)
        setLoadingCover(false)
      })
  }

  const onCancel = () => {
    clearTempState()
    if (cancelRequest.current?.cancel) {
      cancelRequest.current.cancel('Upload canceled')
    }
  }

  const onFileChanged = async image => {
    if (image.file.status !== 'done') return
    if (image?.file?.type !== 'image/jpeg' && image?.file?.type !== 'image/png') {
      return onNotifyDenied()
    }
    setLoadingCover(true)
    setFileNameTemp(image?.file?.name)
    setOriginalImage(image?.file?.originFileObj)

    const onUploadProgress = uploadProgress(setProgressCover)
    images.preparePoster(image, { onUploadProgress, responseType: 'blob' })
      .then(response => {
        const blob = URL.createObjectURL(response.data)
        setFileDataBlob(blob)
        setLoadingCover(false)
      })
      .catch(err => {
        notify(err?.message || err?.response?.data?.message)
        setFileNameTemp(null)
        setLoadingCover(false)
      })
  }

  const onSuccess = ({ onSuccess }) => onSuccess('ok')
  const showProgress = (progress, size = 52, isDark = false) => typeof progress === 'number' && (
    <div className={c('progressContainer', { isDark })}>
      <Progress
        trailColor='transparent'
        strokeLinecap='square'
        strokeColor='#19B47D'
        percent={progress}
        strokeWidth={10}
        showInfo={false}
        type='circle'
        width={size}
      />
    </div>
  )
  const handleChangeImageClick = () => {
    document.getElementById(id).click()
  }
  return (
    <div className='tabsUploaderImageWrapper'>
      <div className='uploadContainer'>
        <div className={c('uploadSpinner', { isFull: !!ticketCover })}>
          <Form.Item
            name={[name, 'imageUrl']}
          >
            <Dragger
              accept='image/png, image/jpeg'
              onChange={onFileChanged}
              customRequest={onSuccess}
              showUploadList={false}
              disabled={loadingCover}
              id={id}
              name={[name, 'imageUrl']}
            >
              <div className={c('upload', { ticketCover: !!ticketCover })}>
                {loadingCover && showProgress(progressCover)}
                {ticketCover && <img className='ticketCover' src={ticketCover} alt='' />}
                {/* {!ticketCover && currentPageDetails && <img className='ticketCover' src={MSImage} alt='' />} */}
                <div className='coverBackground' />
              </div>
              {!ticketCover &&
                <div className='upload specialDesignGutter'>
                  <div className='uploadTitle'>
                    Upload {uploaderTitle} Image
                    <br />
                    <img src={plus} alt='plus' />
                  </div>
                </div>}
            </Dragger>
          </Form.Item>
        </div>
        {fileDataBlob && (
          <ModalCropImage
            onConfirm={handleConfirmModify}
            className='cropImageModal'
            loading={loadingCover}
            onCancel={onCancel}
            file={fileDataBlob}
            setCrop={setCrop}
            onLoad={onLoad}
            crop={crop}
          />
        )}
      </div>
      {ticketCover && (
        <div className='changeImageButton' onClick={handleChangeImageClick}>Change image</div>
      )}
    </div>
  )
}

export default ImageUploader
