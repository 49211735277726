import React, { useState, useContext, useEffect } from 'react'
import { Form, Switch, Divider } from 'antd'
import { CreateMemberLyfeContext } from '../../../../..'
import ChatForm from './ChatForm'
import './index.styl'

const { Item, List } = Form

const Chat = ({ currentPageDetails }) => {
  const { form } = useContext(CreateMemberLyfeContext)

  const [chatSwitch, setChatSwitch] = useState(false)
  const [activeChat, setActiveChat] = useState(true)
  const [chats, setChats] = useState([])

  useEffect(() => {
    if (currentPageDetails?.additionalChats?.filter(i => !i?.isPrimary).length) {
      setChatSwitch(true)
      setChats(currentPageDetails.additionalChats)
    }
  }, [currentPageDetails])

  useEffect(() => {
    if (!chats.filter(i => !i?.isPrimary)?.length) {
      setChatSwitch(false)
      form.setFieldsValue({
        chatSwitch: false,
      })
    }
  }, [chats, form])

  const handleSwitchChange = checked => {
    setChatSwitch(checked)
    if (checked && !chats?.filter(i => !i?.isPrimary).length) {
      const newChat = [{}]
      setChats(newChat)
      form.setFieldsValue({
        additionalChats: newChat,
      })
    }
  }

  const getChatData = idx => {
    return chats[idx]
  }

  return (
    <div className='chatWrapper radioItem'>
      <div className={chatSwitch ? 'betweenWraper active' : 'betweenWraper'}>
        <div className='radioWrapperActiveChat'>
          <Item style={{ marginBottom: 0 }} name='chatActive' valuePropName='checked'>
            <Switch onChange={() => setActiveChat(!activeChat)} />
          </Item>
          <div className='text'>Activate Chat</div>
        </div>
        <div className='radioWrapper'>
          <Item style={{ marginBottom: 0 }} name='chatSwitch' valuePropName='checked'>
            <Switch onChange={handleSwitchChange} />
          </Item>
          <div className='text'>Add Extra Chats</div>
        </div>
      </div>
      {chatSwitch && (
        <List name='additionalChats'>
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, fieldKey, ...restField }) => (
                <ChatForm
                  currentPageDetails={currentPageDetails}
                  categoryData={getChatData(fieldKey)}
                  form={form}
                  key={key}
                  name={name}
                  fieldKey={fieldKey}
                  onRemove={index => {
                    remove(index)
                    const updatedChats = form.getFieldValue('additionalChats')
                    setChats(updatedChats)
                    if (updatedChats.length === 0) {
                      form.setFieldsValue({
                        chatSwitch: false,
                      })
                    }
                  }}
                  {...restField}
                />
              ))}
              <div
                id='addCategory' className='addCategoryBtn' onClick={() => {
                  add()
                  const updatedChats = form.getFieldValue('additionalChats')
                  setChats(updatedChats)
                }}
              >Add Another Extra Chat
              </div>
            </>
          )}
        </List>
      )}
      <Divider />
    </div>
  )
}

export default Chat
