import React, { useState } from 'react'
import { CustomButton } from 'components'
import { useSelector } from 'react-redux'
import close from 'images/closeBanner.svg'
import logo from 'images/MLBanerLogo.svg'
import c from 'classnames'
import './index.styl'
import { useHistory } from 'react-router'

export default () => {
  const [fadeOut, setFadeOut] = useState(false)
  const history = useHistory()
  const { user } = useSelector(state => state.session)
  return (
    <>
      <div className='banner-manage-ml'>
        <div className={c('banner', { fadeIn: !fadeOut, fadeOut: fadeOut })}>
          <img src={close} alt='close' className='close' onClick={() => setFadeOut(true)} />
          <div className='flex-container'>
            <div className='logoTextWrapper'>
              <div className='info'>
                <div className='description'>
                  <img src={logo} alt='logo' />
                </div>
              </div>
              <div className='infoCenter'>
                <div className='description'>
                  <div className='title'>Manage Your Club Now</div>
                  <span className='text'>Give your Members Lyfe... Make your Group Dynamic</span>
                </div>
              </div>
            </div>
            <CustomButton className='button' text='Try It' type='darkInverse' onClick={() => history.push(user ? '/memberlyfe-home' : '/auth/sign-in')} />
          </div>
        </div>
      </div>
    </>

  )
}
