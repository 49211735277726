import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Slider, MainButton } from 'components'
import TwoToneButton from 'pages/Home/TwoToneButton'
import { getUtcOffsetDate, isOnDemand, getEventLabel } from 'utils'
import { Skeleton, Tooltip } from 'antd'

import cardImg from 'images/card.png'
import ellipse from 'images/Ellipse.svg'
import soldOutBadge from 'images/Soldoutbadge.svg'
import onDemand from 'images/OnDemand.svg'
import canceled from 'images/Canceled.svg'
import NoEventsInformation from 'images/NoEventsInformation.svg'
import './index.styl'

const SliderHome = ({ loaded }) => {
  const { publicEvents = [] } = useSelector(state => state.events)
  const history = useHistory()
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 2200 },
      items: 5,
    },
    largeDesktop: {
      breakpoint: { max: 2200, min: 1800 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 1800, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  }
  const onClick = item => history.push(`/buy/${item.id}/${item.userID}`)
  // show first 15 events
  const sliderEvents = publicEvents.slice(0, 15)
  const renderButton = item => {
    const ButtonComponent = item.isDonationEnable ? TwoToneButton : MainButton
    return (
      <ButtonComponent
        onClick={() => onClick(item)}
        subText='Donate Today'
        text='Get tickets'
        className='btn'
      />
    )
  }

  const slider = (
    <Slider showDots responsive={responsive}>
      {sliderEvents.map((item, index) => (
        <div key={index} className='wrapperSlide'>
          <div className='eventType'>{getEventLabel(item)}</div>
          <img
            src={item?.imageUrl?.thumbnail1}
            onError={e => (e.target.src = cardImg)}
            alt='card'
            className='imgCard'
          />
          {/* <div className='background' /> */}
          <div className='front'>
            <div className='title'>{item.eventName}</div>
            <ul className='descriptions'>
              <li className='name'>
                {item.venue ? item.venue : 'Virtual event'}
              </li>
              <li className='ellipse'>
                <img src={ellipse} alt='ellipse' />
              </li>
              <li className='date'>
                {getUtcOffsetDate(item.startDate, 'MMMM DD, YYYY')}
              </li>
            </ul>
            {item?.quantityAvailable > 0 && renderButton(item)}
            {item?.isEventCancelled
              ? <div className='badgeWrapper'><img src={canceled} alt=' ' className='onDemandBadge' draggable={false} /></div>
              : isOnDemand(item.endDate) && !!item.streamTicketsAvailable
                ? <div className='badgeWrapper'><img src={onDemand} alt=' ' className='onDemandBadge' draggable={false} /></div>
                : item.quantityAvailable <= 0
                  ? (
                    <div className='badgeWrapper'>
                      <img src={soldOutBadge} alt='sold out badge' className='soldOutBadge' draggable={false} />
                    </div>
                  )
                  : null}
          </div>
        </div>
      ))}
    </Slider>
  )
  const placeholder = (
    <div className='wrapperBodyEmpty'>
      <Tooltip title='No Events available'>
        <img src={NoEventsInformation} alt='Information Logo' />
      </Tooltip>
      <div className='informationDescription'>No Events available yet</div>
    </div>
  )
  return (
    <div>
      {!loaded
        ? (
          <div className='sliderSkeleton'>
            <Skeleton.Image className='sliderSkeletonImage' loading={!loaded} active={!loaded} />
            <Skeleton.Image className='sliderSkeletonImage' loading={!loaded} active={!loaded} />
            <Skeleton.Image className='sliderSkeletonImage' loading={!loaded} active={!loaded} />
            <Skeleton.Image className='sliderSkeletonImage' loading={!loaded} active={!loaded} />
          </div>
        )
        : sliderEvents.length ? slider : placeholder}
    </div>)
}

export default SliderHome
