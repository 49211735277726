import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import {
  getPageDetails,
  checkMembershipMember,
} from 'modules/memberships/memberships-actions'
import { getEventV2, resetEvent, getEventIDByURL } from 'modules/events/events-actions'
import { MemberLyfeLayout } from 'components'
import { useLocation } from 'react-router'
import Header from './Header'
import Body from './Body'
import './index.styl'

const DetailPage = () => {
  const { currentPageDetails } = useSelector(state => state.memberships)
  const { id, userId, customURL } = useParams()
  const { pathname } = useLocation()
  const dispatch = useDispatch()

  // const path = window.location.pathname.split('/')
  const getEventData = () => {
    if (customURL) {
      dispatch(getEventIDByURL(customURL))
    } else if (pathname?.includes('buyPage')) {
      dispatch(getPageDetails(id))
      dispatch(getEventV2(id, userId))
      dispatch(checkMembershipMember(id))
    } else {
      dispatch(getEventV2(id, userId))
    }
  }
  useEffect(() => {
    getEventData()
    return () => {
      dispatch(resetEvent())
    }
  }, [id, userId])
  return (
    <MemberLyfeLayout className='wrapperDetailPage'>
      <Header id={id} currentPageDetails={currentPageDetails} />
      {/* {currentEvent.id ? ( */}
      <Body getEventData={getEventData} id={id} currentPageDetails={currentPageDetails} />
      {/* ) : <div className='wrapperBodyTicket' />} */}
    </MemberLyfeLayout>
  )
}

export default DetailPage
