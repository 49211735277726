import React from 'react'
import { Modal, Form, Input, InputNumber } from 'antd'
import { CustomButton, Counter } from 'components'
import isInteger from 'lodash/isInteger'
import './index.styl'

const { Item } = Form
const { TextArea } = Input

const ModalCreateBundle = ({ visible, currencySymbol, currentEventDetails, updateVisible, submitForm, loading, categoryCounts, setCategoryCounts }) => {
  const attr = { visible, onCancel: updateVisible, width: 472, maxWidth: 472, footer: null, className: 'modal-create-bundle', destroyOnClose: true }
  const integerValidator = message => {
    return {
      validator (_, value) {
        if (value && (!isInteger(value))) {
          return Promise.reject(new Error(message))
        }
        return Promise.resolve()
      },
    }
  }
  const validateCategories = message => {
    return {
      validator (_, value) {
        if (categoryCounts.some(category => category)) {
          return Promise.resolve()
        }
        return Promise.reject(new Error(message))
      },
    }
  }

  const maxValidator = () => {
    return {
      validator (_, value) {
        if (value && value.toString().includes('.')) {
          const decimalPart = value.toString().split('.')[1]
          if (decimalPart.length > 2) {
            return Promise.reject(new Error('Price should have at most two decimal digits'))
          }
        }
        if (value <= 0) {
          return Promise.reject(new Error('Price should be more than 0'))
        }
        return Promise.resolve()
      },
    }
  }

  const handleCounterChange = (ticketCategoryID, quantity) => {
  // Find the index of the category in the categoryCounts array
    const index = categoryCounts.findIndex(category => category.ticketCategoryID === ticketCategoryID)

    // If the quantity is 0, remove the category from categoryCounts (if it exists)
    if (quantity === 0 && index !== -1) {
      const updatedCounts = [...categoryCounts]
      updatedCounts.splice(index, 1)
      setCategoryCounts(updatedCounts)
    } else {
    // Create a new category object with the updated count
      const updatedCategory = {
        ticketCategoryID,
        quantity,
      }

      if (index === -1) {
      // If the category is not in the array, add it
        setCategoryCounts([...categoryCounts, updatedCategory])
      } else {
      // If the category is in the array, update it
        const updatedCounts = [...categoryCounts]
        updatedCounts[index] = updatedCategory
        setCategoryCounts(updatedCounts)
      }
    }
  }
  const calculateTotalPrice = () => {
    let totalPrice = 0

    for (const categoryCount of categoryCounts) {
      const category = currentEventDetails?.tickets.find(
        cat => cat._id === categoryCount.ticketCategoryID,
      )

      if (category) {
        totalPrice += categoryCount.quantity * category.price
      }
    }

    return totalPrice
  }
  return (
    <Modal
      title='Bundle Tickets'
      centered
      {...attr}
    >
      <Form
        layout='vertical'
        onFinish={submitForm}
        id='create-bundle'
      >
        <Item
          rules={[
            validateCategories('Please choose at least one Category'),
          ]}
          className='item chooseBundleCategory'
          colon={false}
          label='Choose categories'
          name='categories'
        >
          {currentEventDetails?.tickets?.map((category, index) => {
            const categoryName = category.ticketType
            const price = category.price.toFixed(2)
            const categoryID = category._id
            const selectedQuantity =
              categoryCounts.find(c => c.ticketCategoryID === categoryID)?.quantity || 0

            return (
              <div key={category._id} className='wrapperBundleCategory'>
                <div className='wrapperCategoryBlock'>
                  <div className='categoryName'>{categoryName}</div>
                  <div className='categoryPrice'>{currencySymbol + price}</div>
                </div>
                <div className='right'>
                  <Counter
                    min={0}
                    max={category.ticketCount.available}
                    count={selectedQuantity}
                    setCount={count => handleCounterChange(categoryID, count)}
                    disabled={category.ticketCount.available === 0}
                  />
                </div>
              </div>
            )
          })}
        </Item>
        <div className='borderTop' />
        <div className='originalValueWrapper'>
          <div className='title'>Original value: </div>
          <div className='price'>{currencySymbol + calculateTotalPrice()}</div>
        </div>

        <Item
          rules={[{ required: true, message: 'Enter Bundle name' }]}
          label='Bundle name'
          className='item bundleName'
          name='bundleName'
          normalize={v => v.trimStart()}
        >
          <Input placeholder='Type here' className='input' />
        </Item>

        <Item
          name='notes'
          rules={[{ required: true, message: 'Enter Bundle note' }]}
          validateTrigger='onBlur'
          className='item bundleNote'
          label='Bundle note'
          normalize={v => v.trimStart()}
        >
          <TextArea placeholder='Type here' maxLength={256} />
        </Item>
        <div className='bundleQuantityWrapper'>
          <Item
            name='quantity'
            label='Number of bundles'
            className='item bundleQuantity'
            validateTrigger='onBlur'
            colon={false}
            rules={[
              { required: true, type: 'number', message: 'Please enter quantity' },
              integerValidator('Invalid number!'),
              maxValidator(),
              // maxValidator('ticketCount.available', 'Quantity shouldn\'t exceed tickets you have'),
            ]}
          >
            <InputNumber
              placeholder='0'
              min={1}
              className='darkBg'
            />
          </Item>
          <Item
            name='price'
            label='Price'
            className='item bundlePrice'
            validateTrigger='onBlur'
            colon={false}
            rules={[
              { required: true, type: 'number', message: 'Please enter price' },
              maxValidator(),
            ]}
          >
            <InputNumber
              placeholder='0'
              className='darkBg'
            />
          </Item>
        </div>
        <div className='bundleCustomFooter'>
          <CustomButton className='btnCancel' text='Cancel' type='white' onClick={updateVisible} htmlType='button' />
          <CustomButton className='submitBtn' text='Create' htmlType='submit' type='dark' />
        </div>
      </Form>
    </Modal>
  )
}

export default ModalCreateBundle
