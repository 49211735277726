import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { getMemberHomePublicEvent, eventsSetMemberPublicEvents, getActiveParticipations } from 'modules/memberships/memberships-actions'
import { MemberLyfeLayout, CustomButton, BannerMockMemberTicket } from 'components'
import Header from './Header'
import Body from './Body'
import './index.styl'

const limit = 20

const MemberLyfeHome = () => {
  const [loaded, setLoaded] = useState(false)
  const [loadingPagination, setLoadingPagination] = useState(true)
  const [total, setTotal] = useState(0)
  const [current, setCurrent] = useState(0)
  const [skip, setSkip] = useState(0)
  const dispatch = useDispatch()

  const handleEndFetch = () => {
    setLoaded(true)
    setLoadingPagination(false)
  }

  const handleFetch = obj => {
    dispatch(getMemberHomePublicEvent(obj))
      .then(({ total, current }) => {
        setTotal(total)
        setCurrent(current)
        setSkip(obj.skip)
        handleEndFetch()
      })
      .catch(handleEndFetch)
    dispatch(getActiveParticipations())
  }

  useEffect(() => {
    handleFetch({ limit, skip })
    window.dataLayer.push({ event: 'MemberHome' })
    return () => dispatch(eventsSetMemberPublicEvents([]))
  }, [])

  const handleFetchMore = () => {
    setLoadingPagination(true)
    handleFetch({ limit, skip: skip + limit })
  }

  // const restLength = (total - publicEvents.length)

  return (
    <MemberLyfeLayout className='wrapperHome'>
      <BannerMockMemberTicket />
      <Header loaded={loaded} />
      <div className='container'>
        <Body loaded={loaded} />
        <div className='fetchMoreMemberContainer'>
          {loaded && current < total && (
            <CustomButton
              disabled={current >= total || loadingPagination}
              loading={loadingPagination}
              onClick={handleFetchMore}
              // text={`Show More ${restLength > limit ? limit : restLength}`}
              text='Show More'
              className='homeShowMoreMember'
              type='dark'
            />
          )}
        </div>
      </div>
    </MemberLyfeLayout>
  )
}

export default MemberLyfeHome
