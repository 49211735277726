import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation, useParams, useHistory } from 'react-router-dom'
import {
  getEvents,
  getEventDetailsSummaryV2,
  getEventDetails,
  clearCurrentEvent,
  resetEventSummary,
} from 'modules/events/events-actions'
import {
  getPageDetails,
  getMembershipSummariesData,
} from 'modules/memberships/memberships-actions'
import CreateMemberLyfe from 'components/CreateMemberLyfe'

import { StatisticsBox } from 'components'
import { Spin, notification, Button } from 'antd'
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import Statistics from '../Statistics/index'
import Categories from '../Categories/index'
import Distribution from '../Distribution/index'
import ScanTickets from '../ScanTickets/index'
import PurchaseDetails from '../PurchaseDetails/index'
import MembershipRoster from '../MembershipRoster/index'
// import FinancialReports from '../FinancialReports/index'
import { getCurrencySymbolByCode, getMemberOfficeURL, isAdmin, isVenue } from 'utils'
import { ModalEmbedCode } from 'pages/MyBoxOffice/MBOComponents'
import { camelCase, isEmpty } from 'lodash'
// import QRCode from 'qrcode.react'
import { QRCode } from 'react-qrcode-logo'
import moment from 'moment'
import QRCodeLogoIcon from 'images/QRCodeLogo.svg'
import locationIcon from 'images/memberLocationIcon.svg'
import copyLinkImg from 'images/membershipCopyLink.svg'
import distributionIcon from 'images/memberDistribution.svg'
import categoriesIcon from 'images/memberCategory.svg'
import financeReport from 'images/memberFinancial.svg'
import purchaseDetails from 'images/memberPurchase.svg'
import memberRoster from 'images/memberRoster.svg'
import memberScan from 'images/memberScanHistory.svg'
import memberDate from 'images/memberDate.svg'
import EditIcon from 'images/Edit.svg'
import arrow from 'images/simpleArrow.svg'
import copyIcon from 'images/copyIcon.png'
import embedCodeIcon from 'images/memberEmbedCode.svg'
import viewQRCode from 'images/viewQRCode.svg'
import closeIconSvg from 'images/Crossmark.svg'
import c from 'classnames'
import './index.styl'

const HiddenField = ({ label, value }) => {
  const [visible, setVisible] = useState(false)
  return (
    <div className='hidden_field'>
      <div className={c('field_text', { select: !visible })}>
        {visible ? value : label}
      </div>
      <div onClick={() => setVisible(!visible)} className='toggle'>
        {visible ? <EyeOutlined /> : <EyeInvisibleOutlined />}
      </div>
    </div>
  )
}

const MemberDetails = () => {
  const { pathname, state = {} } = useLocation()
  const { push } = useHistory()
  const { eventID } = useParams()
  const containerRef = useRef(null)
  const dispatch = useDispatch()
  const [currentEvent, setCurrentEvent] = useState({})
  const [isResponsive, setIsResponsive] = useState(false)
  const {
    currentEventDetails, events,
  } = useSelector(state => state.events)
  const {
    currentMembershipDetailsSummary,
  } = useSelector(state => state.memberships)

  const { currentPageDetails } = useSelector(state => state.memberships)
  const [modalEmbedCode, setModalEmbedCode] = useState(null)
  const [isChange, setIsChange] = useState(false)
  const [selectedTicket, setSelectedTicket] = useState()
  const [tableData, setTableData] = useState([])
  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const { user } = useSelector(state => state.session)
  const ticketOwnerId = currentEventDetails.userID
  const ticketVenueUserID = currentEventDetails?.venueUserID
  const isOwner = user?._id === ticketOwnerId || user?._id === ticketVenueUserID || isAdmin(user?.role)
  useEffect(() => {
    setIsResponsive(window.innerWidth < 1200)
  }, [window.innerWidth])
  useEffect(() => {
    dispatch(getEvents())
    dispatch(getEventDetails(eventID))
    dispatch(getPageDetails(eventID))
    dispatch(getMembershipSummariesData(eventID))
    dispatch(clearCurrentEvent())
  }, [])
  useEffect(() => {
    if (isChange) {
      dispatch(getEvents())
    }
  }, [isChange])
  useEffect(() => {
    if (currentEventDetails && typeof currentEventDetails?.isPromoted === 'boolean') dispatch(getEventDetailsSummaryV2(eventID, currentEventDetails?.isPromoted))
  }, [currentEventDetails?.isPromoted])

  useEffect(() => {
    const data = events.filter(i => i._id === eventID)[0]
    setCurrentEvent(data)
  }, [events.length])

  const handleModal = () => setModalEmbedCode(`?e=${eventID}&u=${currentEventDetails?.userID}`)
  const handleCopy = () => {
    notification.success({ message: 'Code copied to clipboard success' })
    setModalEmbedCode(null)
  }

  const openPopup = () => {
    setIsPopupOpen(true)
  }

  const closePopup = () => {
    setIsPopupOpen(false)
  }

  const handleOverlayClick = e => {
    if (e.target === e.currentTarget) {
      closePopup()
    }
  }

  const handleScroll = () => containerRef.current.scrollIntoView()
  const getUrl = _state => {
    if (_state?.mainLink?.includes('member')) {
      return getMemberOfficeURL(_state.orderBy, _state.page)
    }
    return '/member-office/table-members'
  }
  const titleLink = 'Member Office'
  const headerReset = () => (
    <Link to={getUrl(state)} className='headerReset' onClick={() => dispatch(resetEventSummary())}>
      <div className='text'>
        <img src={arrow} alt='arrow' className='headerResetArrow' />
        Back to {titleLink}
      </div>
    </Link>
  )
  const goTop = (
    <div className='headerReset goBackBtn'>
      <div className='text' onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
        <img src={arrow} alt='arrow' className='headerResetArrow' />
        Back to top
      </div>
    </div>
  )
  let activeTable = false
  const activeTab = pathname.split('/')[3].replace(/[\-\/]/gi, ' ')
  if (pathname.split('/')[5] === 'rows') {
    activeTable = true
  } else if (pathname.split('/')[3] === 'member-edit') {
    activeTable = true
  }

  const locations = currentPageDetails?.locations
  const currencySymbol = getCurrencySymbolByCode(currentEventDetails?.currency)
  const objContent = {
    statistics: () => <Statistics />,
    'member categories': () => <Categories setTableData={setTableData} tableData={tableData} selectedTicket={selectedTicket} setSelectedTicket={setSelectedTicket} isChange={isChange} updateFunc={setIsChange} currencySymbol={currencySymbol} isResponsive={isResponsive} />,
    'member distribution': () => <Distribution currencySymbol={currencySymbol} />,
    'scan history': () => <ScanTickets />,
    'purchase details': () => <PurchaseDetails isChange={isChange} updateFunc={setIsChange} eventID={eventID} currencySymbol={currencySymbol} isResponsive={isResponsive} />,
    // 'financial reporting': () => <FinancialReports eventID={eventID} currencySymbol={currencySymbol} />,
    'member edit': () => <CreateMemberLyfe currentPageDetails={currentPageDetails} />,
    'membership roster': () => <MembershipRoster currentPageDetails={currentPageDetails} />,
  }
  const navigationPanel = [

    {
      name: 'Membership Categories',
      to: `/member-office/member-details/member-categories/${eventID}`,
      icon: categoriesIcon,
    },
    {
      name: 'Membership Distribution',
      to: `/member-office/member-details/member-distribution/${eventID}/public-sale-membership`,
      icon: distributionIcon,
    },
    {
      name: 'Scan History',
      to: `/member-office/member-details/scan-history/${eventID}`,
      icon: memberScan,
    },
    // {
    //  name: 'Financial Reporting',
    //  to: `/member-office/member-details/financial-reporting/${eventID}`,
    //  icon: financeReport,
    // },
    {
      name: 'Purchase Details',
      to: `/member-office/member-details/purchase-details/${eventID}`,
      icon: purchaseDetails,
    },
    {
      name: 'Membership Roster',
      to: `/member-office/member-details/membership-roster/${eventID}`,
      icon: memberRoster,
    },
  ]

  const navigatePanel = !isOwner ? navigationPanel.filter(item => item.icon !== financeReport) : navigationPanel
  const currentPromoter = isAdmin(user?.role) || isVenue(user?.role) ? currentEventDetails?.userID || currentEvent?.userID : user?._id
  const isVanity = currentEventDetails?.eventUrl || currentEvent?.eventUrl
  const eventLink = isVanity
    ? `${window.location.origin}/memberPage/${isVanity}`
    : `${window.location.origin}/memberPage/${currentEventDetails?._id || currentEvent?._id}/${currentPromoter}`

  const vanityLink = (classNames = '') => (
    <a href={eventLink} rel='noopener noreferrer' target='_blank' className={classNames}>{eventLink}</a>
  )

  const linkComponent = <HiddenField label='Membership Link' value={vanityLink()} />
  const checkVisibleForAdmin = () => isAdmin(user?.role) && state?.mainLink === '/my-back-office'

  const statistics = {
    ...currentMembershipDetailsSummary,
  }
  const modalQRCodeSize = window.innerWidth <= 500 ? 250 : 400
  const creationDate = moment(currentEventDetails?.startDate).format('MMMM D, YYYY')
  return (
    <div className='mainLayout'>
      <div id='top' />
      <div className={c('selectedMemberPage', { wrapperTable: activeTable, [camelCase(activeTab)]: !!activeTab })}>
        {!activeTable && (
          <div className='memberPageInfoWrap'>
            <div className='memberPageInfo'>
              <div className='headerMemberBlockOffice'>
                {headerReset()}
                {(currentEventDetails && !currentEventDetails.isEventBlocked || checkVisibleForAdmin()) && (
                  <div onClick={() => push(`/member-office/member-details/member-edit/${eventID}`)} className='editButtonWrapper'>
                    <img className='editIcon' src={EditIcon} alt='edit icon' />
                    <span className='editText'>Edit</span>
                  </div>
                )}
              </div>
              <div className='eventNameDetails'>
                <div className='eventDetailsEventName'>{currentPageDetails?.name}</div>
              </div>
              <div className='imageWrap'>
                {currentPageDetails?.images?.thumbnail1
                  ? (
                    <img
                      src={currentPageDetails?.images?.thumbnail1}
                      alt='cardDetail'
                      className='eventImage'
                    />)
                  : <div className='eventImageLoading'><Spin spinning={currentPageDetails?.images?.thumbnail1} /></div>}
              </div>
              <div className='eventDescription'>
                <Button
                  className='btnTypeWhite codeGenButton'
                  onClick={handleModal}
                  disabled={!eventID || isEmpty(currentPageDetails)}
                >
                  <div className='customButtonWrapper'>
                    <img src={embedCodeIcon} alt='embed code' />
                    Generate Embed Code
                  </div>
                </Button>
                <div className='eventDescriptionItemLocation startAligned'>
                  {locations?.map((location, index) => (
                    <div key={index}>
                      <img src={locationIcon} alt='flagMap' />
                      <a
                        target='_blank'
                        rel='noopener noreferrer'
                        className='memberLocation'
                        href={`https://www.google.com/maps/search/?api=1&query=${location}`}
                      >
                        {location}
                      </a>
                    </div>
                  ))}
                </div>
                <div className='eventDescriptionItem startAligned'>
                  <img src={memberDate} alt='memberDate' />
                  <div className='memberDate'>Created at {creationDate}</div>
                </div>
                <div className='eventDescriptionItem eventLink'>
                  <img src={copyLinkImg} alt='link icon' className='linkIcon' />
                  {linkComponent}
                  <CopyToClipboard text={eventLink} onCopy={() => notification.success({ message: 'Link copied to clipboard success' })}>
                    <img src={copyIcon} alt='copy' className='copyImg' />
                  </CopyToClipboard>
                  <div>
                    <img
                      className='qrCodeImg'
                      onClick={openPopup}
                      src={viewQRCode}
                      alt='QR Code'
                    />
                    {isPopupOpen &&
                      <div className='modal' onClick={handleOverlayClick}>
                        <div className='modal-content'>
                          <img className='closeQrCodeIcon' onClick={closePopup} src={closeIconSvg} alt='Close' />
                          <QRCode
                            removeQrCodeBehindLogo logoPadding={10}
                            size={modalQRCodeSize} logoImage={QRCodeLogoIcon} value={eventLink}
                          />
                        </div>
                      </div>}
                  </div>
                </div>
              </div>
              <div className='eventNavigation'>
                {navigatePanel.map(i => {
                  const activeNavTab = i.to.split('/')[3].replace(/[\-\/]/gi, ' ')
                  return (
                    <Link
                      className={c('navItem', { activeNavItem: activeNavTab.includes(activeTab) })}
                      to={{ pathname: i.to, state }}
                      onClick={handleScroll}
                      key={i.name}
                    >
                      <div className='flex'>
                        <div className='navIcons'>
                          <img src={i.icon} alt='icon' />
                        </div>
                        <div className='text'>{i.name}</div>
                      </div>
                      <img src={arrow} alt='arrow' />
                    </Link>
                  )
                })}
              </div>
            </div>
          </div>
        )}

        {!activeTable && <StatisticsBox statistics={statistics} currencySymbol={currencySymbol} isML />}
        {activeTab !== 'members edit' && goTop}
        <div className=''>
          <div ref={containerRef} />
          {objContent[activeTab]()}
        </div>
      </div>
      {modalEmbedCode && (
        <ModalEmbedCode
          eventID={modalEmbedCode}
          onClose={() => setModalEmbedCode(null)}
          onCopy={handleCopy}
        />
      )}
    </div>
  )
}

export default MemberDetails
