import api from './axios.v2'
import adminapi from './axios.v2.withoutTransform'

const getAllEvents = ({ skip = 0, limit = 10, search = '', sort, statuses, other }) => {
  let apiUrl = `backOffice/events?limit=${limit}&skip=${skip}`
  if (search) {
    apiUrl += `&search=${search?.toLowerCase()}`
  }
  if (sort) {
    apiUrl += `&sort=${sort}`
  }
  if (statuses) {
    apiUrl += `&statuses=${statuses}`
  }
  if (other) {
    apiUrl += `&other=${other}`
  }
  return api(apiUrl, 'GET')
}

const getListUsers = ({ skip = 0, limit = 10, search = '', sort, statuses, other }) => {
  let apiUrl = `backOffice/users?limit=${limit}&skip=${skip}`
  if (search) {
    apiUrl += `&search=${search?.toLowerCase()}`
  }
  if (sort) {
    apiUrl += `&sort=${sort}`
  }
  if (statuses) {
    apiUrl += `&statuses=${statuses}`
  }
  if (other) {
    apiUrl += `&other=${other}`
  }

  return api(apiUrl, 'GET')
}
const getUsersCsv = ({ search = '', sort, statuses, other }) => {
  let apiUrl = 'backOffice/users/csv'
  const params = []
  if (search) {
    params.push(`search=${search?.toLowerCase()}`)
  }
  if (sort) {
    params.push(`sort=${sort}`)
  }
  if (statuses) {
    params.push(`statuses=${statuses}`)
  }
  if (other) {
    params.push(`&other=${other}`)
  }

  const strParams = params.join('&')

  if (strParams) {
    apiUrl += `?${strParams}`
  }

  return api(apiUrl, 'GET')
}
const getEventV2 = (id, userId) =>
  api(
    `events/getPublicEventDetails?eventID=${id}&userID=${userId}`,
    'GET',
    {},
    false,
  )
const getMyWalletTickets = (type = 'ACTIVE') =>
  adminapi(`user/wallet?eventType=${type}&limit=200&skip=0`, 'GET')
const reserveSeats = obj => {
  return adminapi('events/reserveSeat', 'POST', obj)
}
const dereserveSeats = obj => {
  return adminapi('events/dereserveSeat', 'POST', obj)
}
const guestReserveSeats = obj => {
  return adminapi('events/guestReserveSeat', 'POST', obj)
}
const guestDereserveSeats = obj => {
  return adminapi('events/guestDereserveSeat', 'POST', obj)
}
const getBankAccountEvent = id => {
  const apiUrl = `backOffice/events/${id}/bankAccount`
  return api(apiUrl, 'GET')
}

const getDistributedTickets = (eventID, ticketID, limit = 10, skip = 0, search) =>
  adminapi(`boxOffice/getDistributedTickets?eventID=${eventID}&ticketID=${ticketID}&limit=${limit}&skip=${skip}` + `${search ? `&search=${search}` : ''}`, 'GET')

const getInvites = (categoryID, type, limit = 10, skip = 0, search) => {
  let idToUse
  if (categoryID) {
    idToUse = `ticketID=${categoryID}`
  } else {
    throw new Error('Neither ticketID nor bundleID provided')
  }

  return adminapi(`boxOffice/getInvites?inviteType=${type}&${idToUse}&limit=${limit}&skip=${skip}` + `${search ? `&search=${search}` : ''}`, 'GET')
}

const getTransactionList = (eventID, limit = 10, skip = 0, search = '') =>
  adminapi(`boxOffice/getTransactionList?eventID=${eventID}&limit=${limit}&skip=${skip}` + `${search ? `&search=${search}` : ''}`, 'GET')

const cashChekSaleInvite = obj => adminapi('boxOffice/invite', 'POST', obj)

const deleteInvite = obj => adminapi('boxOffice/invite', 'DELETE', obj)

const adminPayout = (obj, id) => adminapi(`backOffice/events/${id}/payout`, 'POST', obj)
const createTemplate = obj => {
  return adminapi('seats/templates', 'POST', obj)
}
const getEventDetailsSummaryV2 = (eventID, isSubpromotion = false) => adminapi(`boxOffice/getEventSummaries?eventID=${eventID}&isSubpromotion=${isSubpromotion}`, 'GET')
const getTemplates = ({ search = '', limit = 0, skip = 0, eventID = '' }) => {
  const searchParam = search ? `&search=${search}` : ''
  const eventIDParam = eventID ? `&eventID=${eventID}` : ''
  return adminapi(`seats/templates?limit=${limit}&skip=${skip}${searchParam}${eventIDParam}`, 'GET')
}
const getTemplate = id => {
  return adminapi(`seats/templates/${id}`, 'GET')
}

const getTemplateCategories = id => {
  return adminapi(`seats/templates/${id}/categories`, 'GET')
}
const deleteTemplate = id => {
  return adminapi(`seats/templates/${id}`, 'DELETE')
}
const renameTemplate = (id, obj) => {
  return adminapi(`seats/templates/${id}/rename`, 'PUT', obj)
}

const unassignTicketsFromSection = obj => {
  return adminapi(`seats/assignTables/${obj.eventID}/unassignAll/${obj.sectionID}`, 'PUT', obj)
}

const deleteAssignTicketSection = obj => {
  return adminapi(`seats/templates/${obj.templateID}/sections`, 'DELETE', { id: obj.sectionID })
}

const deleteEvent = eventID => {
  return api(`backOffice/events/${eventID}/delete`, 'PATCH')
}

const blockEvent = eventID => {
  return api(`backOffice/events/${eventID}/block`, 'PATCH')
}

const cancelEvent = eventID => {
  return api(`backOffice/events/${eventID}/cancel`, 'PATCH')
}

const activateEvent = eventID => {
  return api(`backOffice/events/${eventID}/activate`, 'PATCH')
}

const getCsv = () => {
  return api('backOffice/events/csv?limit=10000', 'GET')
}

const getStreamsCsv = () => {
  return api('backOffice/events/eventLiveCsv?limit=10000', 'GET')
}

const getScannedTickets = ({ eventID, limit = 100000, skip = 0, search = '' }) => {
  let apiUrl = `boxOffice/events/${eventID}/scannedTickets?limit=${limit}&skip=${skip}`
  if (search) {
    apiUrl += `&search=${search}`
  }
  return api(apiUrl, 'GET')
}

const getTicketsByOrderV2 = orderID => api(`purchase/order/${orderID}`, 'GET', {}, false)
const regiftFromOrderV2 = (orderID, data) => {
  return adminapi(`purchase/order/${orderID}/regift-ticket`, 'POST', data, false)
}
const regiftFromWalletV2 = data => {
  return adminapi('purchase/wallet/regift-ticket', 'POST', data, true)
}

const getPublicEvent = obj => {
  let apiUrl = 'events/list'
  if (Object.keys(obj).length) {
    apiUrl += '?v=1'
  }
  if (obj.limit) {
    apiUrl += `&limit=${obj.limit}`
  }
  if (obj.skip) {
    apiUrl += `&skip=${obj.skip}`
  }
  if (obj.eventName) {
    const search = encodeURIComponent(obj.eventName)
    apiUrl += `&search=${search}`
  }
  return api(apiUrl, 'GET', {}, false)
}
const getPublicVenueEvent = obj => {
  let apiUrl = `events/venueList?venue=${obj?.venue}`
  if (obj.limit) {
    apiUrl += `&limit=${obj.limit}`
  }
  if (obj.skip) {
    apiUrl += `&skip=${obj.skip}`
  }
  if (obj.eventName) {
    apiUrl += `&search=${obj.eventName}`
  }
  return api(apiUrl, 'GET')
}

const getScanCategories = eventID => {
  return adminapi(`events/scanCategories/${eventID}`, 'GET')
}

const removeTicketCategory = obj => {
  return adminapi('boxOffice/ticketCategory', 'DELETE', obj)
}
const addTicketCategory = obj => {
  return adminapi('boxOffice/ticketCategory', 'POST', obj)
}
const addEvent = obj => {
  return adminapi('boxOffice/events/addEvent', 'POST', obj)
}
const editEvent = obj => {
  return adminapi('boxOffice/events/editEvent', 'PUT', obj)
}
const addVenueEvent = obj => {
  return adminapi('boxOffice/events/addVenueEvent', 'POST', obj)
}
const editDraftEvent = obj => {
  return adminapi('boxOffice/events/editVenueEvent', 'PUT', obj)
}

const getAssignTicketSection = eventId => {
  return api(`seats/assignTables/${eventId}`, 'GET')
}
const getAssignTicketSectionDetails = (eventID, sectionID) => {
  return api(`seats/assignTables/${eventID}?sectionID=${sectionID}`, 'GET')
}

const getSectionTables = (eventID, sectionID) => api(`seats/assignTables/${eventID}/getTables/${sectionID}`, 'GET')

const getUnassignedTicketList = eventID => api(`seats/assignTables/${eventID}/getUnassignedTicketList`, 'GET')

const assignTicketsToTable = obj => adminapi(`seats/assignTables/${obj.eventID}/assignTicketsToTable`, 'PUT', obj)

const renameSectionTables = (eventID, sectionID, obj) => adminapi(`seats/assignTables/${eventID}/renameSection/${sectionID}`, 'PUT', obj)

const addSectionTables = (eventID, obj) => adminapi(`seats/assignTables/${eventID}/addTable`, 'POST', obj)

const unassignTicketsFromTable = obj => adminapi(`seats/assignTables/${obj.eventID}/unassignTicketsFromTable`, 'PUT', obj)

const getBalanceLedgers = (eventID, search) => {
  let apiUrl = `backOffice/events/${eventID}/balances`
  if (search) {
    apiUrl += `?search=${search}`
  }
  return api(apiUrl, 'GET')
}

const randomDrawSubmitPopup = (eventID, obj) => adminapi(`boxOffice/${eventID}/randomDraw`, 'POST', obj)
const randomDrawNextWinner = (eventID, randomDrawID) => api(`boxOffice/${eventID}/pickNextWinner/${randomDrawID}`, 'POST')
const getRandomDrawCategories = eventID => api(`boxOffice/${eventID}/randomDraw`, 'GET')
const getRandomDrawList = eventID => api(`boxOffice/${eventID}/RandomDrawList`, 'GET')

const toggleEventScannerV2 = (eventID, obj) => adminapi(`boxOffice/${eventID}/ActivateScanners`, 'PUT', obj)

const getBundleList = (eventID, limit = 3, skip = 0, search) => adminapi(`boxOffice/${eventID}/bundles?limit=${limit}&skip=${skip}` + `${search ? `&search=${search}` : ''}`, 'GET')
const createBundle = (eventID, obj) => adminapi(`boxOffice/${eventID}/bundles`, 'POST', obj)
const editBundle = (eventID, obj) => adminapi(`boxOffice/${eventID}/bundles`, 'PUT', obj)
const deleteBundle = (eventID, obj) => adminapi(`boxOffice/${eventID}/bundles`, 'DELETE', obj)

const getEventDetails = (eventID, limit = null, skip = null, search = null) => api(`boxOffice/events/${eventID}`)

const getFeeV2 = (eventID, type, ticketsArray, currency = 'USD', paymentSystem) => api(`purchase/fees?payerType=${type}&eventID=${eventID}&tickets=${ticketsArray}&currency=${currency}&paymentType=${paymentSystem}`, 'GET', {}, false)

const verifyUser = obj => adminapi('backOffice/users/userVerificationByAdmin', 'PUT', obj)

const inviteTicket = obj => adminapi('purchase/accept-invite', 'POST', obj)
const setInviteTicketGuest = obj => adminapi('purchase/accept-invite-guest', 'POST', obj, false)
const getInviteTicket = inviteID => adminapi(`purchase/guest-invite-details?inviteID=${inviteID}`, 'GET', {}, false)

const sendPromoterEmail = obj => adminapi('events/sendPromoterEmail', 'POST', obj, false)

const makeEventV2 = obj => {
  return adminapi('events/event', 'POST', obj)
}
const editEventV2 = (obj, eventID) => {
  return adminapi(`events/event/${eventID}`, 'PUT', obj)
}
const getEventDetailsV2 = (eventID, limit = null, skip = null, search = null) => api(`events/event/${eventID}`)

const scanTicketV2 = obj => adminapi('events/scanTicket', 'POST', obj)

export default {
  getAllEvents,
  deleteEvent,
  blockEvent,
  cancelEvent,
  activateEvent,
  getCsv,
  getStreamsCsv,
  getBankAccountEvent,
  getScannedTickets,
  adminPayout,
  createTemplate,
  getTemplates,
  deleteTemplate,
  renameTemplate,
  getTemplate,
  getPublicEvent,
  getEventV2,
  reserveSeats,
  dereserveSeats,
  guestReserveSeats,
  guestDereserveSeats,
  removeTicketCategory,
  addTicketCategory,
  addEvent,
  editEvent,
  addVenueEvent,
  editDraftEvent,
  getTemplateCategories,
  getEventDetailsSummaryV2,
  getPublicVenueEvent,
  getInvites,
  cashChekSaleInvite,
  getDistributedTickets,
  getTransactionList,
  getAssignTicketSection,
  getAssignTicketSectionDetails,
  addSectionTables,
  getBalanceLedgers,
  renameSectionTables,
  getSectionTables,
  getUnassignedTicketList,
  assignTicketsToTable,
  unassignTicketsFromTable,
  unassignTicketsFromSection,
  deleteAssignTicketSection,
  deleteInvite,
  randomDrawSubmitPopup,
  randomDrawNextWinner,
  getRandomDrawCategories,
  getRandomDrawList,
  toggleEventScannerV2,
  getListUsers,
  getUsersCsv,
  createBundle,
  getBundleList,
  editBundle,
  deleteBundle,
  getEventDetails,
  getFeeV2,
  verifyUser,
  inviteTicket,
  setInviteTicketGuest,
  getInviteTicket,
  sendPromoterEmail,
  getTicketsByOrderV2,
  makeEventV2,
  editEventV2,
  getEventDetailsV2,
  regiftFromOrderV2,
  getScanCategories,
  scanTicketV2,
  regiftFromWalletV2,
  getMyWalletTickets,
}
