import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import { getMyWalletTickets, getInviteTicket } from 'modules/events/events-actions'
import { Tooltip } from 'antd'
import NoEventsInformation from 'images/NoEventsInformation.svg'
import { MemberLyfeLayout, Layout } from 'components'
import Header from './Header'
import Body from './Body'
import './index.styl'

const MyWallet = () => {
  const [valueSearch, setValueSearch] = useState('')
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState('')
  const dispatch = useDispatch()
  const { inviteID } = useParams()
  const location = useLocation()

  const onChangeSearch = e => setValueSearch(e.target.value)

  const attr = { valueSearch, onChangeSearch, loading, setLoading }

  useEffect(() => {
    const loadTickets = !inviteID ? dispatch(getMyWalletTickets()) : dispatch(getInviteTicket(inviteID))
    loadTickets.then(() => setLoading(false)).catch(error => {
      setError(error?.response?.data?.message || error?.response?.data?.error)
    })
  }, [])
  const LayoutComponent = location.pathname.includes('member') ? MemberLyfeLayout : Layout

  return (
    <LayoutComponent className='wrapperMyMemberWallet'>
      {!location.pathname.includes('guest') && <Header location={location} {...attr} />}
      {error
        ? (
          <div className='wrapperBodyEmpty'>
            <Tooltip>
              <img src={NoEventsInformation} alt='Information Logo' />
            </Tooltip>
            <div className='informationDescription'>{error}</div>
          </div>
        ) : (<Body {...attr} />)}
    </LayoutComponent>
  )
}

export default MyWallet
