import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Route, Switch, Redirect } from 'react-router-dom'
import { authorized, unauthorized, global } from 'modules/router/routes'
import { accessTokenLogin, logout } from 'modules/session/session-actions'
import * as Sentry from '@sentry/react'
import { Router } from 'react-router'
import { history } from 'router'

import { Auth } from 'pages'

const App = () => {
  const dispatch = useDispatch()
  const { user } = useSelector(state => state.session)
  const getComponent = (Component, condition, redirect) => condition ? Component : <Redirect to={redirect} />
  const getRoute = route => (
    <Route
      component={route.redirectTo ? getComponent(route.component, !!user, route.redirectTo) : route.component}
      path={route.path}
      key={route.path}
      exact
    />
  )
  useEffect(() => {
    if (user && window.location.pathname.includes('/auth/confirmation-email/')) {
      dispatch(logout())
    }
  }, [user])
  useEffect(() => {
    if (user && history.location.pathname === '/') {
      history.push('/home')
    }
  }, [user])
  const renderContent = routes => (
    <>
      <Switch>
        {!user &&
          <Route
            component={() => <Redirect to='/auth/sign-in?redirectTo=/my-wallet' />}
            path='/my-wallet'
            exact
          />}
        {!user &&
          <Route
            component={() => <Redirect to='/auth/sign-in?redirectTo=/my-member-wallet' />}
            path='/my-member-wallet'
            exact
          />}
        {/* {!user && window.location.search === '?activeTab=ACTIVE&orderBy=DATE_DESC&page=1' &&
          <Route
            component={() => <Redirect to='/auth/sign-in?redirectTo=/my-box-office/table-events?activeTab=ACTIVE&orderBy=DATE_DESC&page1' />}
            path='/my-box-office/table-events'
            exact
            key='active'
          />} */}
        {/* {!user && window.location.search === '?activeTab=SUBPROMOTIONS&orderBy=DATE_DESC&page=1' &&
          <Route
            component={() => <Redirect to='/auth/sign-in?redirectTo=/my-box-office/table-events?activeTab=SUBPROMOTIONS&orderBy=DATE_DESC&page=1' />}
            path='/my-box-office/table-events'
            exact
            key='subpromotions'
          />} */}
        {/* {!user && window.location.search === '?activeTab=ALL&orderBy=DATE_DESC&page=1' &&
          <Route
            component={() => <Redirect to='/auth/sign-in?redirectTo=/my-box-office/table-events?activeTab=ALL&orderBy=DATE_DESC&page=1' />}
            path='/my-box-office/table-events'
            exact
            key='all'
          />} */}
        {/* {!user && window.location.search === '?activeTab=ARCHIVE&orderBy=DATE_DESC&page=1' &&
          <Route
            component={() => <Redirect to='/auth/sign-in?redirectTo=?activeTab=ARCHIVE&orderBy=DATE_DESC&page=1' />}
            path='/my-box-office/table-events'
            exact
            key='past'
          />} */}
        {/* {!user && window.location.search === '?activeTab=ON_DEMAND&orderBy=DATE_DESC&page=1' &&
          <Route
            component={() => <Redirect to='/auth/sign-in?redirectTo=/my-box-office/table-events?activeTab=ON_DEMAND&orderBy=DATE_DESC&page=1' />}
            path='/my-box-office/table-events'
            exact
            key='onDemand'
          />} */}
        <Route
          component={props => !user && <Auth {...props} />}
          path='/auth/sign-in'
          exact
        />
        {routes.map(route => getRoute(route))}
        {global.map(route => getRoute(route))}
        <Route component={() => <Redirect to={user ? '/home' : '/'} />} />
      </Switch>
    </>
  )
  useEffect(() => {
    if (user) {
      dispatch(accessTokenLogin({ deviceType: 'WEB' }))
      Sentry.setUser({ email: user.email })
    }
  }, [])

  return (
    <Router history={history}>
      <Switch>
        <Route path='/'>
          {renderContent(user ? authorized : unauthorized)}
        </Route>
      </Switch>
    </Router>
  )
}

export default App
