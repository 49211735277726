/* eslint-disable indent */
import React from 'react'
import './index.styl'
import { useDispatch } from 'react-redux'
import { clearGetEventDetails } from 'modules/events/events-actions'
import { Link } from 'react-router-dom'
import defaultLogo from 'images/logoMemberLyfe.svg'

const LogoMemberLyfe = ({ user }) => {
  const dispatch = useDispatch()
  return (
    <Link
      to={user ? '/memberlyfe-home' : '/help/overview/my-box-office'}
      onClick={() => {
        dispatch(clearGetEventDetails({}))
        window.scroll(0, 0)
      }}
    >
      <img src={defaultLogo} alt='logo' className='logoMember' />
    </Link>
  )
}

export default LogoMemberLyfe
