import React, { useEffect, useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Layout, CustomButton, ResponsiveCards } from 'components'
import { isAdmin, useQuery, getUserListingURL, notify, isVenue, handleConfirmModal } from 'utils'
import { Pagination, Skeleton, Spin, Table } from 'antd'
import c from 'classnames'
import { eventsV2 } from 'apiv2'

import NoEventsInformation from 'images/NoEventsInformation.svg'
import ULControlPanel from './ULControlPanel'
import moment from 'moment'
import './index.styl'

const msgError = error => {
  notify(error?.response?.data?.error || error?.response?.data?.message || error?.message)
}
const getOffset = ({ current, limit }) => current * limit - limit
const arrayToString = arr => arr.filter(Boolean).join(',')
const getParams = ({ current, limit, search, sort, statuses = [], other = [] }) => {
  return { skip: getOffset({ current, limit }), statuses, other, search, limit, sort }
}
const pageSize = 13
const responsivePageSize = 2
const defaultStatuses = ['venue', 'promoter', 'promoterPartner']

const UsersBackOffice = () => {
  const { user } = useSelector(state => state?.session)
  const [isResponsive, setIsResponsive] = useState(false)
  const query = useQuery()
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [userList, setUserList] = useState([])
  const [current, setCurrent] = useState(0)
  const [total, setTotal] = useState(0)
  const [search, setSearch] = useState(query.get('search') || '')
  const [sort, setSort] = useState(query.get('sort') || 'DATE_DESC')
  const [statusesActive, setStatusesActive] = useState(query.get('filter') ? query.get('filter').split(',') : defaultStatuses)
  const [statusesVerified, setStatusesVerified] = useState(query.get('other') ? query.get('other').split(',') : [])
  const { push } = useHistory()
  const containerRef = useRef(null)
  useEffect(() => {
    window.dataLayer.push({ event: 'User Listing' })
    if (!!user.role && !isAdmin(user.role) && !isVenue(user.role)) {
      push('/')
    }
  }, [user])
  let requestDelay = 0
  const onChangeSearch = e => {
    const statuses = arrayToString(statusesActive)
    const other = arrayToString(statusesVerified)
    if (+query.get('page') !== 1) {
      history.push(getUserListingURL({ sort, current, statuses, search, other }))
    }
    const searchValue = e.target?.value
    if (requestDelay) clearTimeout(requestDelay)
    requestDelay = setTimeout(() => {
      setSearch(searchValue)
    }, 700)
  }
  const fetchData = () => {
    setLoading(true)
    const statuses = arrayToString(statusesActive)
    const other = arrayToString(statusesVerified)
    const params = { current, limit: pageSize, search, sort, statuses, other }
    history.push(getUserListingURL({ sort, current, statuses, search, other }))
    eventsV2
      .getListUsers(getParams(params))
      .then(({ data }) => {
        setUserList(data?.users)
        setTotal(data?.meta?.total)
        containerRef.current.scrollIntoView(true)
      })
      .catch(msgError)
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    if (query.get('page') && +query.get('page') !== current) {
      setCurrent(+query.get('page'))
    }
    if (!!query.get('sort') && query.get('sort') !== sort) {
      setSort(query.get('sort'))
    }
    if (!!query.get('search') && query.get('search') !== search) {
      setSearch(query.get('search'))
    }
    if (!!query.get('filter') && query.get('filter') !== arrayToString(statusesActive)) {
      setStatusesActive(query.get('filter').split(','))
    }
    if (!!query.get('other') && query.get('other') !== arrayToString(statusesVerified)) {
      setStatusesVerified(query.get('other').split(','))
    }
  }, [history.location.search])

  useEffect(() => {
    current && fetchData()
  }, [current])

  useEffect(() => {
    if (current !== 1) {
      setCurrent(!current ? +query.get('page') || 1 : 1)
    } else {
      fetchData()
    }
  }, [search, sort, statusesActive, statusesVerified])

  useEffect(() => {
    setIsResponsive(window.innerWidth < 1025)
  }, [window.innerWidth])

  const handlePagination = current => setCurrent(current)

  const formattedDate = data => {
    return data.map(item => {
      if (item.createdAt) {
        item.createdAt = moment(item.createdAt).format('MMM D, YYYY')
      }
      return item
    })
  }

  const handleVerifyUser = userID => {
    handleConfirmModal(
      'Are you sure you want to verify user?',
      () =>
        eventsV2.verifyUser({ userID }).then(() => {
          fetchData()
        }),
    )
  }

  const columnsListing = [
    {
      title: 'User name',
      dataIndex: 'fullName',
      key: 'fullName',
      width: '65%',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: '60%',
    },
    {
      title: 'Phone number',
      dataIndex: 'fullMobile',
      key: 'fullMobile',
      width: '50%',
    },
    {
      title: 'User type',
      dataIndex: 'typeUser',
      key: 'typeUser',
      width: '35%',
    },
    {
      title: 'User status',
      dataIndex: 'status',
      key: 'status',
      width: '30%',
      render: (item, record) => (
        <>
          <div className={c('userListingStatus',
            { isVerified: record.isEmailVerified },
            { isNotVerified: !record.isEmailVerified })}
          >{record.isEmailVerified ? 'Verified' : 'Unverified'}
          </div>
          {!record.isEmailVerified && (
            <button className='verifiedButton' onClick={() => handleVerifyUser(record.userID)}>
              Verify User
            </button>
          )}
        </>
      ),
    },
    {
      title: 'Creation date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: '30%',
    },
  ]

  const prepareCardData = data => [
    {
      title: 'User name',
      text: data?.fullName,
    },
    {
      title: 'Email',
      text: data?.email,
    },
    {
      title: 'Phone number',
      text: data?.fullMobile,
    },
    {
      title: 'User type',
      text: data?.typeUser,
    },
    {
      title: 'User status',
      text:
  <>
    <div className={c('userListingStatus',
      { isVerified: data.isEmailVerified },
      { isNotVerified: !data.isEmailVerified })}
    >{data.isEmailVerified ? 'Verified' : 'Unverified'}
    </div>
    {!data.isEmailVerified && (
      <button className='verifiedButton' onClick={() => handleVerifyUser(data.userID)}>
        Verify User
      </button>
    )}
  </>,
    },
    {
      title: 'Creation date',
      text: data?.createdAt ? moment(data.createdAt).format('MMM D, YYYY') : '',
    },
  ]

  const renderEmpty = (
    <div className='wrapperBodyEmpty'>
      <img src={NoEventsInformation} alt='Information Logo' />
      <div className='informationDescription'>No Users</div>
    </div>
  )

  const getCSV = async () => {
    const statuses = arrayToString(statusesActive)
    const other = arrayToString(statusesVerified)
    const result = await eventsV2.getUsersCsv({ sort, statuses, other, search })
    if (result?.status === 200) {
      window.open(result?.data?.urlToReport, '_blank')
    }
  }

  return (
    <Layout>
      <div className='container myBackOffice'>
        <div style={{ display: 'flex', justifyContent: 'space-between' }} className='myBackOfficeTitle' ref={containerRef}>
          <div>
            Users List
          </div>
          <div>
            <CustomButton text='Export' type='dark' onClick={() => getCSV()} />
          </div>
        </div>
        <ULControlPanel
          setStatusesActive={setStatusesActive}
          setStatuses={setStatusesVerified}
          statusesActive={statusesActive}
          statuses={statusesVerified}
          setSearch={setSearch}
          onChangeSearch={onChangeSearch}
          loading={loading}
          setSort={setSort}
          search={search}
          sort={sort}
        />
        <Spin wrapperClassName='eventsListWrapper' spinning={loading}>
          <Skeleton loading={loading && !userList.length} active paragraph={{ rows: 10 }}>
            {userList.length && !isResponsive ? (

              <>
                <Table
                  pagination={false}
                  dataSource={formattedDate(userList)}
                  className='userListingTable custom-table'
                  columns={columnsListing}
                  rowKey='id'
                  sticky
                />
                {total && total > 6 && (
                  <Pagination
                    onChange={handlePagination}
                    showSizeChanger={false}
                    className='pagination'
                    disabled={loading}
                    current={current}
                    pageSize={pageSize}
                    total={total}
                  />
                )}
              </>
            ) : userList.length && isResponsive ? (
              <>
                {userList.map(data => (
                  <ResponsiveCards key={data?.id} data={prepareCardData(data)} />
                ))}
                <Pagination
                  current={current}
                  pageSize={responsivePageSize}
                  total={total}
                  showSizeChanger={false}
                  className='pagination'
                  onChange={handlePagination}
                />
              </>
            ) : renderEmpty}
          </Skeleton>
        </Spin>
      </div>
      {/* {modalAction && (
        <MBModalConfirmAction
          onCancel={() => setModalAction(null)}
          modalData={modalAction}
          loading={loading}
        />
      )}
      {showBankDetails && showBankDetailsModal && (
        <MBModalBankDetails
          onCancel={handleCancelModal}
          showBankDetails={showBankDetails}
          loading={loading}
          // fetchData={fetchData}
        />
      )} */}
    </Layout>
  )
}

export default UsersBackOffice
