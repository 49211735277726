import React from 'react'
import { Avatar } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import moment from 'moment'
import './index.styl'
import { useParams } from 'react-router'

export default ({ messageInfo }) => {
  const { date, userName, message, userID, type, avatar } = messageInfo
  const { userId } = useParams()
  const classes = ['messageChat']
  const isMessageOwner = userID === userId
  const timeFormatter = t => moment(t).format('HH:mm')
  if (isMessageOwner) classes.push('admin')

  const avatarProps = avatar
    ? { src: avatar?.thumbnail1 }
    : { icon: <UserOutlined />, style: { backgroundColor: '#62677E' } }
  return (
    <div className={classes.join(' ')}>
      {isMessageOwner && type !== 'connected'
        ? (
          <>
            <Avatar
              {...avatarProps}
              className='avatar'
              shape='circle'
              size={40}
            />
            <div className='messageWrapper'>
              <div className='nickname'>{userName}</div>
              <div className='message-body'>{message}</div>
              <small className='time'>{timeFormatter(date)}</small>
            </div>
          </>
        )
        : type === 'disconnected'
          ? (
            <div className='disconnectTimeWrapper'>
              <div className='disconnectWrapper'>
                <div className='title'>{userName} left the chat</div>
              </div>
              <small className='time'>{timeFormatter(date)}</small>
            </div>
          )
          : type === 'connected'
            ? (
              <div className='disconnectTimeWrapper'>
                <div className='disconnectWrapper'>
                  <div className='title'>{userName} joined the chat</div>
                </div>
                <small className='time'>{timeFormatter(date)}</small>
              </div>
            )
            : type === 'message' && (
              <>
                <Avatar
                  {...avatarProps}
                  className='avatar'
                  shape='circle'
                  size={40}
                />
                <div className='messageWrapper'>
                  <div className='nickname'>{userName}</div>
                  <div className='message-body'>{message}</div>
                  <small className='time'>{timeFormatter(date)}</small>
                </div>
              </>
            )}
    </div>
  )
}
