import React, { useState, useEffect } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { FormLogIn } from 'components'
import { SignUp, ResetPassword, ConfirmationPassword, ConfirmationEmail, ConfirmationResetPassword, StripeRedirect, VenueSignUp } from './Pages'
import memberLogo from 'images/logoMemberLyfe.svg'
import defaultLogo from 'images/brand-header.svg'

import { useQuery } from 'utils'
import './index.styl'
import ChooseAccount from './Pages/ChooseAccount'

const Auth = () => {
  const [redirectTo, setRedirectTo] = useState('/home')
  const { pathname } = useLocation()
  const history = useHistory()
  const query = useQuery()
  const activeTab = pathname.split('/')[2].replace(/[\-\/]/gi, ' ')

  useEffect(() => {
    const _redirectTo = query.get('redirectTo')
    if (_redirectTo) {
      setRedirectTo(_redirectTo)
      history.push(pathname)
    }
  }, [query.get('redirectTo')])

  const objContent = {
    'choose account': () => <ChooseAccount />,
    'venue sign up': () => <VenueSignUp />,
    'sign in': () => (
      <div className='wrapperSignIn'>
        <FormLogIn title='Log In' redirectTo={redirectTo} />
      </div>
    ),
    'sign up': () => <SignUp />,
    'reset password': () => <ResetPassword />,
    'confirmation password': () => <ConfirmationPassword />,
    'confirmation email': () => <ConfirmationEmail />,
    'confirmation reset password': () => <ConfirmationResetPassword />,
    'stripe auth': () => <StripeRedirect />,
  }

  return (
    <>
      <div className='wrapperAuth'>
        <div className='left'>
          <div className='head'>
            <div className='panelLogoWrapper'>
              <Link
                to='/home' onClick={() => {
                  window.scroll(0, 0)
                }}
              >
                <img src={defaultLogo} alt='logo' className='TLlogo' />
              </Link>
              <img src={memberLogo} alt='logo' className='logoMemberLyfe' />
            </div>
            <div className='title'>Start Your Lyfe Now</div>
            <div className='title'>
              The Journey
              <br />
              to
              <br />
              Excellence Begins Here
            </div>
          </div>
          <div className='foot'>
            <div className='description'>
              2024 Ticket Lyfe. All Rights Reserved. Use of this website signifies your agreement to our User Agreement, Privacy Notice and Cookie Notice.
            </div>
            <div className='flex'>
              <div>
                <Link to='/help/terms-and-conditions'>Terms & Conditions</Link>
              </div>
              <div className='circle' />
              <div>
                <Link to='/help/overview/general'>Help Center</Link>
              </div>
              <div className='circle' />
              <div>
                <Link to='/help/faq'>FAQ</Link>
              </div>
              <div className='circle' />
              <div>
                <Link to='/contact-us'>Contact Us</Link>
              </div>
            </div>
          </div>
        </div>

        <div className='right'>
          {objContent[activeTab]()}
        </div>
      </div>

    </>
  )
}

export default Auth
