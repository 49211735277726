import React from 'react'
import { getFormattedNumber } from 'utils'
import c from 'classnames'

const Category = ({ item = {}, currencySymbol }) => {
  return (
    <li className={c('category')}>
      <div className='categoryWrapperLine'>
        {item.categoryName && <div className='textCategory'>{item.categoryName}</div>}
        {item.total && item.type && <div className={item.type === 'Total' ? 'textTotal' : 'textCategory'}>{item.type}</div>}
        {item.bundleName &&
          <>
            <div className='textCategory'>{item.bundleName}</div>
          </>}
        {item?.total && <div className='textPrice'>{currencySymbol}{!isNaN(item?.total) && getFormattedNumber(item?.total)}</div>}
        {item.isActive &&
          <>
            <div className='textCategory'>{item.sectionName}</div>
            <div className='textPrice'>{currencySymbol}{!isNaN(item?.price) && getFormattedNumber(item?.price)}</div>
          </>}

      </div>
      {item.count > 0 && !item.isDonate && !item.bundleName && <div className='bottom'>{item.count} {item.count === 1 ? 'ticket' : 'tickets'}</div>}
      {item.count > 0 && !item.isDonate && item.bundleName && <div className='bottom'>{item.count} {item.count === 1 ? 'bundle' : 'bundles'}</div>}
      {item.isActive &&
        <>
          <div className='bottom'>{item.sectionType === 'TABLES' ? 'Table' : 'Row'} {item.rowName}</div>
          <div>Seat {item.seatName}</div>
        </>}
    </li>)
}
export default Category

// import React from 'react'
// import c from 'classnames'

// const Category = ({ item = {}, total = false, currencySymbol }) => {
//   console.log('item', item)
//   return (
//     <div className={c('category')}>
//       <div className={c('top', { bold: total })}>
//         {item.categoryName &&
//           <div className='totalWrapper'>
//             <div className='textCategory'>{item.categoryName}</div>
//             <div className='textPrice'>{currencySymbol}{!isNaN(item?.total) && item?.total?.toFixed(2)}</div>
//           </div>}
//         {item.count > 0 && !item.isDonate && <div className='bottom'>{item.count} {item.count === 1 ? 'ticket' : 'tickets'}</div>}
//         {item.isActive &&
//           <>
//             <div className='textCategory'>{item.sectionName}</div>
//             <div className='textPrice'>{currencySymbol}{!isNaN(item?.price) && item?.price?.toFixed(2)}</div>
//           </>}
//       </div>
//       {item.isActive &&
//         <div>
//           <div className='bottom'>{item.sectionType === 'TABLES' ? 'Table' : 'Row'} {item.rowName}</div>
//           <div>Seat {item.seatName}</div>
//         </div>}
//       {item?.total && !item?.price &&
//         <div className='totalWrapper'>
//           <div className='textTotal'>Total</div>
//           <div className='textPrice'>{currencySymbol}{!isNaN(item?.total) && item?.total?.toFixed(2)}</div>
//         </div>}
//     </div>)
// }
// export default Category
