import React, { useEffect, useState } from 'react'
import {
  Form,
  Input,
  Switch,
} from 'antd'

import './index.styl'

const { Item } = Form

const SignUpInputFieldsForm = ({ name, onRemove, form }) => {
  const [minorsOnly, setMinorsOnly] = useState(form.getFieldValue('minorsOnly') || false)
  const [hasRequired, setHasRequired] = useState(form.getFieldValue('hasRequired') || false)

  useEffect(() => {
    if (minorsOnly) {
      form.setFieldsValue({
        rosterDoB: true,
      })
    }
  }, [minorsOnly, form])

  const minorsOnlyHandler = () => {
    setMinorsOnly(!minorsOnly)
  }
  const hasRequiredHandler = () => {
    setHasRequired(!hasRequired)
  }
  return (
    <div className='SignUpInputFieldsWrapper'>
      <div className='SignUpInputTopField'>
        <Item
          name={[name, 'name']} label='Input Field Name' rules={[
            { required: true, message: 'Enter input field name!' },
            { max: 100, message: 'Input Field Name cannot exceed 100 characters!' },
          ]}
          normalize={v => v.trimStart()}
        >
          <Input className='inputFieldName' placeholder='Input field name' />
        </Item>
      </div>
      <div className='switchesWrapper'>
        <div className='radioItemField'>
          <Item
            name={[name, 'minorsOnly']}
            valuePropName='checked'
            className='item'
            initialValue={minorsOnly}
          >
            <Switch onChange={minorsOnlyHandler} defaultChecked={minorsOnly} />
          </Item>
          <div className='text'>Make the field active for minors only</div>
        </div>
        <div className='radioItemField'>
          <Item
            name={[name, 'hasRequired']}
            valuePropName='checked'
            className='item'
            initialValue={hasRequired}
          >
            <Switch onChange={hasRequiredHandler} defaultChecked={hasRequired} />
          </Item>
          <div className='text'>Make the field required</div>
        </div>
      </div>
      <div className='btnWrapper'>
        <div className='removeBtn' onClick={() => onRemove(name)}>
          Delete Input Field
        </div>
      </div>
    </div>
  )
}
export default SignUpInputFieldsForm
