import { auth, profile, events } from 'api'
import { user as v2user, profile as v2profile, eventsV2, auth as v2auth } from 'apiv2'

import { notification } from 'antd'
import { push } from 'router'
import {
  SESSION_IS_MOBILE,
  SESSION_LOGIN,
  SESSION_LOGOUT,
  SESSION_SKIP_TUTORIAL,
  LOGO_FOR_VENUE_UPDATE,
  BANK_DETAILS_UPDATE,
  ADMIN_BANK_DETAILS,
  ADMIN_BANK_DETAILS_CLEAR,
  SESSION_GUEST_RESERVATION_TOKEN,
} from './session-constants'

const msgError = error => {
  const message = error?.response?.data?.message || error?.response?.data?.error
  notification.warning({ message })
}

export const sessionIsMobile = payload => ({ type: SESSION_IS_MOBILE, payload })
export const sessionLogin = payload => ({ type: SESSION_LOGIN, payload })
export const sessionLogout = () => ({ type: SESSION_LOGOUT })
export const sessionSkipTutorial = payload => ({ type: SESSION_SKIP_TUTORIAL, payload })
export const isMobileWidth = bool => dispatch => dispatch(sessionIsMobile(bool))

export const usersGetVenueLogo = payload => ({ type: LOGO_FOR_VENUE_UPDATE, payload })
export const guestGetReservationToken = payload => ({ type: SESSION_GUEST_RESERVATION_TOKEN, payload })

export const usersGetBankDetails = payload => ({ type: BANK_DETAILS_UPDATE, payload })
export const adminGetBankDetails = payload => ({ type: ADMIN_BANK_DETAILS, payload })
export const adminClearBankDetails = () => ({ type: ADMIN_BANK_DETAILS_CLEAR })

export const login = (data, func = () => { }, noRedirect = false) => dispatch => {
  return new Promise((resolve, reject) => {
    auth
      .login(data)
      .then(async ({ data: { data } }) => {
        await dispatch(sessionLogin(data))
        await func()
        resolve(data)
      })
      .catch(error => {
        reject(error)
        msgError(error)
      })
  })
}

export const logout = () => dispatch => dispatch(sessionLogout())
export const bankDetailsClear = () => dispatch => dispatch(adminClearBankDetails())

export const signUpVenue = data => dispatch =>
  v2auth
    .signUpVenue(data)
    .then(() => {
      notification.success({
        message:
          'You are registered successfully. Please verify your email and then login.',
      })
      push('/auth/sign-in')
    })
    .catch(error => msgError(error))

export const signUp = data => dispatch =>
  v2auth
    .signUp(data)
    .then(() => {
      notification.success({
        message:
          'You are registered successfully. Please verify your email and then login.',
      })
      push('/auth/sign-in')
    })
    .catch(error => msgError(error))

export const resetPassword = email => dispatch =>
  v2auth
    .resetPassword(email)
    .then(() => {
      notification.success({
        message: 'Reset password link is sent on your email.',
      })
      push('/auth/confirmation-password')
    })
    .catch(error => msgError(error))

export const confirmationResetPassword = obj => dispatch =>
  auth.confirmationResetPassword(obj)
    .then(() => {
      notification.success({
        message:
          'Your password changed successfully.',
      })
      push('/auth/sign-in')
    })
    .catch(error => msgError(error))

export const verificationEmail = (token, en) => dispatch =>
  v2auth.verificationEmail(token, en)

export const makeConnectedAccount = obj => dispatch =>
  auth.makeConnectedAccount(obj)

export const resendEmail = () => dispatch => auth.resendEmail()

export const changePassword = obj => dispatch =>
  profile
    .changePassword(obj)
    .then(() => {
      notification.success({ message: 'Password successfully changed' })
      push('/auth/sign-in')
      dispatch(sessionLogout())
    })
    .catch(error => msgError(error))

export const editProfile = obj => dispatch => {
  return new Promise((resolve, reject) => {
    profile
      .editProfile(obj)
      .then(data => {
        dispatch(sessionLogin(data?.data?.data))
        dispatch(accessTokenLogin({ deviceType: 'WEB' }))
        notification.success({ message: 'Profile successfully edited' })
        resolve(data)
      })
      .catch(error => {
        reject(error)
        msgError(error)
      })
  })
}

export const editBankDetails = obj => dispatch => {
  return new Promise((resolve, reject) => {
    v2profile
      .editBankDetails(obj)
      .then(data => {
        dispatch(usersGetBankDetails(data.data.bankAccountDetails))
        notification.success({ message: 'Profile successfully edited' })
        resolve(data)
      })
      .catch(error => {
        reject(error)
        msgError(error)
      })
  })
}
export const userBankAccount = () => dispatch =>
  v2profile
    .userBankAccount().then(res => {
      dispatch(usersGetBankDetails(res.data.bankAccountDetails))
    })
    .catch(error => msgError(error))

export const adminBankAccount = id => dispatch =>
  eventsV2
    .getBankAccountEvent(id).then(res => {
      dispatch(adminGetBankDetails(res.data.bankAccountDetails))
    })
    .catch(error => msgError(error))

export const adminPayout = (obj, id) => dispatch => {
  return new Promise((resolve, reject) => {
    eventsV2
      .adminPayout(obj, id)
      .then(data => {
        resolve(data)
        notification.success({ message: 'Successfully paid out' })
      })
      .catch(error => {
        reject(error)
        msgError(error)
      })
  })
}
export const createTemplate = obj => dispatch => {
  return new Promise((resolve, reject) => {
    eventsV2
      .createTemplate(obj)
      .then(data => {
        resolve(data)
      })
      .catch(error => {
        reject(error)
        msgError(error)
      })
  })
}
export const editVenueProfile = obj => dispatch => {
  return new Promise((resolve, reject) => {
    profile
      .editVenueProfile(obj)
      .then(data => {
        dispatch(sessionLogin(data?.data?.data))
        dispatch(accessTokenLogin({ deviceType: 'WEB' }))
        notification.success({ message: 'Profile successfully edited' })
        resolve(data)
      })
      .catch(error => {
        reject(error)
        msgError(error)
      })
  })
}

export const uploadFile = image => dispatch => {
  return new Promise(resolve => {
    events
      .uploadImage(image)
      .then(res => {
        dispatch(editProfile({ image: JSON.stringify(res.data.data) }))
        resolve(true)
      })
      .catch(error => {
        msgError(error)
        resolve(false)
      })
  })
}

export const getLogoVenue = url => dispatch => {
  return new Promise(resolve => {
    profile
      .getLogoVenue(url)
      .then(data => {
        dispatch(usersGetVenueLogo(data.data.data.venueLogo))
        resolve(true)
      })
      .catch(error => {
        msgError(error)
        resolve(false)
      })
  })
}

export const getBankDetailsInfo = url => dispatch => {
  return new Promise(resolve => {
    v2profile
      .getBankDetailsInfo()
      .then(data => {
        dispatch(usersGetBankDetails(data))
        resolve(true)
      })
      .catch(error => {
        msgError(error)
        resolve(false)
      })
  })
}

export const uploadQrCodeFile = image => dispatch => {
  return new Promise(resolve => {
    events
      .uploadImage(image)
      .then(res => {
        dispatch(editProfile({ venmoQrCodeImage: JSON.stringify(res.data.data) }))
        resolve(true)
      })
      .catch(error => {
        msgError(error)
        resolve(false)
      })
  })
}
export const disconnectVenmo = () => dispatch => {
  return new Promise(resolve => {
    v2user
      .disconnectVenmo()
      .then(res => {
        resolve(true)
        dispatch(accessTokenLogin({ deviceType: 'WEB' }))
        notification.success({ message: 'Profile successfully edited' })
      })
      .catch(error => {
        msgError(error)
        resolve(false)
      })
  })
}
export const disconnectStripe = () => dispatch => {
  return new Promise(resolve => {
    v2user
      .disconnectStripe()
      .then(res => {
        resolve(true)
        dispatch(accessTokenLogin({ deviceType: 'WEB' }))
        notification.success({ message: 'Profile successfully edited' })
      })
      .catch(error => {
        msgError(error)
        resolve(false)
      })
  })
}

export const accessTokenLogin = obj => dispatch =>
  profile
    .accessTokenLogin(obj)
    .then(({ data: { data } }) => {
      dispatch(sessionLogin(data))
    })
    .catch(() => {
      // msgError(error)
      dispatch(logout())
      push('/auth/sign-in')
    })
