/* eslint-disable react/jsx-closing-bracket-location */
/* eslint-disable indent */
import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Table } from 'antd'
import { useParams } from 'react-router-dom'
import {
  getEventDetails,
} from 'modules/events/events-actions'
import { getUtcOffsetDate, formatInteger } from 'utils'
import './index.styl'

const Categories = ({ currencySymbol, tableData, setTableData, isChange, isResponsive }) => {
  const { currentEventDetails } = useSelector(state => state.events)
  const { user } = useSelector(state => state.session)
  const dispatch = useDispatch()
  const { eventID } = useParams()
  // const [isChange, setIsChange] = useState(false)

  useEffect(() => {
    if (isChange) {
      dispatch(getEventDetails(eventID))
    }
    }, [isChange])
  useEffect(() => {
    const data = currentEventDetails.tickets?.map(i => {
      const ticketData = {
        isInvite: false,
        category: i?.ticketName,
        ticketType: i?.ticketType,
        quantity: formatInteger(i?.quantity),
        originalPrice: formatInteger(i?.price),
        total: formatInteger(i?.ticketCount?.total),
        directSell: formatInteger(i?.ticketCount?.sell),
        cashSalesRevenue: formatInteger(i?.ticketCount?.cashSales),
        gift: formatInteger(i?.ticketCount?.gift),
        ticketsScanned: formatInteger(i?.ticketCount?.ticketsScanned),
        sold: formatInteger(i?.ticketCount?.buy),
        subPromote: formatInteger(i?.ticketCount?.promote),
        available: formatInteger(i?.ticketCount?.available),
        price: formatInteger(i?.price || 0),
        id: i?._id,
        eventID,
        userID: currentEventDetails.userID,
        eventName: currentEventDetails.eventName,
        startDate: currentEventDetails.startDate,
        endDate: currentEventDetails.endDate,
        ticketColor: i.bgColor,
        isVirtualTicket: currentEventDetails.isVirtualTicket,
        isStreamingEvent: i.isStreaming,
        image: currentEventDetails.imageUrl?.thumbnail1,
        imageUrl: currentEventDetails.imageUrl,
        currency: currentEventDetails.currency,
        eventType: currentEventDetails.eventType,
        presentedBy: currentEventDetails.presentedBy,
        rainDate: getUtcOffsetDate(currentEventDetails.rainDate, 'MMMM DD, YYYY', currentEventDetails.timeZone),
      }
      if (!currentEventDetails.isVirtualTicket) {
        ticketData.venue = currentEventDetails?.venue
        ticketData.address = currentEventDetails?.address
      }
      return ticketData
    })
    if ((currentEventDetails.isDonationEnable && currentEventDetails?.userID === user._id) || (currentEventDetails.isDonationEnable && user.role?.includes('admin'))) {
      const objDonationRow = {
        id: currentEventDetails.donations?._id,
        category: 'Donations',
        total: currentEventDetails.donations?.quantity || 0,
        price: currentEventDetails.donations ? currentEventDetails.donations.total : 0,
        isDonation: true,
      }
      data.push(objDonationRow)
    }

    setTableData(data)
  }, [currentEventDetails])
  const defaultColumns = [
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
    },
    {
      title: 'Online sales',
      dataIndex: 'sold',
      key: 'sold',
      render: (item, record) => record.isDonation ? '' : item,
    },
    {
      title: 'Direct sales',
      dataIndex: 'directSell',
      key: 'directSell',
      render: (item, record) => record.isDonation ? '' : item,
    },
    {
      title: 'Gift',
      dataIndex: 'gift',
      key: 'gift',
      render: (item, record) => record.isDonation ? '' : item,
    },
    {
      title: 'Cash/check sales',
      dataIndex: 'cashSalesRevenue',
      key: 'cashSalesRevenue',
      render: (item, record) => record.isDonation ? '' : item,
    },
    {
      title: 'Scanned',
      dataIndex: 'scanned',
      key: 'scanned',
      render: (item, record) => record.isDonation ? '' : item || 0,
    },
    {
      title: 'Available',
      key: 'available',
      dataIndex: 'available',
      render: (item, record) => record.isDonation ? '' : item,
    },
    {
      title: 'Price',
      key: 'price',
      dataIndex: 'price',
      render: (item, record) => <span>{currencySymbol}{item}</span>,
    },
  ]

  const columns = defaultColumns
  // eventname, venue, address, startDate, startTime - endTime, ticketColor
  return (
    <>
      <div id='categories' className='wrapperTablesAssignSeating'>
        <div className='titleAssignedSeating'>Membership Categories</div>
        {isResponsive
          ? tableData && tableData.map((i, idx) => {
            return (
              <div key={idx} className='memberCategoriesTable'>
                <div className='memberCategoriesItem'>
                  <div className='memberCategorieslabel'>Category</div>
                  <div className='memberCategoriesText'>{i.category}</div>
                </div>
                {i.isDonation
                ? (
                  <div className='memberCategoriesItem'>
                    <div className='memberCategorieslabel'>Received</div>
                    <div className='memberCategoriesText'>{currencySymbol}{i.price}</div>
                  </div>
                ) : (
                  <>
                    <div className='memberCategoriesItem'>
                      <div className='memberCategorieslabel'>Online sales</div>
                      <div className='memberCategoriesText'>{i.sold}</div>
                    </div>
                    <div className='memberCategoriesItem'>
                      <div className='memberCategorieslabel'>Direct sales</div>
                      <div className='memberCategoriesText'>{i.directSell}</div>
                    </div>
                    <div className='memberCategoriesItem'>
                      <div className='memberCategorieslabel'>Gift</div>
                      <div className='memberCategoriesText'>{i.gift}</div>
                    </div>
                    <div className='memberCategoriesItem'>
                      <div className='memberCategorieslabel'>Cash/check sales</div>
                      <div className='memberCategoriesText'>{i.cashSalesRevenue}</div>
                    </div>
                    {i?.scanned &&
                      <div className='memberCategoriesItem'>
                        <div className='memberCategorieslabel'>Scanned</div>
                        <div className='memberCategoriesText'>{i.scanned}</div>
                      </div>}

                    <div className='memberCategoriesItem'>
                      <div className='memberCategorieslabel'>Available</div>
                      <div className='memberCategoriesText'>{i.available}</div>
                    </div>
                    <div className='memberCategoriesItem'>
                      <div className='memberCategorieslabel'>Price</div>
                      <div className='memberCategoriesText'>{currencySymbol}{i.price}</div>
                    </div>

                  </>)}
              </div>
          )
})
          : (
            <Table
              columns={columns}
              dataSource={tableData}
              className='eventDetailsTable memberDetailsTable'
              rowKey='id'
            />
          )}
      </div>
    </>
  )
}

export default Categories
