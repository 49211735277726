import React, { useState } from 'react'
import { CustomButton } from 'components'
import close from 'images/closeBanner.svg'
import logo from 'images/logoBanner.svg'
import c from 'classnames'
import './index.styl'
import { useHistory } from 'react-router'

export default () => {
  const [fadeOut, setFadeOut] = useState(false)
  const history = useHistory()
  return (
    <>

      <div className='banner-mock-member-ticket'>
        <div className={c('banner', { fadeIn: !fadeOut, fadeOut: fadeOut })}>
          <img src={close} alt='close' className='close' onClick={() => setFadeOut(true)} />
          <div className='flex-container'>
            <div className='logoTextWrapper'>
              <div className='info'>
                <div className='description'>
                  <img src={logo} alt='logo' />
                </div>
              </div>
              <div className='infoCenter'>
                <div className='description'>
                  <div className='title'>Make a Ticket Now</div>
                  <span className='text'>Master your own ticket...  Live your best lyfe</span>
                </div>
              </div>
            </div>
            <CustomButton className='button' text='Try It' type='darkInverse' onClick={() => history.push('/home')} />
          </div>
        </div>
      </div>
    </>

  )
}
