import { memberships, images } from 'apiv2'

import { notification } from 'antd'
import * as actionTypes from './memberships-constants'
import { membershipsSelector } from './memberships-selectors'
import { push } from 'modules/router'

const msgError = error => {
  const message = error?.response?.data?.message || error?.response?.data?.error
  notification.warning({ message })
}

export const setAdditionalImages = payload => ({ type: actionTypes.PAGES_SET_ADDITIONAL_IMAGES, payload })
export const clearUploadFile = () => dispatch => {
  dispatch(setAdditionalImages({}))
}
export const deleteAdditionalImage = url => (dispatch, getStore) => {
  const { additionalImagesUrls } = membershipsSelector(getStore())
  dispatch(setAdditionalImages(additionalImagesUrls.filter(obj => obj?.original !== url)))
}

export const announcementSetImage = payload => ({ type: actionTypes.ANNOUNCEMENT_SET_IMAGES, payload })
export const buySetImage = payload => ({ type: actionTypes.BUY_SET_IMAGES, payload })
export const chatSetImage = payload => ({ type: actionTypes.CHAT_SET_IMAGES, payload })

export const getSoldMemberDetails = payload => ({
  type: actionTypes.MEMBERSHIPS_GET_SOLD_MEMBER_DETAILS,
  payload,
})
export const getMembershipMember = payload => ({
  type: actionTypes.IS_MEMBERSHIP_MEMBER,
  payload,
})

export const getMembershipSummaries = payload => ({
  type: actionTypes.MEMBERSHIP_GET_DETAILS_SUMMARY,
  payload,
})

export const currentPageDetails = payload => ({
  type: actionTypes.GET_PAGE_DETAILS,
  payload,
})

export const eventsGetMemberPublicEvents = payload => ({
  type: actionTypes.EVENTS_GET_MEMBER_PUBLIC_EVENTS,
  payload,
})

export const eventsSetMemberPublicEvents = payload => ({
  type: actionTypes.EVENTS_SET_MEMBER_HOME_PUBLIC_EVENTS,
  payload,
})
export const eventsSetActiveParticipants = payload => ({
  type: actionTypes.EVENTS_SET_ACTIVE_PARTICIPANTS,
  payload,
})

export const getMembershipDistributedTickets = (eventID, ticketType, perPage, skip, searchValue) => dispatch => {
  memberships.getMembershipDistributedTickets(eventID, ticketType, perPage, skip, searchValue)
    .then(res => {
      const data = res?.data?.data
      const total = res?.data?.desc.total
      dispatch(getSoldMemberDetails({ total, data }))
    })
}
export const getDonationList = (eventID, perPage, skip, searchValue, input) => dispatch => {
  memberships.getDonationList(eventID, perPage, skip, searchValue, input)
    .then(res => {
      const data = res?.data?.data
      const total = res?.data?.desc.total
      dispatch(getSoldMemberDetails({ total, data }))
    })
}

export const getMembershipRoster = (eventID, perPage, skip, searchValue, type) => (dispatch, getStore) => {
  // const getFields = new Promise(resolve => {
  //   memberships.getMembershipRosterFields(eventID)
  //   .then(res => {
  //     resolve(res?.data)
  //   })
  //   .catch(error => {
  //     resolve(false)
  //     msgError(error)
  //   })
  // })
  memberships.getMembershipRoster(eventID, perPage, skip, searchValue, type)
    .then(res => {
      const data = res?.data?.data
      const total = res?.data?.desc.total
      dispatch(getSoldMemberDetails({ total, data }))
    })
}

export const uploadAdditionalImages = (originalImage, image, options) => (dispatch, getStore) => {
  return new Promise(resolve => {
    images
      .posterCover(originalImage, image, options)
      .then(res => {
        const { additionalImagesUrls } = membershipsSelector(getStore())
        dispatch(setAdditionalImages([...additionalImagesUrls, res?.data]))
        resolve(res?.data)
        notification.success({ message: 'Image successfully uploaded' })
      })
      .catch(error => {
        resolve(false)
        msgError(error)
      })
  })
}
export const uploadAnnouncementImage = (originalImage, image, options) => dispatch => {
  return new Promise(resolve => {
    images
      .posterCover(originalImage, image, options)
      .then(res => {
        dispatch(announcementSetImage(res?.data))
        resolve(res?.data)
        notification.success({ message: 'Image successfully uploaded' })
      })
      .catch(error => {
        resolve(false)
        msgError(error)
      })
  })
}
export const uploadBuyImage = (originalImage, image, options) => dispatch => {
  return new Promise(resolve => {
    images
      .posterCover(originalImage, image, options)
      .then(res => {
        dispatch(buySetImage(res?.data))
        resolve(res?.data)
        notification.success({ message: 'Image successfully uploaded' })
      })
      .catch(error => {
        resolve(false)
        msgError(error)
      })
  })
}
export const uploadChatImage = (originalImage, image, options) => dispatch => {
  return new Promise(resolve => {
    images
      .posterCover(originalImage, image, options)
      .then(res => {
        dispatch(chatSetImage(res?.data))
        resolve(res?.data)
        notification.success({ message: 'Image successfully uploaded' })
      })
      .catch(error => {
        resolve(false)
        msgError(error)
      })
  })
}

export const makeMemberPage = obj => dispatch => {
  return new Promise((resolve, reject) => {
    memberships
      .makeMemberPage(obj)
      .then(response => {
        notification.success({ message: 'Page successfully created' })
        // dispatch(clearUploadFile())
        resolve(response)
      })
      .catch(error => {
        reject(error)
        msgError(error)
      })
  })
}

export const editMemberPage = (obj, eventID) => dispatch => {
  return new Promise((resolve, reject) => {
    memberships.editMemberPage(obj, eventID).then(() => {
      dispatch(getPageDetails(eventID))
      // dispatch(clearUploadFile())
      notification.success({ message: 'Page successfully updated' })
      resolve()
    }).catch(error => {
      reject(error)
      msgError(error)
    })
  })
}
export const resendParentRequest = obj => dispatch => {
  return new Promise((resolve, reject) => {
    memberships.resendParentRequest(obj).then(() => {
      notification.success({ message: 'Email successfully resend' })
      resolve()
    }).catch(error => {
      reject(error)
      msgError(error)
    })
  })
}

export const getPageDetails = eventID => (dispatch, getStore) =>
  memberships.getMemberPageDetails(eventID)
    .then(data => {
      const obj = { ...data.data.event }
      dispatch(currentPageDetails(obj))
      // const { events } = eventsSelector(getStore())
      // dispatch(eventsGetEvents({ ownEvents: events.map(i => i._id === eventID ? obj : i) }))
    })
    .catch(error => {
      if (error?.response?.status === 401) {
        push('/auth/sign-in')
      }
    })
export const approveMembershipRequest = (eventID, membershipID) => (dispatch, getStore) => {
  return new Promise((resolve, reject) => {
    memberships.approveMembership(eventID, membershipID).then(() => {
      notification.success({ message: 'Request approved!' })
      resolve()
    }).catch(error => {
      reject(error)
      msgError(error)
    })
  })
}
export const disableMembershipMember = (eventID, membershipID) => (dispatch, getStore) => {
  return new Promise((resolve, reject) => {
    memberships.disableMembership(eventID, membershipID).then(() => {
      notification.success({ message: 'Member disabled!' })
      resolve()
    }).catch(error => {
      reject(error)
      msgError(error)
    })
  })
}
export const unblockMembershipMember = (eventID, membershipID) => (dispatch, getStore) => {
  return new Promise((resolve, reject) => {
    memberships.unblockMembership(eventID, membershipID).then(() => {
      notification.success({ message: 'Member unblocked!' })
      resolve()
    }).catch(error => {
      reject(error)
      msgError(error)
    })
  })
}
export const deleteMembershipMember = (eventID, membershipID) => (dispatch, getStore) => {
  return new Promise((resolve, reject) => {
    memberships.deleteMembership(eventID, membershipID).then(() => {
      notification.success({ message: 'Member deleted!' })
      resolve()
    }).catch(error => {
      reject(error)
      msgError(error)
    })
  })
}
export const inviteUser = (eventID, email) => (dispatch, getStore) => {
  return new Promise((resolve, reject) => {
    memberships.inviteMembership(eventID, email).then(() => {
      notification.success({ message: 'Invitation sent!' })
      resolve()
    }).catch(error => {
      reject(error)
      msgError(error)
    })
  })
}
export const membershipRequest = obj => (dispatch, getStore) => {
  return new Promise((resolve, reject) => {
    memberships.membershipRequest(obj).then(() => {
      resolve()
    }).catch(error => {
      reject(error)
      msgError(error)
    })
  })
}
export const selfScanWallet = obj => (dispatch, getStore) => {
  return new Promise((resolve, reject) => {
    memberships.selfScanWallet(obj).then(data => {
      resolve(data)
    }).catch(error => {
      reject(error)
      // msgError(error)
    })
  })
}

export const getMembershipSummariesData = eventID => dispatch =>
  memberships.getMembershipSummaries(eventID)
    .then(res => {
      const data = res?.data
      dispatch(getMembershipSummaries(data.data))
    })

export const getParentApproval = eventID => (dispatch, getStore) => {
  return new Promise((resolve, reject) => {
    memberships.getParentApproval(eventID)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        if (error?.response?.data?.statusCode === 400) {
        // push('/404?status=blocked')
        }
      })
  })
}
export const getMembershipQRCode = eventID => (dispatch, getStore) => {
  return new Promise((resolve, reject) => {
    memberships.getMembershipQRCode(eventID)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        if (error?.response?.data?.statusCode === 400) {
        // push('/404?status=blocked')
        }
      })
  })
}

export const checkMembershipMember = eventID => (dispatch, getStore) => {
  return new Promise((resolve, reject) => {
    memberships.checkMembershipMember(eventID)
      .then(response => {
        dispatch(getMembershipMember(response.data?.isMember))
        resolve(response)
      })
      .catch(error => {
        if (error?.response?.data?.statusCode === 400) {
        // push('/404?status=blocked')
        }
      })
  })
}

export const getMemberPublicEvent = obj => dispatch =>
  memberships
    .getMemberPublicEvent(obj)
    .then(({ data }) => {
      dispatch(eventsGetMemberPublicEvents(data.data))
    })
    .catch(error => msgError(error))

export const getMemberHomePublicEvent = obj => (dispatch, getStore) => {
  return new Promise((resolve, reject) => {
    const { memberPublicEvents } = membershipsSelector(getStore())

    memberships
      .getMemberPublicEvent(obj)
      .then(({ data }) => {
        const currentEvents = [...memberPublicEvents, ...data.events]
        const q = eventsSetMemberPublicEvents(currentEvents)
        dispatch(q)
        resolve({ total: data.meta.total, current: currentEvents.length })
      })
      .catch(error => {
        reject(error)
        msgError(error)
      })
  })
}

export const getActiveParticipations = obj => (dispatch, getStore) => {
  return new Promise((resolve, reject) => {
    memberships
      .getActiveParticipations(obj)
      .then(({ data }) => {
        const currentActiveParticipants = [...data.events]
        const q = eventsSetActiveParticipants(currentActiveParticipants)
        dispatch(q)
        resolve({ total: data.meta.total, current: currentActiveParticipants.length })
      })
      .catch(error => {
        reject(error)
        msgError(error)
      })
  })
}
