import React from 'react'
import { Popover } from 'antd'
import { Link } from 'react-router-dom'
import { CustomButton } from 'components'
import { getCurrencySymbolByCode, isVenue, isAdmin, getUtcOffsetDate } from 'utils'
import moment from 'moment'
import circleDone from 'images/circleDone.png'
import ThreeDotIcon from 'images/threedots.svg'
import VenueIcon from 'images/venueType.svg'
import UserIcon from 'images/userType.svg'
import PromoterPartnerIcon from 'images/promoterPartner.svg'
import c from 'classnames'
import './index.styl'

const MBEventItem = ({ event, actionProps, setShowBankDetailsModal, setModalAction, setShowBankDetails, user }) => {
  const currencyCode = getCurrencySymbolByCode(event?.currency)
  const renderDate = date => {
    const _date = moment(date).utcOffset(date)
    return (
      <>
        <span className='month'>{_date.format('MMM')} {_date.format('YYYY')}</span>
        <span className='date'>{_date.format('DD')}</span>
        <span className='time'>{getUtcOffsetDate(event.startDate)}</span>
      </>
    )
  }

  const getRowLink = eventId => ({ pathname: `/my-box-office/event-details/tickets-categories/${eventId}`, state: actionProps.state })

  const popoverContent = ({ id, isDeleted, refundStatus, isEventBlocked, isEventCancelled }) => {
    const isInactive = !isEventBlocked && !isEventCancelled && !isDeleted
    return (
      <div className='actionPopover'>
        <div className='popoverBtn'><Link to={getRowLink(id)}>View details</Link></div>
        {!isInactive && (
          <div className='popoverBtn' onClick={() => setModalAction({ ...actionProps.activateEvent, id })}>
            Activate event
          </div>)}
        {!isVenue(user?.role) && (
          <div
            className='popoverBtn' onClick={() => {
              setShowBankDetails({ ...actionProps.refundAll, id, event })
              setShowBankDetailsModal(true)
            }}
          >
            Manual Payout
          </div>
        )}
        {!isEventBlocked && !isVenue(user?.role) && (
          <div className='popoverBtn btnRemove' onClick={() => setModalAction({ ...actionProps.blockEvent, id })}>
            Block event
          </div>)}
        {!isEventCancelled && (
          <div className='popoverBtn btnRemove' onClick={() => setModalAction({ ...actionProps.cancelEvent, id })}>
            Cancel event
          </div>)}
        {!isDeleted && !isVenue(user?.role) && (
          <div className='popoverBtn btnRemove' onClick={() => setModalAction({ ...actionProps.deleteEvent, id })}>
            Delete event
          </div>)}
        {!refundStatus || !!refundStatus && refundStatus !== 'FULLY_REFUNDED' ? (
          <div className='popoverBtn btnRemove' onClick={() => setModalAction({ ...actionProps.refundAll, id })}>
            Refund all tickets
          </div>) : null}
      </div>
    )
  }
  const balancesConfig = {
    onlineSales: 'Online Sales',
    donationAmount: 'Donations',
    onDemandAmount: 'On-Demand',
    tlFeeAmount: 'TL Fees',
    streamFeeAmount: 'Stream Fee',
    stripeFeeAmount: 'Payment Fees',
    promoterAmount: "Promoter's Take",
    cashRevenue: 'Cash/Check Sales ',
  }
  const paidConfig = {
    promoterPaidOff: 'Paid to Promoter',
    refundAmount: 'Refunded',
  }
  const balancesConfigVenue = {
    onlineSales: 'Online Sales',
    donationAmount: 'Donations',
    onDemandAmount: 'On-Demand',
    stripeFeeAmount: 'Payment Fee',
    venueTake: 'Venue\'s Take',
    venueFeeAmount: 'Venue Revenue',
    coownerAmount: 'Co-Promoter\'s Take',
    saleTax: 'Taxes',
    cashRevenue: 'Cash/Check Sales ',
  }
  const balancesConfigVenueWoCopromoter = {
    onlineSales: 'Online Sales',
    donationAmount: 'Donations',
    onDemandAmount: 'On-Demand',
    stripeFeeAmount: 'Payment Fees',
    venueTake: 'Venue\'s Take',
    venueFeeAmount: 'Venue Fee',
    saleTax: 'Taxes',
    cashRevenue: 'Cash/Check Sales',
  }
  const paidConfigVenue = {
    streamPayback: 'Stream Payback',
    promoterPaidOff: 'Paid to Co-Promoter',
    venuePaidOff: 'Paid to Venue',
    refundAmount: 'Refunded',
  }
  const paidConfigVenueWoCopromoter = {
    streamPayback: 'Stream Payback',
    promoterPaidOff: 'Paid to Venue',
    refundAmount: 'Refunded',
  }
  const balancesConfigVenueAdmin = {
    onlineSales: 'Online Sales',
    donationAmount: 'Donations',
    onDemandAmount: 'On-Demand',
    tlFeeAmount: 'TL Fees',
    streamFeeAmount: 'Stream Fee',
    stripeFeeAmount: 'Payment Fee',
    venueTake: 'Venue\'s Take',
    venueFeeAmount: 'Venue Revenue',
    coownerAmount: 'Co-Promoter\'s Take',
    saleTax: 'Taxes',
    cashRevenue: 'Cash/Check Sales ',
  }
  const balancesConfigVenueWoCopromoterAdmin = {
    onlineSales: 'Online Sales',
    donationAmount: 'Donations',
    onDemandAmount: 'On-Demand',
    tlFeeAmount: 'TL Fees',
    streamFeeAmount: 'Stream Fee',
    stripeFeeAmount: 'Payment Fees',
    promoterAmount: "Promoter's Take",
    venueTake: 'Venue\'s Take',
    venueFeeAmount: 'Venue Fee',
    saleTax: 'Taxes',
    cashRevenue: 'Cash/Check Sales',
  }
  const paidConfigVenueAdmin = {
    streamPayback: 'Stream Payback',
    promoterPaidOff: 'Paid to Co-Promoter',
    venuePaidOff: 'Paid to Venue',
    refundAmount: 'Refunded',
  }

  const getCreatorFullName = ({ firstName, lastName, venueName }) => venueName ? `${venueName}`.trim() : `${firstName} ${lastName}`.trim()
  const promoterFullName = getCreatorFullName(event?.promoter)
  const payoutProcessor = event.payoutProcessor && event.payoutProcessor[0].toUpperCase() + event.payoutProcessor.slice(1)
  const mobileWidth = window.innerWidth <= 500
  return (
    <div className='EventItem'>
      <div className='dateContainer'>{renderDate(event?.startDate)}</div>
      <div className='nameContainer'>
        <div className='nameTitle'><Link to={getRowLink(event?.id)}>{event?.eventName}</Link></div>
        <div className='nameSubtitle'>
          {mobileWidth &&
            <div className='mobileDetail'>
              <div className='detailContainerItemTitle'>Status</div>
              <div className='detailContainerItemDescription'>
                <span className={c('status', event?.statusLabel?.toLowerCase())}>{event?.statusLabel}</span>
              </div>
            </div>}
          {event?.presentedBy && <div><span>Presented By</span> {event?.presentedBy}</div>}
          {promoterFullName && <div><span>Created by</span> {promoterFullName}</div>}
          {event?.promoter?.email && <div><span>Creator Email</span> {event?.promoter?.email}</div>}
          {event?.promoter?.mobile && <div><span>Creator Phone Number</span> {event?.promoter?.mobile}</div>}
        </div>
      </div>
      <div className='detailActions'>
        <div className='testAcc'>
          <div className='connectedWrapper'>
            {event?.payoutProcessor && <div className='connected'><img src={circleDone} alt='circleDone' /> {payoutProcessor} connected</div>}
            {event?.isVenueEvent || event?.promoter?.isVenueUser ? <img className='venueIcon' src={VenueIcon} alt='VenueIcon' /> : event?.promoter?.isSubscriptionActive ? <img className='venueIcon' src={PromoterPartnerIcon} alt='PromoterPartnerIcon' /> : <img className='venueIcon' src={UserIcon} alt='user icon' />}
            <Popover
              overlayClassName={event?.payoutProcessor ? 'actionPopoverContainerProcessor' : 'actionPopoverContainer'}
              getPopupContainer={node => node?.parentNode}
              content={popoverContent(event)}
              placement='topRight'
              trigger='click'
            >
              <img src={ThreeDotIcon} className='threeDots mdVisible' alt=' ' />
              <CustomButton htmlType='button' className='xsVisible' text='More Options' />
            </Popover>
          </div>
          {!mobileWidth &&
            <div className='detailContainerItemStatus detailContainerItemStyles statusItem'>
              <div className='detailContainerItemTitle'>Status</div>
              <div className='detailContainerItemDescription'>
                <span className={c('status', event?.statusLabel?.toLowerCase())}>{event?.statusLabel}</span>
              </div>
            </div>}
        </div>
      </div>
      <div className='detailContainer'>
        <div className='collectedContainer'>
          <div className='title'>
            Collected
          </div>
          {isAdmin(user?.role)
            ? event?.isVenueEvent
              ? Object.keys(balancesConfigVenueAdmin).map(key => (
                <div key={key} className='detailContainerItem'>
                  <div className='detailContainerItemTitle'>{balancesConfigVenueAdmin[key]}</div>
                  <div className='detailContainerItemDescription'>{currencyCode}{(event?.balances[key] || event?.offlineSales[key]) ?? '0.00'}</div>
                </div>))
              : user?.isVenueUser
                ? Object.keys(balancesConfigVenueWoCopromoterAdmin).map(key => (
                  <div key={key} className='detailContainerItem'>
                    <div className='detailContainerItemTitle'>{balancesConfigVenueWoCopromoterAdmin[key]}</div>
                    <div className='detailContainerItemDescription'>{currencyCode}{(event?.balances[key] || event?.offlineSales[key]) ?? '0.00'}</div>
                  </div>))
                : Object.keys(balancesConfig).map(key => (
                  <div key={key} className='detailContainerItem'>
                    <div className='detailContainerItemTitle'>{balancesConfig[key]}</div>
                    <div className='detailContainerItemDescription'>{currencyCode}{(event?.balances[key] || event?.offlineSales[key]) ?? '0.00'}</div>
                  </div>))
            : event?.isVenueEvent
              ? Object.keys(balancesConfigVenue).map(key => (
                <div key={key} className='detailContainerItem'>
                  <div className='detailContainerItemTitle'>{balancesConfigVenue[key]}</div>
                  <div className='detailContainerItemDescription'>{currencyCode}{(event?.balances[key] || event?.offlineSales[key]) ?? '0.00'}</div>
                </div>
              ))
              : Object.keys(balancesConfigVenueWoCopromoter).map(key => (
                <div key={key} className='detailContainerItem'>
                  <div className='detailContainerItemTitle'>{balancesConfigVenueWoCopromoter[key]}</div>
                  <div className='detailContainerItemDescription'>{currencyCode}{(event?.balances[key] || event?.offlineSales[key]) ?? '0.00'}</div>
                </div>
              ))}
        </div>
        <div className='paidContainer'>
          <div className='title'>
            Paid
          </div>
          {isAdmin(user?.role)
            ? event?.isVenueEvent
              ? Object.keys(paidConfigVenueAdmin).map(key => (
                <div key={key} className='detailContainerItem'>
                  <div className='detailContainerItemTitle'>{paidConfigVenueAdmin[key]}</div>
                  <div className='detailContainerItemDescription'>{currencyCode}{event?.balances[key] ?? '0.00'}</div>
                </div>))
              : Object.keys(paidConfig).map(key => (
                <div key={key} className='detailContainerItem'>
                  <div className='detailContainerItemTitle'>{paidConfig[key]}</div>
                  <div className='detailContainerItemDescription'>{currencyCode}{event?.balances[key] ?? '0.00'}</div>
                </div>))
            : event?.isVenueEvent
              ? Object.keys(paidConfigVenue).map(key => (
                <div key={key} className='detailContainerItem'>
                  <div className='detailContainerItemTitle'>{paidConfigVenue[key]}</div>
                  <div className='detailContainerItemDescription'>{currencyCode}{event?.balances[key] ?? '0.00'}</div>
                </div>))
              : Object.keys(paidConfigVenueWoCopromoter).map(key => (
                <div key={key} className='detailContainerItem'>
                  <div className='detailContainerItemTitle'>{paidConfigVenueWoCopromoter[key]}</div>
                  <div className='detailContainerItemDescription'>{currencyCode}{event?.balances[key] ?? '0.00'}</div>
                </div>))}
        </div>
      </div>
    </div>
  )
}

export default MBEventItem
