import React, { useEffect, useState } from 'react'
import { Menu, Dropdown } from 'antd'
import LogoMemberLyfe from '../LogoMemberLyfe'
import DropDownIcon from 'images/DropDownIcon.svg'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import Logo from '../Logo'
import './index.styl'

const LayoutSwitcher = ({ type }) => {
  const [pageType, setPageType] = useState('ticket')
  const { user } = useSelector(state => state.session)
  useEffect(() => {
    setPageType(type)
  }, [type])
  const menu = (
    <Menu>
      <div className='logoMenuWrapper'>
        {pageType === 'member'
          ? (
            <Menu.Item key='ticket'>
              <div className='typeWrapper'>
                <Link to='/home'>
                  <div className='text'>Switch to Ticket Lyfe</div>
                  <Logo />
                </Link>

              </div>
            </Menu.Item>
          ) : (
            <Menu.Item key='member'>
              <div className='typeWrapper'>
                <Link to={user ? '/memberlyfe-home' : '/help/overview/my-box-office'}>
                  <div className='text'>Switch to Member Lyfe</div>
                  <LogoMemberLyfe user={user} />
                </Link>
              </div>
            </Menu.Item>)}
      </div>
    </Menu>
  )

  return (
    <div className='logoSwitcher'>
      {pageType === 'member' ? <LogoMemberLyfe user={user} /> : <Logo />}
      <Dropdown overlayClassName='logoSwitcherDrop' trigger={['click']} overlay={menu}>
        <img src={DropDownIcon} alt='Drow down icon' className='icon' />
      </Dropdown>
    </div>
  )
}

export default LayoutSwitcher
