import { events } from 'api'
import { eventsV2 } from '../../apiv2'
import { template, images } from 'apiv2'
import { notification } from 'antd'
import * as actionTypes from './events-constants'
import * as sessionTypes from '../session/session-constants'
import {
  getPageDetails,
} from 'modules/memberships/memberships-actions'
import { eventsSelector } from './events-selectors'
import { push } from 'modules/router'

const msgError = error => {
  const message = error?.response?.data?.message || error?.response?.data?.error
  notification.warning({ message })
}

const ticketSpelling = quantity => `ticket${quantity > 1 ? 's' : ''}`

export const eventsGetEvents = payload => ({ type: actionTypes.EVENTS_GET_EVENTS, payload })
export const eventsGetTemplates = payload => ({ type: actionTypes.EVENTS_GET_TEMPLATES, payload })

export const eventsGetPromotions = payload => ({ type: actionTypes.EVENTS_GET_PROMOTIONS, payload })

export const subscriptionUpdate = payload => ({ type: sessionTypes.SUBSCRIBE_UPDATE, payload })

export const assignTicketSection = payload => ({
  type: actionTypes.EVENTS_GET_ASSIGN_TICKETS,
  payload,
})

export const assignTicketSectionDetails = payload => ({
  type: actionTypes.EVENTS_GET_ASSIGN_TICKET_SECTION_DETAILS,
  payload,
})

export const eventDetailsSummary = payload => ({
  type: actionTypes.EVENTS_GET_EVENT_DETAILS_SUMMARY,
  payload,
})

export const assignTicketSeats = payload => ({
  type: actionTypes.EVENTS_GET_ASSIGN_TICKETS_SEATS,
  payload,
})

export const eventScannerInvites = payload => ({
  type: actionTypes.EVENTS_GET_SCANNER_INVITES,
  payload,
})

export const eventScannedTickets = payload => ({
  type: actionTypes.EVENTS_GET_SCANNED_TiCKETS,
  payload,
})

export const eventScannerTotalCount = payload => ({
  type: actionTypes.EVENTS_GET_SCANNER_TOTAL_COUNT,
  payload,
})

export const eventGetTableSeats = payload => ({
  type: actionTypes.EVENTS_GET_TABLE_SEATS,
  payload,
})
export const eventGetTablesCategories = payload => ({
  type: actionTypes.EVENTS_GET_TABLE_CATEGORIES,
  payload,
})

export const setUnassignedTicketList = payload => ({
  type: actionTypes.EVENTS_UNASSIGNED_TICKETS_LIST,
  payload,
})

export const setUnassignedTicketsData = payload => ({
  type: actionTypes.EVENTS_UNASSIGNED_TICKETS_DATA,
  payload,
})

export const eventGetTransactionList = payload => ({
  type: actionTypes.EVENTS_GET_TRANSACTION_LIST,
  payload,
})

export const eventGetBalancesList = payload => ({
  type: actionTypes.EVENTS_GET_BALANCE_LIST,
  payload,
})

export const eventGetSoldTicketDetails = payload => ({
  type: actionTypes.EVENTS_GET_SOLD_TICKET_DETAILS,
  payload,
})

export const eventGetInviteGiftUsers = payload => ({
  type: actionTypes.EVENTS_GET_INVITE_GIFT_USERS,
  payload,
})
export const eventGetInviteCashUsers = payload => ({
  type: actionTypes.EVENTS_GET_INVITE_CASH_USERS,
  payload,
})

export const eventGetInviteSellUsers = payload => ({
  type: actionTypes.EVENTS_GET_INVITE_SELL_USERS,
  payload,
})

export const eventGetInviteBuyUsers = payload => ({
  type: actionTypes.EVENTS_GET_INVITE_BUY_USERS,
  payload,
})

export const eventGetInvitePromoteUsers = payload => ({
  type: actionTypes.EVENTS_GET_INVITE_PROMOTE_USERS,
  payload,
})

export const eventsSetEvent = payload => ({ type: actionTypes.EVENTS_SET_EVENT, payload })

export const eventsSetImages = payload => ({ type: actionTypes.EVENTS_SET_IMAGES, payload })

export const eventsSetFiles = payload => ({ type: actionTypes.EVENTS_SET_FILES, payload })

export const eventsUnreserveSeat = payload => ({ type: actionTypes.UNRESERVE_SEAT, payload })

export const eventsGetPublicEvents = payload => ({
  type: actionTypes.EVENTS_GET_PUBLIC_EVENTS,
  payload,
})

export const eventsSetPublicEvents = payload => ({
  type: actionTypes.EVENTS_SET_HOME_PUBLIC_EVENTS,
  payload,
})

export const eventsGetMyWallets = payload => ({
  type: actionTypes.EVENTS_GET_WALLETS,
  payload,
})

export const eventsGetMyWalletsGuest = payload => ({
  type: actionTypes.EVENTS_GET_WALLETS_GUEST,
  payload,
})

export const eventDetails = payload => ({
  type: actionTypes.EVENTS_GET_EVENT_DETAILS,
  payload,
})
export const eventDetailsV2 = payload => ({
  type: actionTypes.EVENTS_GET_EVENT_DETAILS_V2,
  payload,
})

export const getEvents = (params = {}) => dispatch => {
  return new Promise((resolve, reject) => {
    events.getEvents(params)
      .then(({ data: { data } }) => {
        dispatch(eventsGetPromotions(data))
        dispatch(eventsGetEvents(data))
        resolve(data?.count)
      })
      .catch(error => {
        reject(error)
        // msgError(error)
      })
  })
}
export const getTemplates = () => dispatch => {
  return new Promise((resolve, reject) => {
    eventsV2.getTemplates()
      .then(({ data: { data } }) => {
        dispatch(eventsGetTemplates())
        resolve(data?.count)
      })
      .catch(error => {
        reject(error)
        // msgError(error)
      })
  })
}

export const deleteEvent = obj => () => {
  return new Promise(resolve => {
    events.deleteEvent(obj)
      .catch(msgError)
      .finally(() => resolve())
  })
}

// export const getEvent = (id, userId) => dispatch =>
//   events.getEvent(id, userId).then(
//     ({
//       data: {
//         data: { eventData, ticketArray },
//       },
//     }) => {
//       const obj = { ...eventData, tickets: ticketArray, inviterID: userId }
//       dispatch(eventsSetEvent(obj))
//     },
//   )
//     .catch(() => {
//       push('/404/no-event')
//     })

export const getEventV2 = (id, userId) => dispatch =>
  eventsV2.getEventV2(id, userId).then(
    data => {
      const obj = { ...data.data.event, tickets: data.data.categoryArray, bundles: data.data.bundles, assignedSeatings: data.data.assignedSeatings, reservedSeatsArray: data.data.reservedSeats, inviterID: userId }
      dispatch(eventsSetEvent(obj))
    },
  )
    .catch(error => {
      if (error?.response?.status === 412) {
        push('/412/contact-promoter')
      } else {
        push('/404/no-event')
      }
    })

export const reserveSeats = obj => dispatch => {
  return new Promise(resolve => {
    eventsV2.reserveSeats(obj)
      .then(() => {
        // notification.success({ message: `The invitation has been sent to ${obj?.email}` })
        resolve(true)
      })
      .catch(error => {
        msgError(error)
        resolve(false)
      })
  })
}
export const dereserveSeats = obj => dispatch => {
  return new Promise(resolve => {
    eventsV2.dereserveSeats(obj)
      .then(() => {
        // notification.success({ message: `The invitation has been sent to ${obj?.email}` })
        resolve(true)
      })
      .catch(error => {
        msgError(error)
        resolve(false)
      })
  })
}
export const guestReserveSeats = obj => dispatch => {
  return new Promise(resolve => {
    eventsV2.guestReserveSeats(obj)
      .then(response => {
        // notification.success({ message: `The invitation has been sent to ${obj?.email}` })
        resolve(response)
      })
      .catch(error => {
        msgError(error)
        resolve(false)
      })
  })
}
export const guestDereserveSeats = obj => dispatch => {
  return new Promise(resolve => {
    eventsV2.guestDereserveSeats(obj)
      .then(() => {
        // notification.success({ message: `The invitation has been sent to ${obj?.email}` })
        resolve(true)
      })
      .catch(error => {
        msgError(error)
        resolve(false)
      })
  })
}
export const giftTicketFromMyBoxOffice = obj => dispatch => {
  return new Promise(resolve => {
    events.giftTicket(obj)
      .then(() => {
        notification.success({ message: `The ${ticketSpelling(obj?.quantity)} have been sent to ${obj?.email}` })
        resolve(true)
      })
      .catch(error => {
        msgError(error)
        resolve(false)
      })
  })
}

export const sellTicketFromMyBoxOffice = obj => dispatch => {
  return new Promise(resolve => {
    events.giftTicket(obj)
      .then(() => {
        notification.success({ message: `The invitation has been sent to ${obj?.email}` })
        resolve(true)
      })
      .catch(error => {
        msgError(error)
        resolve(false)
      })
  })
}

export const cashSaleTicketFromMyBoxOffice = obj => dispatch => {
  return new Promise(resolve => {
    eventsV2.cashChekSaleInvite(obj)
      .then(() => {
        notification.success({ message: `The invitation has been sent to ${obj?.email}` })
        resolve(true)
      })
      .catch(error => {
        msgError(error)
        resolve(false)
      })
  })
}
export const deleteInvite = obj => dispatch => {
  return new Promise(resolve => {
    eventsV2.deleteInvite(obj)
      .then(() => {
        // notification.success({ message: `The invitation has been sent to ${obj?.email}` })
        resolve(true)
      })
      .catch(error => {
        msgError(error)
        resolve(false)
      })
  })
}

export const getDistributedTickets = (eventID, ticketID, perPage, skip, searchValue) => dispatch => {
  eventsV2.getDistributedTickets(eventID, ticketID, perPage, skip, searchValue)
    .then(res => {
      const data = res?.data?.data
      const total = res?.data?.desc.total
      dispatch(eventGetSoldTicketDetails({ total, data }))
    })
}

export const getEventGetSoldTicketDetails = (eventID, ticketID, limit, skip, search) => dispatch =>
  events.getSoldTicketDetails(eventID, ticketID, limit, skip, search)
    .then(res => {
      const total = res?.data?.data[0]?.decs[0]?.count
      const data = res?.data?.data[0]?.data
      dispatch(eventGetSoldTicketDetails({ total, data }))
    })
export const getInvites = (categoryID, type, limit, skip, search) => dispatch =>
  eventsV2.getInvites(categoryID, type, limit, skip, search)
    .then(res => {
      const data = res?.data?.data
      const total = res?.data?.desc.total
      type === 'GIFT'
        ? dispatch(eventGetInviteGiftUsers({ total, data }))
        : type === 'SELL'
          ? dispatch(eventGetInviteSellUsers({ total, data }))
          : type === 'PROMOTE'
            ? dispatch(eventGetInvitePromoteUsers({ total, data }))
            : type === 'CASH_SELL'
              ? dispatch(eventGetInviteCashUsers({ total, data }))
              : dispatch(eventGetInviteBuyUsers({ total, data }))
    })
export const getCashCheckSales = (ticketID, eventID, limit, skip, search) => dispatch =>
  eventsV2.getCashCheckSales(ticketID, eventID, limit, skip, search)
    .then(res => {
      const total = res?.data?.data[0]?.decs[0]?.count
      const data = res?.data?.data[0]?.data
      dispatch(eventGetInviteCashUsers({ total, data }))
    })
export const getUnassignedTicketList = (eventID, search) => dispatch => {
  return new Promise((resolve, reject) => {
    eventsV2.getUnassignedTicketList(eventID, search)
      .then(res => {
        if (res.data.data.dataArray) {
          resolve(res)
          dispatch(setUnassignedTicketList(res.data.data.dataArray))
          dispatch(setUnassignedTicketsData(res.data.data))
        }
      })
      .catch(error => {
        reject(error)
        msgError(error)
      })
  })
}

export const assignTicketsToTable = obj => dispatch => {
  return new Promise(resolve => {
    eventsV2.assignTicketsToTable(obj)
      .then(async ({ data: { data } }) => {
        if (data && data?.unassignedList) {
          await dispatch(setUnassignedTicketList(data?.unassignedList?.dataArray))
          await dispatch(setUnassignedTicketsData(data?.unassignedList))
        }
        if (data && data?.tableArray) {
          await dispatch(eventGetTableSeats(data?.tableArray))
        }
        resolve(data)
      })
      .catch(error => {
        resolve(error)
        msgError(error)
      })
  })
}

export const unassignTicketsFromTable = obj => (dispatch, getStore) => {
  return new Promise(resolve => {
    eventsV2.unassignTicketsFromTable(obj)
      .then(async ({ data: { data } }) => {
        if (data && data?.tableArray) {
          await dispatch(eventGetTableSeats(data?.tableArray))
        }
        if (data && data?.unassignedList) {
          await dispatch(setUnassignedTicketList(data?.unassignedList?.dataArray))
          await dispatch(setUnassignedTicketsData(data?.unassignedList))
        }
        if (data && data._id) {
          const { tableArray } = eventsSelector(getStore())
          dispatch(eventGetTableSeats(tableArray.map(t => t._id === data._id ? data : t)))
        }
        resolve(data)
      })
      .catch(error => {
        resolve(error)
        msgError(error)
      })
  })
}

export const unassignTicketsFromSection = obj => dispatch => {
  return new Promise(resolve => {
    const actionFn = async res => {
      await dispatch(getAssignTicketSection(obj?.eventID))
      resolve(res)
    }
    eventsV2.unassignTicketsFromSection(obj)
      .then(async res => await actionFn(res))
      .catch(async error => {
        await actionFn(error)
        msgError(error)
      })
  })
}

export const getEventGetTransactionList = (eventID, limit, skip, search) => dispatch =>
  eventsV2.getTransactionList(eventID, limit, skip, search)
    .then(res => {
      const total = res?.data?.data.count
      const data = res?.data?.data.data
      dispatch(eventGetTransactionList({ total, data }))
    })

export const getBalanceLedgers = (eventID, search) => dispatch =>
  eventsV2.getBalanceLedgers(eventID, search)
    .then(res => {
      dispatch(eventGetBalancesList({ data: res.data.balanceLedgers }))
    })

export const getAssignTicketSeats = (eventID, sectionID) => dispatch =>
  events.getAssignTicketSeats(eventID, sectionID)
    .then(data => {
      if (data.data.data.seatsArray) {
        dispatch(assignTicketSeats(data.data.data.seatsArray))
      }
    })

export const updateAssignTicketSeats = (eventID, sectionID, obj) => dispatch =>
  events.updateAssignTicketSeats(obj)
    .then(() => {
      dispatch(getAssignTicketSeats(eventID, sectionID))
    })

export const getEventScannerInvites = params => dispatch =>
  events.getScannerInvites(params)
    .then(({ data: { data } }) => {
      dispatch(eventScannerInvites(data?.data))
      dispatch(eventScannerTotalCount(data?.count))
    })
    .catch(msgError)

export const getScannedTickets = params => dispatch =>
  eventsV2.getScannedTickets(params)
    .then(({ data }) => {
      dispatch(eventScannedTickets({ tickets: data?.tickets, totalScannedTickets: data?.meta?.total[0]?.count }))
    })
    .catch(msgError)

export const resetEventScannerInvites = () => dispatch => {
  dispatch(eventScannerInvites([]))
  dispatch(eventScannerTotalCount(0))
}

export const setEventScannerInvites = obj => dispatch => {
  return new Promise((resolve, reject) => {
    events.setScannerInvites(obj)
      .then(() => resolve(true))
      .catch(error => {
        msgError(error)
        reject(error)
      })
  })
}

export const deleteEventScannerInvites = ({ scannerID, eventID }) => dispatch => {
  return new Promise((resolve, reject) => {
    events.deleteScannerInvites({ scannerID })
      .then(() => resolve(true))
      .catch(error => {
        msgError(error)
        reject(error)
      })
  })
}

export const getEventDetailsSummary = (eventID, isSubpromotion = false) => dispatch =>
  events.getEventDetailsSummary(eventID, isSubpromotion)
    .then(({ data: { data } }) => {
      dispatch(eventDetailsSummary(data))
    })
    .catch(msgError)
export const getEventDetailsSummaryV2 = (eventID, isSubpromotion = false) => dispatch =>
  eventsV2.getEventDetailsSummaryV2(eventID, isSubpromotion)
    .then(({ data: { data } }) => {
      dispatch(eventDetailsSummary(data))
    })
    .catch(msgError)

export const getEventDetails = eventID => (dispatch, getStore) =>
  events.getEventDetails(eventID)
    .then(({ data: { data: { eventData, ticketArray, bundleArray, sections = [] } } }) => {
      const obj = { ...eventData, tickets: ticketArray, bundles: bundleArray, sections }
      dispatch(eventDetails(obj))
      const { events } = eventsSelector(getStore())
      dispatch(eventsGetEvents({ ownEvents: events.map(i => i._id === eventID ? obj : i) }))
    })
    .catch(error => {
      if (error?.response?.data?.statusCode === 400) {
        // push('/404?status=blocked')
      }
    })
export const getEventDetailsV2 = eventID => (dispatch, getStore) =>
  eventsV2.getEventDetailsV2(eventID)
    .then(data => {
      const obj = { ...data.data.event }
      dispatch(eventDetailsV2(obj))
      const { events } = eventsSelector(getStore())
      dispatch(eventsGetEvents({ ownEvents: events.map(i => i._id === eventID ? obj : i) }))
    })
    .catch(error => {
      if (error?.response?.data?.statusCode === 400) {
        // push('/404?status=blocked')
      }
    })

export const activateScanner = obj => (dispatch, getStore) => {
  const { currentEventDetails } = eventsSelector(getStore())
  return new Promise(resolve => {
    events
      .toggleEventScanner(obj)
      .then(({ data: { data: { activateScanner } } }) => dispatch(eventDetails({ ...currentEventDetails, activateScanner })))
      .catch(msgError)
      .finally(() => resolve())
  })
}
export const activateScannerV2 = (eventID, obj) => (dispatch, getStore) => {
  return new Promise(resolve => {
    eventsV2
      .toggleEventScannerV2(eventID, obj)
      .then()
      .catch(msgError)
      .finally(() => resolve())
  })
}

// respond to ticket invite as guest
export const setInviteTicketGuest = (obj, callback = () => { }) => dispatch => {
  return new Promise(resolve => {
    eventsV2.setInviteTicketGuest(obj)
      .then(response => {
        // push('/home')
        callback()
        resolve(response)
        if (obj?.status === 'REJECTED') {
          notification.success({ message: 'Success' })
        } else {
          notification.success({ message: `The ${ticketSpelling(obj?.quantity)} have been sent to your email` })
        }
      }).then(() => setTimeout(() => window.location.reload(), 300))
      .catch(error => {
        resolve(error)
        msgError(error)
      })
  })
}

// respond to ticket invite as user
export const inviteTicket = (obj, callback = () => { }) => dispatch => {
  return new Promise(resolve => {
    eventsV2
      .inviteTicket(obj)
      .then(response => {
        callback()
        resolve(response)
        notification.success({ message: 'Success' })
      }).then(() => {
        dispatch(getTickets('ACTIVE'))
        dispatch(getMyWalletTickets('ACTIVE'))
      })
      .catch(error => {
        msgError(error)
        resolve(error)
      })
  })
}

export const clearGetEventDetails = () => dispatch => {
  dispatch(eventDetails({}))
}

export const getAssignTicketSection = eventID => dispatch =>
  eventsV2.getAssignTicketSection(eventID).then(({ data: { dataArray } }) => {
    dispatch(assignTicketSection(dataArray))
  })
// export const getRandomDrawCategories = eventID => dispatch =>
//   eventsV2.getRandomDrawCategories(eventID).then(({ data: { dataArray } }) => {
//     dispatch(assignTicketSection(dataArray))
//   })

export const deleteAssignTicketSection = obj => dispatch => {
  return new Promise((resolve, reject) => {
    eventsV2.deleteAssignTicketSection(obj)
      .then(async response => {
        await dispatch(getAssignTicketSection(obj?.eventID))
        resolve(response)
      })
      .catch(error => {
        msgError(error)
        reject(error)
      })
  })
}

export const clearGetAssignTicketSection = () => dispatch => {
  dispatch(assignTicketSection([]))
}
export const clearCurrentEvent = () => dispatch => {
  dispatch(eventGetSoldTicketDetails({}))
  dispatch(eventGetInviteGiftUsers({}))
  dispatch(eventGetInviteSellUsers({}))
  dispatch(eventGetInviteBuyUsers({}))
  dispatch(eventGetInvitePromoteUsers({}))
  dispatch(eventGetTransactionList({}))
}

export const getAssignTicketSectionDetails = (eventID, sectionID) => dispatch =>
  eventsV2.getAssignTicketSectionDetails(eventID, sectionID)
    .then(({ data: { dataArray } }) => {
      dispatch(assignTicketSectionDetails(dataArray[0]))
    })

export const deleteTablesFromEvent = (id, obj) => (dispatch, getStore) => {
  return new Promise(resolve => {
    template.deleteTemplateRow(id, obj)
      .then(data => {
        resolve(data)
      }).catch(error => {
        resolve(error)
        msgError(error)
      })
  })
}

export const editTableSection = (templateID, obj) => (dispatch, getStore) => {
  return new Promise((resolve, reject) => {
    template.editRowTable(templateID, obj)
      .then(async ({ data }) => {
        resolve(data)
      }).catch(error => {
        reject(error)
        msgError(error)
      })
  })
}
export const addNewTable = (templateID, obj) => (dispatch, getStore) => {
  return new Promise((resolve, reject) => {
    template.addNewTable(templateID, obj)
      .then(async ({ data }) => {
        resolve(data)
      }).catch(error => {
        reject(error)
        msgError(error)
      })
  })
}

export const getAssignTicketTables = (eventID, sectionID) => dispatch => {
  return new Promise((resolve, reject) => {
    eventsV2.getSectionTables(eventID, sectionID).then(async res => {
      if (res.data.tablesData) {
        await dispatch(eventGetTableSeats(res.data.tablesData))
      }
      if (res.data.seatCategories) {
        await dispatch(eventGetTablesCategories(res.data.seatCategories))
      }
      resolve(res.data)
    }).catch(error => {
      msgError(error)
      reject(error)
    })
  })
}

export const clearGetAssignTicketTables = () => dispatch => {
  dispatch(eventGetTableSeats([]))
  dispatch(eventGetTablesCategories([]))
  dispatch(setUnassignedTicketList([]))
  dispatch(setUnassignedTicketsData({}))
}

export const setAssignTicketTables = (eventID, obj) => dispatch => {
  return new Promise((resolve, reject) => {
    eventsV2.addSectionTables(eventID, obj)
      .then(async ({ data: { data } }) => {
        await dispatch(getAssignTicketTables(eventID, obj.sectionID))
        resolve(data)
      })
      .catch(error => {
        reject(error)
        msgError(error)
      })
  })
}

export const editTableSectionName = (eventID, sectionID, obj) => () => {
  return new Promise((resolve, reject) => {
    eventsV2.renameSectionTables(eventID, sectionID, obj)
      .then(({ data: { data } }) => {
        resolve(data)
      })
      .catch(error => {
        reject(error)
        msgError(error)
      })
  })
}

export const setAssignTicketSection = obj => dispatch => {
  return new Promise((resolve, reject) => {
    events.setAssignTicketSection(obj).then(async response => {
      await dispatch(getAssignTicketSection(obj?.eventID))
      resolve(response)
    }).catch(error => {
      reject(error)
      msgError(error)
    })
  })
}
export const addSectionTables = (id, eventID, obj) => dispatch => {
  return new Promise((resolve, reject) => {
    template.addSection(id, obj).then(async response => {
      await dispatch(getAssignTicketSection(eventID))
      resolve(response)
    }).catch(error => {
      reject(error)
      msgError(error)
    })
  })
}

export const resetEvent = () => dispatch => dispatch(eventsSetEvent({}))
export const resetEventSummary = () => dispatch => dispatch(eventDetailsSummary({}))

export const getTickets = type => dispatch =>
  events.getTickets(type).then(({ data: { data } }) => {
    dispatch(filterTickets('date (descending)', data))
  })
export const getMyWalletTickets = type => dispatch =>
  eventsV2.getMyWalletTickets(type).then(({ data }) => {
    dispatch(filterTicketsV2('date (descending)', data))
  })

export const getInviteTicket = ticketID => dispatch =>
  eventsV2.getInviteTicket(ticketID)
    .then(res => {
      if (res.data.data.inviteData) {
        dispatch(eventsGetMyWalletsGuest([res.data.data.inviteData]))
      }
    })

export const filterTickets = (type, myWallets) => dispatch => {
  const { walletAcceptedData = [], walletPendingData = [] } = myWallets
  let AcceptedData = []
  let PendingData = []

  if (type === 'date (descending)' || type === 'date (ascending)') {
    AcceptedData = walletAcceptedData.sort((a, b) => {
      const dateA = new Date(a.eventID.startDate)
      const dateB = new Date(b.eventID.startDate)
      return dateA - dateB
    })
    PendingData = walletPendingData.sort((a, b) => {
      const dateA = new Date(a.startDate)
      const dateB = new Date(b.startDate)
      return dateA - dateB
    })
  } else if (type === 'name (ascending)' || type === 'name (descending)') {
    AcceptedData = walletAcceptedData.sort(
      (a, b) => a.eventID.eventName - b.eventID.eventName,
    )
    PendingData = walletPendingData.sort(
      (a, b) => a.ticketID.eventID.eventName - b.ticketID.eventID.eventName,
    )
  } else if (
    type === 'location (ascending)' ||
    type === 'location (descending)'
  ) {
    AcceptedData = walletAcceptedData.sort(
      (a, b) => a.eventID.address - b.eventID.address,
    )
    PendingData = walletPendingData.sort(
      (a, b) => a.ticketID.eventID.address - b.ticketID.eventID.address,
    )
  }

  if (
    type === 'date (descending)' ||
    type === 'name (descending)' ||
    type === 'location (descending)'
  ) {
    AcceptedData.reverse()
    PendingData.reverse()
  }

  dispatch(
    eventsGetMyWallets({
      walletAcceptedData: AcceptedData,
      walletPendingData: PendingData,
    }),
  )
}
export const filterTicketsV2 = (type, myWallets) => dispatch => {
  const { accepted = [], pending = [] } = myWallets
  let AcceptedData = []
  let PendingData = []

  if (type === 'date (descending)' || type === 'date (ascending)') {
    AcceptedData = accepted.sort((a, b) => {
      const dateA = new Date(a.eventID.startDate)
      const dateB = new Date(b.eventID.startDate)
      return dateA - dateB
    })
    PendingData = pending.sort((a, b) => {
      const dateA = new Date(a.startDate)
      const dateB = new Date(b.startDate)
      return dateA - dateB
    })
  } else if (type === 'name (ascending)' || type === 'name (descending)') {
    AcceptedData = accepted.sort(
      (a, b) => a.eventID.eventName - b.eventID.eventName,
    )
    PendingData = pending.sort(
      (a, b) => a.ticketID.eventID.eventName - b.ticketID.eventID.eventName,
    )
  } else if (
    type === 'location (ascending)' ||
    type === 'location (descending)'
  ) {
    AcceptedData = accepted.sort(
      (a, b) => a.eventID.address - b.eventID.address,
    )
    PendingData = pending.sort(
      (a, b) => a.ticketID.eventID.address - b.ticketID.eventID.address,
    )
  }

  if (
    type === 'date (descending)' ||
    type === 'name (descending)' ||
    type === 'location (descending)'
  ) {
    AcceptedData.reverse()
    PendingData.reverse()
  }

  dispatch(
    eventsGetMyWallets({
      accepted: AcceptedData,
      pending: PendingData,
    }),
  )
}

export const uploadFile = image => dispatch =>
  events
    .uploadFile(image)
    .then(res => {
      dispatch(eventsSetImages(res?.data?.data))
      notification.success({ message: 'Image successfully uploaded' })
    })
    .catch(error => msgError(error))

export const uploadImage = (originalImage, image, options) => dispatch => {
  return new Promise(resolve => {
    images
      .posterCover(originalImage, image, options)
      .then(res => {
        !options?.isTemplate && dispatch(eventsSetImages(res?.data))
        resolve(res?.data)
        notification.success({ message: 'Image successfully uploaded' })
      })
      .catch(error => {
        resolve(false)
        msgError(error)
      })
  })
}
export const uploadImageSeating = (originalImage, options) => dispatch => {
  return new Promise(resolve => {
    images
      .posterSeatingSchema(originalImage, options)
      .then(res => {
        resolve(res?.data)
        notification.success({ message: 'Image successfully uploaded' })
      })
      .catch(error => {
        resolve(false)
        msgError(error)
      })
  })
}

export const uploadAttachment = (image, options) => (dispatch, getStore) => {
  return new Promise(resolve => {
    events
      .uploadAttachment(image, options)
      .then(res => {
        if (res) {
          const { filesAttachment } = eventsSelector(getStore())
          dispatch(eventsSetFiles([...filesAttachment, res?.data?.data]))
          resolve(res?.data?.data)
          notification.success({ message: 'File successfully uploaded' })
        }
      })
      .catch(error => {
        resolve(false)
        if (error?.message === 'Network Error') {
          notification.warning({ message: 'You can not upload folder, zip it if you would like to continue' })
        } else {
          error.message ? msgError({ response: { data: { message: error.message } } }) : msgError(error)
        }
      })
  })
}

export const eventsDeleteFile = url => (dispatch, getStore) => {
  const { filesAttachment } = eventsSelector(getStore())
  dispatch(eventsSetFiles(filesAttachment.filter(obj => obj?.original !== url)))
}

export const clearUploadFile = () => dispatch =>
  dispatch(eventsSetImages({}))

export const makeTicket = obj => dispatch =>
  events
    .makeTicket(obj)
    .then(() => {
      notification.success({ message: 'Ticket successfully created' })
      dispatch(clearUploadFile())
    })
    .catch(error => msgError(error))

export const createEvent = obj => dispatch => {
  return new Promise((resolve, reject) => {
    events
      .makeTicket(obj)
      .then(response => {
        notification.success({ message: 'Ticket successfully created' })
        dispatch(clearUploadFile())
        resolve(response)
      })
      .catch(error => {
        reject(error)
        msgError(error)
      })
  })
}
export const createEventV2 = obj => dispatch => {
  return new Promise((resolve, reject) => {
    eventsV2
      .addEvent(obj)
      .then(response => {
        notification.success({ message: 'Event successfully created' })
        dispatch(clearUploadFile())
        resolve(response)
      })
      .catch(error => {
        reject(error)
        msgError(error)
      })
  })
}
export const makeTicketV2 = obj => dispatch => {
  return new Promise((resolve, reject) => {
    eventsV2
      .makeEventV2(obj)
      .then(response => {
        notification.success({ message: 'Event successfully created' })
        dispatch(clearUploadFile())
        resolve(response)
      })
      .catch(error => {
        reject(error)
        msgError(error)
      })
  })
}
export const createEventVenue = obj => dispatch => {
  return new Promise((resolve, reject) => {
    events
      .addVenueEvent(obj)
      .then(response => {
        notification.success({ message: 'Ticket successfully created' })
        // dispatch(clearUploadFile())
        resolve(response)
      })
      .catch(error => {
        reject(error)
        msgError(error)
      })
  })
}
export const createEventVenueV2 = obj => () => {
  return new Promise((resolve, reject) => {
    eventsV2
      .addVenueEvent(obj)
      .then(response => {
        notification.success({ message: 'Event successfully created' })
        resolve(response)
      })
      .catch(error => {
        reject(error)
        msgError(error)
      })
  })
}

export const updateEventVenueV2 = obj => () => {
  return new Promise((resolve, reject) => {
    eventsV2
      .editDraftEvent(obj)
      .then(response => {
        notification.success({ message: 'Event successfully updated' })
        resolve(response)
      })
      .catch(error => {
        reject(error)
        msgError(error)
      })
  })
}

export const createSubscribe = obj => dispatch => {
  return new Promise((resolve, reject) => {
    events
      .createSubscribe(obj)
      .then(response => {
        notification.success({ message: 'Subscription successfully created' })
        dispatch(subscriptionUpdate(true))
        resolve(response)
        return response
      })
      .catch(error => {
        reject(error)
        msgError(error)
      })
  })
}

export const deleteSubscribe = () => dispatch => {
  return new Promise((resolve, reject) => {
    events
      .deleteSubscribe()
      .then(response => {
        notification.success({ message: 'Subscribe successfully deleted' })
        dispatch(subscriptionUpdate(false))
        resolve(response)
        return response
      })
      .catch(error => {
        reject(error)
        msgError(error)
      })
  })
}

export const buyPrivateTiket = (obj, func) => dispatch => {
  return new Promise((resolve, reject) => {
    events.buyPrivateStreamBundle(obj)
      .then(response => {
        func('tickets')
        notification.success({ message: 'Ticket successfully buy' })
        resolve(response)
      })
      .catch(error => {
        reject(error)
        msgError(error)
      })
  })
}
export const addTicketCategory = obj => dispatch =>
  events.addTicketCategory(obj)
    .catch(error => msgError(error))

export const removeTicketCategory = (obj, eventID) => dispatch => {
  events.removeTicketCategory(obj)
    .then(dispatch(getEventDetails(eventID)))
    .catch(error => msgError(error))
}

export const removeTicketCategoryV2 = (obj, eventID) => dispatch => {
  eventsV2.removeTicketCategory(obj)
    .then(dispatch(getEventDetails(eventID)))
    .catch(error => msgError(error))
}

export const addTicketCategoryV2 = (obj, eventID) => dispatch => {
  return new Promise((resolve, reject) => {
    eventsV2.addTicketCategory(obj)
      .then(response => {
        dispatch(getEventDetails(eventID))
        resolve(response)
      })
      .catch(error => msgError(error))
  })
}

export const updateEvent = (obj, eventID) => dispatch => {
  return new Promise((resolve, reject) => {
    events.updateTicket(obj).then(() => {
      dispatch(getEventDetails(eventID))
      dispatch(clearUploadFile())
      notification.success({ message: 'Ticket successfully updated' })
      resolve()
    }).catch(error => {
      reject(error)
      msgError(error)
    })
  })
}
export const updateEventV2 = (obj, eventID) => dispatch => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      eventsV2.editEvent(obj).then(() => {
        dispatch(getEventDetails(eventID))
        dispatch(clearUploadFile())
        notification.success({ message: 'Ticket successfully updated' })
        resolve()
      }).catch(error => {
        reject(error)
        msgError(error)
      })
    }, 300)
  })
}

export const editTicketV2 = (obj, eventID) => dispatch => {
  return new Promise((resolve, reject) => {
    eventsV2.editEventV2(obj, eventID).then(() => {
      dispatch(getEventDetails(eventID))
      dispatch(clearUploadFile())
      notification.success({ message: 'Ticket successfully updated' })
      resolve()
    }).catch(error => {
      reject(error)
      msgError(error)
    })
  })
}
export const updateVenueEvent = (obj, eventID) => dispatch => {
  return new Promise((resolve, reject) => {
    events.updateVenueTicket(obj).then(() => {
      dispatch(getEventDetails(eventID))
      notification.success({ message: 'Ticket successfully updated' })
      resolve()
    }).catch(error => {
      reject(error)
      msgError(error)
    })
  })
}

export const getPublicEvent = obj => dispatch =>
  events
    .getPublicEvent(obj)
    .then(({ data }) => {
      dispatch(eventsGetPublicEvents(data.data))
    })
    .catch(error => msgError(error))

/*
export const getPublicVenueEvent = obj => dispatch => eventsV2
  .getPublicVenueEvent(obj)
  .then(({ data }) => {
    dispatch(eventsGetPublicEvents(data.data))
  })
  .catch(error => msgError(error))
*/
export const getPublicVenueEvent = obj => (dispatch, getStore) => {
  return new Promise((resolve, reject) => {
    const { publicEvents } = eventsSelector(getStore())

    eventsV2
      .getPublicVenueEvent(obj)
      .then(({ data }) => {
        const currentEvents = [...publicEvents, ...data.events]
        const q = eventsSetPublicEvents(currentEvents)
        dispatch(q)
        resolve({ total: data.meta.total, current: currentEvents.length })
      })
      .catch(error => {
        reject(error)
        msgError(error)
      })
  })
}
export const getHomePublicEvent = obj => (dispatch, getStore) => {
  return new Promise((resolve, reject) => {
    const { publicEvents } = eventsSelector(getStore())

    eventsV2
      .getPublicEvent(obj)
      .then(({ data }) => {
        const currentEvents = [...publicEvents, ...data.events]
        const q = eventsSetPublicEvents(currentEvents)
        dispatch(q)
        resolve({ total: data.meta.total, current: currentEvents.length })
      })
      .catch(error => {
        reject(error)
        msgError(error)
      })
  })
}

export const sellTickets = obj => dispatch =>
  events
    .sellTickets(obj)
    .then(() => {
      const message = obj?.inviteType === 'RESELL'
        ? `The invitation has been sent to ${obj?.email}`
        : `The ${ticketSpelling(obj?.quantity)} have been sent to ${obj?.email}`
      notification.success({ message })
    })
    .catch(error => msgError(error))

export const regiftFromOrder = (orderId, data) => dispatch =>
  eventsV2
    .regiftFromOrderV2(orderId, data)
    .then(res => {
      const message = `The invitation has been sent to ${data?.email}`
      notification.success({ message })
      return res
    })
    .catch(error => msgError(error))

export const regiftFromWallet = data => dispatch =>
  eventsV2
    .regiftFromWalletV2(data)
    .then(res => {
      const message = `The invitation has been sent to ${data?.email}`
      notification.success({ message })
      return res
    })
    .catch(error => msgError(error))

export const getEventIDByURL = eventURL => dispatch => {
  return new Promise((resolve, reject) => {
    events.getEventIDByUrl(eventURL)
      .then(res => {
        dispatch(getEventV2(res.data.data.eventID, res.data.data.userID))
        dispatch(getPageDetails(res.data.data.eventID))
        resolve(res)
      })
      .catch(() => {
        push('/404/no-event')
      })
  })
}
export const getFee = (eventID, type, ticketsArray, currency, paymentType) => dispatch =>
  eventsV2.getFeeV2(eventID, type, JSON.stringify(ticketsArray), currency, paymentType)
    .then(res => res.data.data)
    .catch(error => msgError(error))
// export const getFee = (eventID, type, ticketsArray, seatsArray, currency, paymentType) => dispatch =>
//   events.getFee(eventID, type, JSON.stringify(ticketsArray), JSON.stringify(seatsArray), currency, paymentType)
//     .then(res => res.data.data)
//     .catch(error => msgError(error))

export const getVenmoToken = () => dispatch =>
  events.getVenmoToken()
    .then(res => res.data.data)
    .catch(error => msgError(error))

export const joinVirtualEvent = code => dispatch =>
  events.joinVirtualEvent(code)
    .then(res => res.data.data)
    .catch(error => msgError(error))

export const getStreamUrl = (eventID, barcode) => () => {
  return new Promise((resolve, reject) => {
    events.getStreamUrl(eventID, barcode)
      .then(res => resolve(res.data.data))
      .catch(error => {
        reject(error)
        msgError(error)
      })
  })
}

export const getGuestStreamUrl = (eventID, barcode) => () => {
  return new Promise((resolve, reject) => {
    events.getGuestStreamUrl(eventID, barcode)
      .then(res => resolve(res.data.data))
      .catch(error => {
        msgError(error)
        reject(error)
      })
  })
}

export const setMockTicket = payload => ({
  type: actionTypes.EVENTS_SET_MOCK_TICKET,
  payload,
})

export const setEventsIsStream = payload => ({
  type: actionTypes.EVENT_STREAM,
  payload,
})

export const setScanned = (barcode, eventID) => {
  return eventsV2.scanTicketV2({ barcode: barcode, eventID: eventID })
    .then(res => {
      notification.success({ message: res.data.message })
    })
    .catch(error => {
      notification.warning({ message: error?.response?.data?.error })
    })
}
